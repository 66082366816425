<template lang="pug">
.base-checkbox-group(
  :id="globalMixin_uuid"
  :class="{ 'max-width' : maxWidth }"
  tabindex=0
)
  label.base-checkbox-label(
    v-if="label"
  )
    | {{ label }}
    question-mark(
      v-if='questionmarkHoverText'
      :questionmarkHoverText='questionmarkHoverText'
    )
  .checkbox-wrap(
    :class="{ vertical: vertical, 'has-error' : localErrors.length > 0 }"
  )
    base-checkbox(
      v-for="(option, index) in options",
      :key="index",
      :disabled="disabled"
      :name="name",
      :label="option[labelKey]",
      :value="option[valueKey]",
      :modelValue="modelValue",
      @update:modelValue="onChangeData"
    )
  p.error-msg(
    v-if="localErrors.length > 0"
  )
    | {{ localErrors[0].message }}
</template>

<script>
import questionMark from '../questionMark.vue';
import baseValidatorMixin from '../../js/baseValidatorMixin';
export default {
  mixins: [
    baseValidatorMixin
  ],
  components: {
    questionMark,
  },
  data () {
    return {
      // this property is used only for isNoneCheckbox special check
      oldModelValue: null,
    };
  },
  props: {
    questionmarkHoverText: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    valueKey: {
      type: String,
      default: "id",
    },
    labelKey: {
      type: String,
      default: "label",
    },
    vertical: {
      type: Boolean,
    },
    maxWidth: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    isNoneCheckbox: {
      type: Boolean,
    },
  },
  emits: [
    "update:modelValue"
  ],
  methods: {
    async onChangeData (evt) {
      if (this.isNoneCheckbox) {
        if (evt.length === 0) return;
        const lastEl = evt[evt.length - 1];
        if (lastEl === 'none') {
          evt = ['none'];
        } else {
          const noneIndex = evt.findIndex(el => el === 'none');
          if (noneIndex > -1) evt.splice(noneIndex, 1);
        }
      }

      this.$emit('update:modelValue', evt);
      await this.$nextTick();
      this.onApplyValidators();
    },
  },
};
</script>

<style scoped lang="scss">
.base-checkbox-group {
  position: relative;
  display: flex;
  flex-direction: column;
  @include flex-gap(1.1em, 'column nowrap');
  width: $input-width;
  outline: none;
  &.max-width {
    width: 100%;
  }
  .base-checkbox-label {
    display: flex;
    align-items: center;
    @include flex-gap(1em, 'row nowrap');
    font-size: $input-label-font-size;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #656B7E;
  }
  .checkbox-wrap {
    min-height: $input-height;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    border-radius: .5em;
    padding: .2em .5em;
    @include flex-gap(.3em, 'row wrap');
    min-height: $input-height;
    // font-size: .8em;
    &.vertical {
      flex-direction: column;
    }
    &.has-error {
      border: 2px solid $input-error-border-color;
    }
  }
}
</style>