<template lang="pug">
.base-type-dropdown
  label.base-label-el(
    v-if="label"
    :for="globalMixin_uuid"
  )
    | {{ label }}
    question-mark(
      v-if='questionmarkHoverText'
      :questionmarkHoverText='questionmarkHoverText'
    )
  .multiple-select(
    v-if="multiple"
    :class="{ 'error' : hasError, 'focus' : isInputFocused, 'is-open' : isOpen }"
  )
    .selected-options
      input.base-type-dropdown-input(
        :id="globalMixin_uuid"
        ref="dropdown"
        :class="{ 'error' : hasError, 'is-open' : isOpen }"
        :placeholder="placeholder"
        :disabled="disabled"
        autocomplete="off"
        v-model="search"
        @click.stop="onToggleOpen"
        @input="onInput"
        @blur="onBlur"
        @keydown.stop="bindArrowsAndEnter"
        @focus="isInputFocused = true"
      )
      .option-badge(
        v-for="(option, index) in selectedOptions"
        :key="index"
      )
        | {{ option[showBy] }}
        span(
          v-show="!disabled"
          @click="onRemoveSelectedOption(index)"
        )
          | X
  input.base-type-dropdown-input(
    v-if="!multiple"
    :id="globalMixin_uuid"
    ref="dropdown"
    :value="modelValue[showBy]"
    :class="{ 'error' : hasError, 'is-open' : isOpen}"
    :placeholder="placeholder"
    :disabled="disabled"
    autocomplete="off"
    @click.stop="onToggleOpen"
    @input="onInput"
    @blur="onApplyValidators"
    @keydown.stop="bindArrowsAndEnter"
  )
  .x(
    v-if="!disabled"
    :class="{ 'has-label' : label, 'is-open': isOpen }"
    @click.stop="onToggleOpen"
  )
  transition(
    name="fade"
    appear
  )
    .options(
      ref="options"
      v-if="isOpen"
      :class="{ 'multiple' : multiple}"
    )
      .option(
        v-for="option in filteredOptions"
        :key="option.index"
        :ref="'option' + option.index"
        :class="{ 'selected' : option.selected }"
        @click="onOptionClick(option)"
      )
        | {{ option[showBy] }}
      .option(v-if="filteredOptions.length === 0") No matches
  p.error-msg(
    v-if="localErrors.length > 0"
  )
    | {{ localErrors[0].message }}
</template>

<script>
import questionMark from '../questionMark.vue';
import baseValidatorMixin from '../../js/baseValidatorMixin';
export default {
  mixins: [
    baseValidatorMixin
  ],
  components: {
    questionMark,
  },
  props: {
    questionmarkHoverText: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    showBy: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: [
    'update:modelValue'
  ],
  data () {
    return {
      isOpen: false,
      localOptions: [],
      // for multiple select
      search: '',
      selectedOptions: this.modelValue,
      isInputFocused: false,
    };
  },
  computed: {
    filteredOptions () {
      if (this.multiple) {
        if (this.search === '' && this.selectedOptions.length === 0) {
          return this.localOptions;
        }

        if (this.search && this.selectedOptions.length === 0) {
          return this.localOptions.filter(option => option[this.showBy].includes(this.search));
        }

        if (this.search === '' && this.selectedOptions.length > 0) {
          const selectedOptionsIds = this.selectedOptions.map(option => option[this.trackBy]);
          return this.localOptions.filter(option => !selectedOptionsIds.includes(option[this.trackBy]));
        }

        if (this.search && this.selectedOptions.length > 0) {
          const typeFilteredOptions = this.localOptions.filter(option => option[this.showBy].includes(this.search));
          const selectedOptionsIds = this.selectedOptions.map(option => option[this.trackBy]);
          return typeFilteredOptions.filter(option => !selectedOptionsIds.includes(option[this.trackBy]));
        }
      }

      return this.localOptions.filter(option =>  option[this.showBy].toString().includes(this.search));
    },
    selectedOptionIndex () { // selected / hovered option from options menu
      const selectedOption = this.localOptions.find(option => option.selected);
      return selectedOption.index;
    },
  },
  watch: {
    isOpen: {
      handler (newVal) {
        if (newVal) this.focusInput();
      },
    },
    modelValue: {
      deep: true,
      handler () {
        this.selectedOptions = this.modelValue;
      },
    },
  },
  created () {
    this.init();
  },
  beforeUnmount () {
    document.removeEventListener('click', () => this.isOpen = false);
  },
  methods: {
    init () {
      const tempLocalOptions = JSON.parse(JSON.stringify(this.options));
      let index = 0;
      this.localOptions = tempLocalOptions.map(option => ({ 
        ...option,
        selected: false,
        index: index++, 
      }));
      document.addEventListener('click', () => this.isOpen = false);
    },
    onInput (evt) {
      this.isOpen = true;
      this.search = evt.target.value;
    },
    async onOptionClick (option) {
      if (this.multiple) {
        this.isOpen = false;
        this.selectedOptions.push(option);
        this.search = '';
        this.$emit('update:modelValue', this.selectedOptions);
        await this.$nextTick();
        this.onApplyValidators();
        return;
      }
      this.isOpen = false;
      this.$emit('update:modelValue', option);
      await this.$nextTick();
      this.onApplyValidators();
    },
    onBlur () {
      this.isInputFocused = false;
      this.onApplyValidators();
    },
    onRemoveSelectedOption (index) {
      this.selectedOptions.splice(index, 1);
      this.$emit('update:modelValue', this.selectedOptions);
    },
    onToggleOpen () {
      this.toggleOpen();
    },
    toggleOpen () {
      this.clearSelected();
      this.isOpen = !this.isOpen;
    },
    bindArrowsAndEnter (evt) {
      if (evt.code === 'ArrowDown') {
        this.setSelectedOption(1, this.filteredOptions[0].index);
        this.scrollOptionsMenu();
      }

      if (evt.code === 'ArrowUp') {
        const lastIndex = this.filteredOptions.length - 1;
        this.setSelectedOption(-1, this.filteredOptions[lastIndex].index);
        this.scrollOptionsMenu();
      }

      if (evt.code === 'Enter') {
        this.onOptionClick(this.localOptions.find(option => option.selected));
      }

      if (evt.code === 'Escape') {
        this.isOpen = false;
      }
      //prevents dropdown being open while tabbing out of focus
      if (evt.code === 'Tab') {
        this.isOpen = false;
      }
    },
    setSelectedOption (offset, defaultIndex) {
      // offset = 1 would select the next option based on currently selected option
      // offset = -1 would select the previous
      let toBeSelectIndex = defaultIndex;

      for (let i = 0; i < this.filteredOptions.length; i++) {
        if (this.filteredOptions[i].selected) {
          //handle edges
          const nextSelectIndex = i + offset;
          const lastIndex = this.filteredOptions.length - 1;
          if (nextSelectIndex > lastIndex || nextSelectIndex < 0) {
            toBeSelectIndex = defaultIndex;
            break;
          }

          toBeSelectIndex = this.filteredOptions[nextSelectIndex].index;
          break;
        }
      }
      this.clearSelected();
      this.localOptions[toBeSelectIndex].selected = true;
    },
    clearSelected () {
      for (let i = 0; i < this.localOptions.length; i++) {
        this.localOptions[i].selected = false;
      }
    },
    scrollOptionsMenu () {
      const optionsMenuEl = this.$refs.options;
      const optionEl = this.$refs[`option${this.selectedOptionIndex}`];

      const elementInViewport = this.isElementInParentViewport(optionEl, optionsMenuEl);

      if (elementInViewport.value === false) {
        const optionElHeight = optionEl.offsetHeight;

        // TODO handle edge cases (top arrow on first option, down arrow on last option)
        if (elementInViewport.position === 'top') {
          optionsMenuEl.scrollBy(0, -optionElHeight);
          return;
        }
        if (elementInViewport.position === 'bottom') {
          optionsMenuEl.scrollBy(0, optionElHeight);
          return;
        }
      }
    },
    isElementInParentViewport (el, parentEl) {
      const rect = el.getBoundingClientRect();
      const parentRect = parentEl.getBoundingClientRect();

      const isBottomOk = rect.bottom <= parentRect.bottom;
      const isTopOk = rect.top >= parentRect.top;

      if (isTopOk && isBottomOk) {
        return {
          value: true,
        };
      } else {
        return {
          value: false,
          position: !isTopOk? 'top' : 'bottom',
        };
      }
    },
    focusInput () {
      this.$refs.dropdown.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: 'Open Sans';
}
.base-type-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  width: $input-width;
  // gap: .5em 0;
  @include flex-gap(.5em, 'column nowrap');
  // only if multipleselect
  .multiple-select {
    display: flex;
    flex-direction: column;
    // gap: .5em;
    @include flex-gap(.5em, 'column nowrap');
    min-height: $input-height;
    border-radius: .5em;
    // border: 2px solid #DEDEE9;
    .selected-options {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
        @include flex-gap(.5em, 'row wrap');
      // padding: 1em;
      .option-badge {
        display: flex;
        // gap: .5em;
        // @include flex-gap(1em, 'row nowrap');
        height: max-content;
        border-radius: 6px;
        background: $main-blue;
        color: #FFF;
        padding: 2px .5em;
        span {
          cursor: pointer;
          font-weight: 600;
          display: inline-block;
          margin: 0 0 0 .5em;
        }
      }
    }
    .base-type-dropdown-input {
      height: $input-height;
      width: 100%;
      &.is-open {
        border-radius: .5em .5em 0 0;
      }
      &:disabled {
      border: none;
      background: #EFF0F1;
      &:active {
        outline: none;
      }
    }
    }
  }
  .base-label-el {
    display: flex;
    align-items: center;
    // gap: 1em;
    @include flex-gap(.1em, 'row nowrap');
    font-size: $input-label-font-size;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #656B7E;
    width: max-content;
    margin-bottom: .75em;
  }
  .base-type-dropdown-input {
    height: $input-height;
    padding: 0 1em;
    border: 2px solid #DEDEE9;
    border-radius: .5em;
    outline: none;
    font-size: 1em;
    font-weight: 600;
    color: #434961;
    transition: all .2s ease;
    &::placeholder {
      font-weight: 400;
      color: #9CA0AF;
    }
    &.error {
      border-color: $input-error-border-color;
      margin-bottom: .8em;
      &:active, &:focus {
        border: 2px solid $input-error-border-color;
      }
    }
    &:active, &:focus {
      border: 2px solid #3E94FF;
    }
    &.is-open {
      border-radius: .5em .5em  0 0;
    }
    &:disabled {
      border: none;
      background: #EFF0F1;
      &:active {
        outline: none;
      }
    }
  }
  .options {
    padding: .5em 0;
    position: absolute;
    width: 100%;
    background: #FFF;
    border-radius: 0 0 11px 11px;
    top: calc(100% - 1em);
    border: 1px solid $box-border-color;
    border-top: none;
    z-index: 2;
    overflow: auto;
    max-height: 10em;
    &.multiple {
      top: calc(#{$input-height} + 1.2em);
    }
    .option {
      padding: 0 .5em;
      width: 100%;
      cursor: pointer;
      &:hover {
        color: #FFF;
        background: $main-blue;
        transition: all .2s ease;
      }
      &.selected {
        background: #CECEE1;
      }
    }
  }
  .x {
    user-select: none;
    position: absolute;
    top: .3em;
    right: 1em;
    cursor: pointer;
    //arrow styles
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../../assets/icons/select-arrow.svg");
    background-repeat: no-repeat;
    background-position-x: calc(50%);
    background-position-y: -.2em;
    background-color: #FFF;
    //arrow
    height: 15px;
    width: 15px;
    transition: all .2s;
    &.has-label {
      top: 3.45em;
    }
    &.is-open {
      transform: rotate(180deg);
      top: 2.9em;
    }
  }
}
</style>