<template lang="pug">
.agent-profile-wrap
  profile-sidebar(
    v-if="!isMobileMixin_isMobile"
    :options="menus"
  )
  .burger(
    v-if="isMobileMixin_isMobile"
  )
    img(
      v-if="isMobileMixin_isMobile"
      :src="globalMixin_images.burger"
      alt="back-arrow-left.svg"
    )
  .content
    router-view(
      v-slot="{ Component }"
    )
      transition(
        name="fade"
        mode="out-in"
        appear
      )
        component(
          :is="Component"
        )
</template>

<script>
import ProfileSidebar from "@/components/profile/ProfileSidebar";
import profileSidebarMenus from "@/js/profileSidebarMenus";
export default {
  components: {
    ProfileSidebar,
  },
  computed: {
    menus () {
      return profileSidebarMenus.agent;
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-profile-wrap {
  display: flex;
  min-height: 100vh;
  .burger {
    position: absolute;
    top: 0;
    left: 0;
  }
  .content {
    padding: clamp(1em, 3vw, 3em);
    width: 100%;
  }
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .agent-profile-wrap {
    .content {
      padding: 3vw;
    }
  }
}}
</style>