<template lang="pug">
.badge-wrap
  .header {{ dynamicLabel }}
  .badges
    .badge(
      :class="getBadgeClass"
    )
      span.type {{ placeholder }}
      span.price {{ `${symbol}${rawNumToCurrencyUS(number)}` }}
</template>

<script>
import { rawNumToCurrencyUS } from '@/js/helpers';
export default {
  props: {
    insId: {
      type: Number,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    number: {
      type: [Number, String],
    },
    symbol: {
      type: String,
      default: '',
    },
  },
  computed: {
    dynamicLabel () {
      let label = '';
      if (this.label) {
        label = this.label;
      } else if (this.insId) {
        label = this.getInsuranceName + " " + this.$t('profile.price');
      }
      return label;
    },
    getInsuranceName () {
      let insName = '';
      switch (this.insId) {
        case 1: {
          insName = this.$t('insurances.home');
          break;
        }
        case 2: {
          insName = this.$t('insurances.life');
          break;
        }
        case 3: {
          insName = this.$t('insurances.auto');
          break;
        }
      }
      return insName;
    },
    getBadgeClass () {
      let badgeClass = null;
      switch (this.insId) {
        case 1: badgeClass = 'green'; break;
        case 2: badgeClass = 'purple'; break;
        case 3: badgeClass = 'blue'; break;
      }
      return badgeClass;
    },
  },
  methods: {
    rawNumToCurrencyUS,
  },
};
</script>

<style lang="scss" scoped>
.badge-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  // gap: .5em;
  @include flex-gap(.5em, 'column nowrap');
  // width: 9em;
  .header {
    font-size: .9em;
    font-weight: 600;
    white-space: nowrap;
    color: #656B7E;
  }
  .badges {
    display: flex;
    min-width: 200px;
    .badge {
      display: flex;
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
      background: #EFF0F1;
      border-radius: 6px;
      padding: .5em 1em;
      font-family: 'Open Sans';
      align-items: center;
      min-width: 10em;
      justify-content: space-between;
      width: 80%;
      .price {
        color: #434961;
        font-size: 1.3em;
        margin-bottom: 2px;
      }
      .type {
        color: #A8ACB8;
        font-size: .8em;
        font-weight: 600;
      }
      &.green {
        background: #C5F2F2;
        .type {
          color: #79C0C0;
        }
      }
      &.purple {
        background: #DAD5F7;
        .type {
          color: #9E95D6;
        }
      }
      &.blue {
        background: #D3E5FD;
        .type {
          color: #96ACCA;
        }
      }
    }
  }
}
</style>