<template lang="pug">
#agency-lead-quote(
  :class="{ 'top-offset' : isMobileMixin_isMobile }"
)
  confirm-box(
    ref="confirmBox"
  )
  profile-navigation(
    v-if="isMobileMixin_isMobile"
  )
  modal(
    v-if="openSummary"
    background="#F7F8FBF0"
    @close-modal="openSummary = false"
  )
    agent-submit-quote-form(
      :forms="nonQuotedApps"
      @on-confirm-quote="onPostQuotes"
      @close-modal="openSummary = false"
    )
  .header
    .search-wrap
      .back
        router-link(
          v-if="!isMobileMixin_isMobile"
          :to="{ name: 'AgentLeads' }"
        )
          img(
            :src="globalMixin_images.backArrowGray"
            alt="back-arrow-gray.svg"
          )
          span {{ $t('buttons.back') }}
      h1 {{ $t('agentProfile.quoteLead') }}
      //- should go to burger
      //- input.search(
      //-   v-if="!isMobileMixin_isMobile"
      //-   :placeholder="$t('profile.search') + '...'"
      //- )
    .profile-notification-wrap
      base-button(
        v-if="!isMobileMixin_isMobile && nonQuotedApps.length > 0"
        :label="$t('buttons.finishQuoting')"
        @click="onFinishQuoting"
      )
      notification-box
      profile-icon-box(
        v-if="!isMobileMixin_isMobile"
      )
  .content(
    v-if="applications.length > 0"
  )
    agent-lead-quote-form(
      v-for="(app, index) in applications"
      :key="app.qot_id"
      :id="app.qot_id"
      :application="app"
      :insCompanies="filteredInsCompanies"
      :allInsCompanies="allAgentInsCompanies"
      :canDelete="canDeleteForm"
      :checkErrors="checkErrors"
      @update:application="onUpdateApplications($event, index)"
      @on-delete-form="onDeleteForm"
      @has-error="errorHandler"
    )
      | {{ index }}
    .new(
      v-if="canAddForms && !isQuoteWon"
      @click="onAddNew"
    )
      .icon-wrap
        img(
          :src="globalMixin_images.plusRound"
          alt="plus-round.svg"
        )
        span Send a quote with another insurance company
  .button-line
    base-button(
      v-if="isMobileMixin_isMobile && nonQuotedApps.length > 0"
      :label="$t('buttons.finishQuoting')"
      @click="onFinishQuoting"
    )
</template>

<script>
import Modal from '@/components/Modal';
import ProfileNavigation from '@/components/profile/ProfileNavigation';
import NotificationBox from "@/components/profile/NotificationBox.vue";
import ProfileIconBox from "@/components/profile/ProfileIconBox.vue";
import AgentLeadQuoteForm from "@/components/profile/leads/AgentLeadQuoteForm.vue";
import AgentSubmitQuoteForm from "@/components/profile/leads/AgentSubmitQuoteForm.vue";
import ConfirmBox from "@/components/ConfirmBox";

export default {
  components: {
    Modal,
    ProfileNavigation,
    NotificationBox,
    ProfileIconBox,
    AgentLeadQuoteForm,
    AgentSubmitQuoteForm,
    ConfirmBox,
  },
  data () {
    return {
      applications: [],
      newAppObj: {},
      // used to differentiate apps
      newAppId: 1,
      insCompanies: [],
      allAgentInsCompanies: [],
      companiesAlreadyQuoted: [],
      hehexd: [],
      checkErrors: false,
      hasErrorArr: [],
      openSummary: false,
    };
  },
  computed: {
    selectedCompanies () {
      return this.applications.filter(app => app.inc_id > 0);
    },
    selectedCompanyIds () {
      return this.selectedCompanies.map(company => company.inc_id);
    },
    filteredInsCompanies () {
      return this.allAgentInsCompanies.map(insObj => {
        const newInsObj = {
          ...insObj,
          hidden: this.selectedCompanyIds.includes(insObj.inc_id) || !this.insCompanies.find(ins => ins.inc_id === insObj.inc_id),
        };

        if (this.companiesAlreadyQuoted.includes(insObj.inc_id)) newInsObj.disabledTooltipMsg = 'Already quoted by another agent.';
        else if (!this.companiesAlreadyQuoted.includes(insObj.inc_id) && newInsObj.hidden) newInsObj.disabledTooltipMsg = 'Already in process of quoting.';

        return newInsObj;
      });
    },
    canAddForms () {
      return this.nonQuotedApps.length < this.hehexd.length;
    },
    canDeleteForm () {
      return this.nonQuotedApps.length > 1;
    },
    nonQuotedApps () {
      return this.applications.filter(app => app.qot_edit !== 0);
    },
    availableInsCompanies () {
      return this.filteredInsCompanies.filter(el => el.hidden === false);
    },
    isQuoteWon () {
      return this.applications.some(app => app.accepted);
    },
  },
  mounted () {
    this.init();
  },
  methods: {
    async init () {
      this.startLoading();
      await this.getQuoteSummary();
      await this.getAllInsuranceCompanies();
      this.hehexd = this.filteredInsCompanies.filter(el => el.hidden === false);
      this.stopLoading();
    },
    async getQuoteSummary () {
      const apiObj = {
        api: this.globalMixin_api.getLead,
        params: {
          id: this.$route.params.id,
        },
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {

        this.getAppObjStructure(data);
        this.applications = data.quote_info.length === 0? [this.newAppObj] : this.transformQuoteInfo(data);
        this.insCompanies = data.insurance_companies;
      }
    },
    getAppObjStructure (data) {
      const pricesArr = data.data.map(insObj => {
        return {
          ins_id: insObj.ins_id,
          ins_name: insObj.ins_name,
          qpr_monthly: '',
          qpr_yearly: '',
        };
      });
      if (data.quote_info.length > 0) {
        const lastQuote = JSON.parse(JSON.stringify(data.quote_info)).reverse().find(quote => quote.qot_id);
        if (lastQuote) this.newAppId = lastQuote.qot_id + 1;
      }
      this.newAppObj = {
        qot_id: this.newAppId,
        inc_id: null,
        monthly: '',
        yearly: '',
        prices: pricesArr,
      };
    },
    transformQuoteInfo (data) {
      const quoteInfo = JSON.parse(JSON.stringify(data.quote_info));
      for (const quote of quoteInfo) {
        this.companiesAlreadyQuoted.push(quote.inc_id);
        quote.oldPrices = quote.prices;
        quote.prices = [];

        if (data.data.length !== quote.oldPrices) {
          for (const insObj of data.data) {
            const oldPrice = quote.oldPrices.find(priceObj => priceObj.ins_id === insObj.ins_id);
            if (oldPrice) quote.prices.push(oldPrice);
            else quote.prices.push({
              ins_id: insObj.ins_id,
              ins_name: insObj.ins_name,
              qpr_monthly: '',
              qpr_yearly: '',
            });
          }
        }
        delete quote.oldPrices;
      }

      return quoteInfo;
    },
    async getAllInsuranceCompanies () {
      const apiObj = {
        api: this.globalMixin_api.getAgentProfile,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.allAgentInsCompanies = data.data.carriers;
      }
    },
    async onPostQuotes () {
      this.startLoading();
      for (const form of this.nonQuotedApps) {
        form.id = this.$route.params.id;
        form.token = await this.globalMixin_recaptcha();

        if (!form.qot_yearly || !form.qot_monthly) {
          delete form.qot_yearly;
          delete form.qot_monthly;
        }

        const apiObj = {
          api: form.qot_edit? this.globalMixin_api.putLead : this.globalMixin_api.postLead,
          params: form,
        };

        const [data, error] = await this.globalMixin_apiCall(apiObj);
        if (error) {
          this.appendToast({ message: error });
          this.stopLoading();
          return;
        }
      }

      this.appendToast({ message: this.$t('agentProfile.quoteSubmitted'), type: 'success' });
      this.$router.push({ name: "AgentLeadsWrapper" });
      await this.init();
      this.stopLoading();
    },
    onUpdateApplications (appData, index) {
      this.applications.splice(index, 1, appData);
    },
    onAddNew () {
      this.applications.push({
        ...this.newAppObj,
        qot_id: ++this.newAppId,
      });
    },
    async onFinishQuoting () {
      this.hasErrorArr = [];
      this.checkErrors = true;
      // TODO FIX THIS RACE CONDITION
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      const hasError = this.hasErrorArr.some(isTrue => isTrue);
      if (!hasError) {
        this.openSummary = true;
      }
      this.checkErrors = false;
    },
    async onDeleteForm (qot_id) {
      await this.$refs.confirmBox.show({
        message: this.$t("agentProfile.deleteQuoteMessage"),
        callback: this.deleteForm,
        callbackParams: [qot_id],
      });
    },
    deleteForm (qot_id) {
      const indexToDelete = this.applications.findIndex(app => app.qot_id === qot_id);
      this.applications.splice(indexToDelete, 1);
    },
    errorHandler (bool) {
      this.hasErrorArr.push(bool);
    },
  },
};
</script>

<style lang="scss" scoped>
#agency-lead-quote {
  display: flex;
  flex-direction: column;
  // gap: 2em;
  @include flex-gap(2em, 'column nowrap');
  &.top-offset {
    padding-top: $nav-height;
  }
  h1 {
    margin: 0;
    font-size: 2em;
    white-space: nowrap;
  }
  h3 {
    margin: 0;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-wrap {
      width: 60%;
      display: flex;
      align-items: center;
      // gap: 1em 2em;
      @include flex-gap(1.3em, 'row nowrap');
      .back {
        a {
          display: flex;
          // gap: .5em;
          @include flex-gap(.5em, 'row nowrap');
          text-decoration: none;
          span {
            font-weight: 600;
            color: #84899B;
          }
        }
      }
    }
    .profile-notification-wrap {
      display: flex;
      align-items: center;
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
      button {
        height: $notification-size;
        padding: 0 2.5em;
      }
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // gap: 4vw;
    @include flex-gap(4vw, 'row wrap');
    .new {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
      .icon-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        // gap: 1em;
        @include flex-gap(1em, 'row nowrap');
        max-width: 10em;
        img {
          width: max-content;
        }
        span {
          color: #5F6174;
          font-weight: 600;
          font-size: .9em;
          text-align: center;
        }
      }
    }
  }
  .button-line {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 500px) {
  #agency-lead-quote {
    .header {
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
      width: 90%;
        margin-left: 4.5vw;
      .search-wrap {
        justify-content: center;
        // gap: 0;
        @include flex-gap-unset('row nowrap');
        width: max-content;
      }
    }
    .content {
      .form {
        width: 90%;
        .price-row {
          flex-direction: column;
        }
      }
    }
  }
}
</style>
<style lang="scss">
#agency-lead-quote {
  .modal-wrap {
    .form-wrap {
      max-width: 65em;
    }
  }
}
</style>