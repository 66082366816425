<template lang="pug">
.base-checkbox
  input.base-checkbox-input(
    v-bind="$attrs"
    type="checkbox"
    :id="globalMixin_uuid"
    :name="name"
    :value="value"
    :checked="isChecked"
    @change="updateCheckedArray"
  )
  label.base-checkbox-label(
    v-if="label"
    :for="globalMixin_uuid"
    :class="{ 'checked' : isChecked }"
  )
    span.custom-checkbox(
      :class="{ 'checked' : isChecked }"
    )
      img(
        class="check-icon"
        :src="globalMixin_images.checkWhite"
        alt="checkWhite.svg"
      )
    p(
      v-html="label"
    )
    //- | {{ label }}
</template>

<script>
export default {
  props: {
    modelValue: {
      required: true,
    },
    name: {
      type: String,
    },
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: [
    'update:modelValue'
  ],
  computed: {
    isModelBooleanOrString () {
      return (typeof this.modelValue === 'boolean') || (typeof this.modelValue === 'string');
    },
    isChecked () {
      if (this.isModelBooleanOrString) {
        return this.modelValue;
      } else {
        return this.modelValue.includes(this.value);
      }
    },
  },
  methods: {
    updateCheckedArray (e) {
      let modelArray = JSON.parse(JSON.stringify(this.modelValue));
      //if v-model attached is a boolean or string - return true/false (the case is when there is one standalone checkbox)
      //and if v-model attached is an array - return checked values (if there is a checkbox group)
      switch (typeof modelArray) {
        //typeof array is object
        case 'object': {
          if (this.isChecked) {
            modelArray.splice(modelArray.indexOf(this.value), 1);
          } else {
            modelArray.push(this.value);
          }
          this.$emit('update:modelValue', modelArray);
          break;
        }
        default: {
          this.$emit('update:modelValue', e.target.checked);
          break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-checkbox {
  display: flex;
  align-items: center;
  // padding-top: 21px;
  // gap: .5em;
  @include flex-gap(.5em, 'row nowrap');
  // width: $input-width;
  .base-checkbox-input {
    cursor: pointer;
    position: fixed;
    opacity: 0;
    height: 25px;
    width: 25px;
    margin: 0;
    // &:checked ~ .custom-checkbox {
    //   background: $main-blue;
    //   .check-icon {
    //     display: block;
    //   }
    // }
  }
  .base-checkbox-label {
    color: #9CA0AF;
    font-weight: 700;
    user-select: none;
    display: flex;
    align-items: center;
    .custom-checkbox {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 25px;
      min-width: 25px;
      background: #F3F4F4;
      border: 1px solid #E6E6E6;
      border-radius: 6px;
      margin-right: 5px;
      .check-icon {
        display: none;
      }
      &.checked {
        background: $main-blue;
        .check-icon {
          display: block;
        }
      }
    }
    &.checked {
      color: #434961;
    }
  }
}
</style>