import { default as axios } from "./config.js";

export default {
  login: params => axios.post('/auth/login', params),
  logout: () => axios.get('/auth/login'),
  checkSession: () => axios.get('/auth/check-session'),
  register: params => axios.post('/auth/signup', params),
  getQuestions: params => axios.get('/auth/signup', { params }),
  validateUserToken: params => axios.post('/auth/validate', params),
  getZipCodes: () => axios.get('/auth/zipcodes'),

  getUserProfile: () => axios.get('/user/profile'),
  patchUserProfile: params => axios.put('user/profile', params),
  getQuotes: () => axios.get('/user/quotes'),
  delQuote: params => axios.delete(`/user/quotes/${params.id}`), 
  acceptQuote: params => axios.post(`/user/quotes/${params.id}`),

  getApplication: () => axios.get('/user/application'),
  postApplications: params => axios.post('/user/application', params),
  deleteApplication: data => axios.delete('/user/application', { data }),
  postCalcQuestions: params => axios.post(`/estimation/${params.id}`, params),

  getAgentProfile: () => axios.get('/agent/profile'),
  putAgentProfile: params => axios.put('/agent/profile', params),
  postAgentCarriers: params => axios.post('agent/profile/carriers', params),
  delAgentCarrier: data => axios.delete('agent/profile/carriers', { data }),

  getLeads: () => axios.get('/agent/profile/leads'),
  getLead: params => axios.get(`/agent/profile/leads/${params.id}`),
  postLead: params => axios.post(`/agent/profile/leads/${params.id}`, params),
  putLead: params => axios.put(`/agent/profile/leads/${params.id}`, params),
  getStripeKey: () => axios.get('/config'),
  showClientInfo: params => axios.get(`/agent/profile/leads/${params.id}/confirm`),
  getPaymentIntent: params => axios.post(`/agent/profile/leads/${params.id}/confirm`),
  
  getInsuranceTypes: () => axios.get('/insurance-types'),
  // getQuestionnaire: params => axios.get(`/insurance-types/${params.insId}`),

  getInsuranceCompanies: () => axios.get('/insurance-companies'),

  getAgentLeads: params => axios.get('/agent/profile/leads', { params }),
  // TODO use real API for getAgentLeadsCount
  getAgentLeadsCount: params => axios.get('/agent/profile/leads', { params }),
  getAvailableInsuranceCompanies: () => axios.get('/agent/profile/carriers'),

  deleteAgentProfile: () => axios.delete('/agent/profile/delete'),
  deleteUserProfile: () => axios.delete('/user/profile/delete'),
  postFeedback: params => axios.post('/feedback', params),
  verifyAccount: params => axios.post('/auth/verify', params),

  getNotifications: () => axios.get('/notifications'),
  delNotification: params => axios.delete(`/notifications/${params.id}`),
  delAllNotifications: () => axios.delete(`/notifications`),
  markNtfAsRead: params => axios.post(`/notifications/${params.id}`),
  markAllNtfsAsRead: () => axios.post(`/notifications/seen-all`),
  getResetPasswordMail: params => axios.get('/auth/forgot-password', { params }),
  resetPassword: params => axios.post('/auth/forgot-password', params),
};
