<template lang="pug">
.register-application-wrap
  modal(
    v-if="showModal"
    background="#F7F8FBF0"
    @close-modal="$router.push('/')"
  )
    notice-box(
      :message="$t('registration.emailConfirmMessage')"
      :buttonLabel="$t('buttons.goHome')"
      :buttonCallback="goHome"
    )
  .header
    h2.title
      | {{ getTitle }}
    .step(
      v-if="!isMobileMixin_isMobile"
    )
      span.active-step {{ activeStepNumber }}
      span {{ '/' + filteredSections.length }}
  .content-wrap
    .wizard-wrapper
      wizard(
        :steps="filteredSections"
        :horizontal="isMobileMixin_isMobile"
        @set-active-step="handleStep"
      )
    .content(
      v-if="!isLoading"
      ref="content"
    )
      transition(
        :name="currentTransition"
        mode="out-in"
        @beforeLeave="classBindEnter"
        @afterEnter="classBindLeave"
      )
        component(
          v-if="filteredSections.length > 0"
          :key="insRerenderKey"
          :is="activeStep.component"
          :title="activeStep.title"
          :checkErrors="checkErrors"
          :questions="activeStep.questions"
          @update:questions="updateQuestionsHandler"
          @has-error="errorHandler"
          @is-form-filled="setIsFormFilled"
        )
      .button-wrap
        base-button(
          v-if="activeStepIndex + 1 < steps.length"
          :label="$t('buttons.nextStep')"
          :disabled="!isFormFilled"
          bindEnter
          @click="nextStep"
        )
        base-button(
          v-else
          :label="$t('registration.finishRegistration')"
          :disabled="!isFormFilled || isSendingData"
          :loading="isSendingData"
          bindEnter
          @click="submitRegistration"
        )
        base-button(
          v-if="activeStepIndex - 1 >= 0"
          :label="$t('buttons.previousStep')"
          transparent
          @click="previousStep"
        )
</template>

<script>
import RegisterStep1 from '@/components/register-page/application/components/RegisterStep1';
import RegisterStep2 from '@/components/register-page/application/components/RegisterStep2';
import RegisterStep3 from '@/components/register-page/application/components/RegisterStep3';
import RegisterStep4 from '@/components/register-page/application/components/RegisterStep4';
import Modal from '@/components/Modal';
import NoticeBox from '@/components/NoticeBox';
import dayjs from 'dayjs';
export default {
  components: {
    RegisterStep1,
    RegisterStep2,
    RegisterStep3,
    RegisterStep4,
    Modal,
    NoticeBox,
  },
  data () {
    return {
      steps: [],
      forms: [],
      questions: [
        {
          firstName: '',
          lastName: '',
          dateOfBirth: '',
          address1: '',
          city: '',
          state: '',
          address2: '', 
          rents: {
            value: '',
            label: 'Do you rent?',
            options: [
              {
                id: true ,
                label: 'Yes',
              },
              {
                id: false,
                label: 'No',
              },
            ],
          },
          zipCode: {
            options: [],
            value: '',
          },
          notRequiredFields: ['address2'],
        },
        {
          maritalStatus: {
            value: '',
            label: 'Martial Status',
            options: [
              {
                id: 1,
                label: 'Married',
              }, 
              {
                id: 2,
                label: 'Domestic Partner',
              }, 
              {
                id: 0,
                label: 'Single',
              },
            ],
          },
          partnerFirstName: '',
          partnerLastName: '',
          partnerDateOfBirth: '',
          gender: '',
          phoneNumber: '',
          email: '',
        },
        {
          currentInsuranceCompany: {
            options: [],
            value: '',
          },
          emailNotification: true,
          password: '',
          confirmPassword: '',
        },
      ],
      hasError: false,
      checkErrors: false,
      showModal: false,
      isLoading: false,
      isSendingData: false,
      isFormFilled: false,

      //used to triger transition
      insRerenderKey: 0,
      currentTransition: 'slide-left',

      hiddenQuestions: new Set(),
      hiddenAnswers: new Set(),
      superSetChangeToggler: 1,
    };
  },
  computed: {
    activeStep () {
      return this.filteredSections.find(step => step.active);
    },
    activeStepNumber () {
      return this.filteredSections.findIndex(step => step.active) + 1;
    },
    activeStepIndex () {
      return this.activeStepNumber - 1;
    },
    activeStepFromStepsObj () {
      return this.steps.find(step => step.active);
    },
    insuranceTypeId () {
      return Number(this.$route.params.insuranceTypeId);
    },
    getTitle () {
      let title = '';
      switch (this.insuranceTypeId) {
        case 1: 
          title = this.$t('insurances.home');
          break;
        case 2: 
          title = this.$t('insurances.life');
          break;
        case 3: 
          title = this.$t('insurances.auto');
          break;
      }
      return title;
    },
    filteredSections () {
      let data = [];
      for (let i = 0; i < this.steps.length; i++) {
        const section = this.steps[i];
        let temp_section = {
          ...section,
          questions: [],
        };
        if (!Array.isArray(section.questions)) {
          data.push(section);
          continue;
        }

        for (let question of section.questions) {
          if (this.hiddenQuestions.has(question.que_id)) {
            continue;
          }
          if (![1, 2, 3].includes(question.que_type)) {
            temp_section.questions.push(question);
            continue;
          }
          let temp_question = {
            ...question,
            answers: [],
          };
          for (let answer of question.oldAnswers) {
            if (!this.hiddenAnswers.has(answer.ans_id)) {
              temp_question.answers.push(answer);
            }
          }
          temp_section.questions.push(temp_question);
        }
        data.push(temp_section);
      }
      return this.superSetChangeToggler && data;
    },
  },
  created () {
    this.init();
  },
  methods: {
    async init () {
      this.isLoading = true;
      await this.getForms();
      await this.getInsuranceCompanies();
      await this.getZipCodes();
      this.createSteps();
      this.nice();
      // this.bindButtonsToKeys();
      this.isLoading = false;
    },
    async getForms () {
      const apiObj = {
        api: this.globalMixin_api.getQuestions,
        params: {
          insId: this.insuranceTypeId,
        },
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (error) return;
      
      this.forms = data.data;
    },
    async getInsuranceCompanies () {
      const apiObj = {
        api: this.globalMixin_api.getInsuranceCompanies,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.questions[2].currentInsuranceCompany.options = [
          { inc_name: "None", inc_id: 0 },
          ...data.data,
        ];
      }
    },
    async getZipCodes () {
      const apiObj = {
        api: this.globalMixin_api.getZipCodes,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.questions[0].zipCode.options = data.data;
      }
    },
    createSteps () {
      for (let i = 0; i < this.questions.length; i++) {
        this.steps[i] = {
          id: i,
          title: 'Step ' + (i+1),
          component: 'RegisterStep' + (i+1),
          completed: false,
          active: i === 0? true : false,
          questions: this.questions[i],
        };
      }

      let dynamicStep = [];
      for (let i = 0; i < this.forms.length; i++) {
        dynamicStep = [
          ...dynamicStep,
          ...this.forms[i].questions,
        ];
      }

      this.getHiddenFields(dynamicStep);

      this.steps.push({
        id: 3,
        title: 'Step 4',
        component: 'RegisterStep4',
        completed: false,
        active: false,
        questions: dynamicStep.map(queObj => {
          const newQueObj = {
            ...queObj,
            // oldAnswers fix the bug with dynamic answers count
            // Selecting an answer from (hidden) list, prevent the answer from reappearing again
            oldAnswers: queObj.answers,
          };
          if (queObj.answer) {
            if (queObj.answer.ans_id) {
              newQueObj.answer = queObj.answer.ans_id;
            } else {
              newQueObj.answer = queObj.answer.qan_content;
            }
          } else {
            // checkboxGroup requires [] for v-model to handle multiple values
            if (queObj.que_type == 3) newQueObj.answer = [];
            else if (queObj.que_prefill) newQueObj.answer = queObj.que_prefill;
            else newQueObj.answer = '';
          }

          return newQueObj;
        }),
      });
    },
    getHiddenFields (questions) {
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        for (let j = 0; j < question.answers.length; j++) {
          const answer = question.answers[j];
          // need to check already answered questions to prevent all questions from hiding
          const answeredObj = question.answer;
          let answeredValue = null;
          if (answeredObj && answeredObj.ans_id) answeredValue = answeredObj.ans_id;
         
          if (answer.shows_questions !== null) {
            for (let que_id of answer.shows_questions) {
              if (Array.isArray(answeredValue)) {
                if (!answeredValue.includes(answer.ans_id))
                this.addToSet(que_id, this.hiddenQuestions);
              } else {
                if (answeredValue != answer.ans_id) 
                this.addToSet(que_id, this.hiddenQuestions);
              }
            }
          }
          if (answer.shows_answers !== null) {
            for (let ans_id of answer.shows_answers) {
              if (Array.isArray(answeredValue)) {
                if (!answeredValue.includes(answer.ans_id))
                this.addToSet(ans_id, this.hiddenAnswers);
              } else {
                if (answeredValue != answer.ans_id) 
                this.addToSet(ans_id, this.hiddenAnswers);
              }
            }
          }
          if (answer.hides_questions !== null) {
            for (let que_id of answer.hides_questions) {
              if (Array.isArray(answeredValue)) {
                if (answeredValue.includes(answer.ans_id))
                this.addToSet(que_id, this.hiddenQuestions);
              } else {
                if (answeredValue == answer.ans_id) 
                this.addToSet(que_id, this.hiddenQuestions);
              }
            }
          }
          if (answer.hides_answers !== null) {
            for (let ans_id of answer.hides_answers) {
              if (Array.isArray(answeredValue)) {
                if (answeredValue.includes(answer.ans_id))
                this.addToSet(ans_id, this.hiddenAnswers);
              } else {
                if (answeredValue == ans_id) 
                this.addToSet(ans_id, this.hiddenAnswers);
              }
            }
          }
        } 
      }
    },
    addToSet (item, set) {
      this.superSetChangeToggler++;
      set.add(item);
    },
    removeFromSet (item, set) {
      this.superSetChangeToggler++;
      set.delete(item);
    },
    updateQuestionsHandler (filteredQuestions) {
      if (!Array.isArray(filteredQuestions)) {
        for (let i = 0; i < this.steps.length; i++) {
          for (const key in this.steps[i].questions) {
            const questionKeys = Object.keys(filteredQuestions);
            if (questionKeys.includes(key)) {
              this.steps[i].questions = filteredQuestions;
              return;
            }
          }
        }
      } else {
        // find answered questions from array
        let changedQuestionIds = [];
        for (let i = 0; i < filteredQuestions.length; i++) {
          const oldQuestion = this.filteredSections[this.activeStepIndex].questions.find(queObj => queObj.que_id === filteredQuestions[i].que_id);
          // if oldQuestion is a checkbox group, perform array comparison
          if (oldQuestion && Array.isArray(oldQuestion.answer)) {
            // first step - check lenght
            if (oldQuestion.answer.length !== filteredQuestions[i].answer.length) {
              changedQuestionIds.push(filteredQuestions[i].que_id);
            } else {
              // if its the same length - iterate over values
              for (let j = 0; j < oldQuestion.answer.length; j++) {
                if (oldQuestion.answer[j] !== filteredQuestions[i].answer[j]) {
                  changedQuestionIds.push(filteredQuestions[i].que_id);
                }
              }
            }
          // if oldQuestion is an array or string
          } else {
            if (oldQuestion && oldQuestion.answer !== filteredQuestions[i].answer) {
              changedQuestionIds.push(filteredQuestions[i].que_id);
            }
          }
        }
  
        const stepIdForActivation = this.filteredSections[this.activeStepIndex].id;
        const stepIndexFromId = this.steps.findIndex(step => step.id === stepIdForActivation);
        // update question obj
        for (let i = 0; i < changedQuestionIds.length; i++) {
          const questionToReplaceIndex = this.steps[stepIndexFromId].questions.findIndex(queObj => queObj.que_id == changedQuestionIds[i]);
          const question = filteredQuestions.find(queObj => queObj.que_id === changedQuestionIds[i]);
          this.steps[stepIndexFromId].questions.splice(questionToReplaceIndex, 1, question);
        }

        this.nice();
      }
    },
    nice () {
      this.hiddenQuestions.clear();
      this.hiddenAnswers.clear();

      const queList = [];
      for (let i = 0; i < this.steps.length; i++) {
        for (let j = 0; j < this.steps[i].questions.length; j++) {
          queList.push(this.steps[i].questions[j]);
        }
      }

      const section = this.steps[this.steps.length - 1];

      // for (let section of this.steps) {
        for (let question of section.questions) {
          for (let answer of question.answers) {
            if (answer.shows_questions !== null) {
              for (let que_id of answer.shows_questions) {
                this.hiddenQuestions.add(Number(que_id));
              }
            }
            if (answer.shows_answers !== null) {
              for (let ans_id of answer.shows_answers) {
                this.hiddenAnswers.add(Number(ans_id));
              }
            }
          }
        }
      // }
      // for (let section of this.steps) {
        for (let question of section.questions) {
          const answeredObj = question.answer;
          let answeredValue = null;
          if (answeredObj && answeredObj) answeredValue = answeredObj;
          for (let answer of question.answers) {
            let isAnswer = false;
            if (Array.isArray(answeredValue)) {
              if (answeredValue.includes(answer.ans_id)) {
                isAnswer = true;
              }
            } else {
              if (answeredValue == answer.ans_id) {
                isAnswer = true;
              }
            }
            if (isAnswer) {
              if (answer.hides_questions !== null) {
                for (let que_id of answer.hides_questions) {
                  this.hiddenQuestions.add(Number(que_id));
                }
              }
              if (answer.hides_answers !== null) {
                for (let ans_id of answer.hides_answers) {
                  this.hiddenAnswers.add(Number(ans_id));
                }
              }
              if (answer.shows_questions !== null) {
                for (let que_id of answer.shows_questions) {
                  this.hiddenQuestions.delete(Number(que_id));
                }
              }
              if (answer.shows_answers !== null) {
                for (let ans_id of answer.shows_answers) {
                  this.hiddenAnswers.delete(Number(ans_id));
                }
              }
            }
          }
        }
      // }
      this.clearAnswersFromHiddenQuestions();
      this.superSetChangeToggler++;
    },
    clearAnswersFromHiddenQuestions () {
      const hiddenQueList = [];
      for (let i = 0; i < this.steps.length; i++) {
        for (let j = 0; j < this.steps[i].questions.length; j++) {
          if (this.hiddenQuestions.has(this.steps[i].questions[j].que_id)) hiddenQueList.push(this.steps[i].questions[j]);
        }
      }

      let isAnswerDeleted = false;

      for (const queObj of hiddenQueList) {
        if (Number.isInteger(queObj.answer) || queObj.answer.length) {
          queObj.answer = queObj.que_type === 3? [] : '';
          isAnswerDeleted = true;
        }
      }

      if (isAnswerDeleted) this.nice();
    },
    bindButtonsToKeys () {
      window.addEventListener('keyup', evt => {
        if (evt.code === 'Enter') {
          this.nextStep();
        }

        if (evt.code === 'ArrowLeft') {
          this.changeStep(this.activeStepIndex - 1);
        }
      });
    },
    //wizard handler
    async handleStep (index) {
      // one of previous steps
      if (index < this.activeStepIndex) {
        this.currentTransition = 'slide-right';
        this.changeStep(index);
      // one of next steps
      } else if (index > this.activeStepIndex) {
        //prevent going next step if theres an error on current one
        await this.initErrorCheck();
        if (!this.hasError) {
          this.currentTransition = 'slide-left';
          this.activeStepFromStepsObj.completed = true;
          if (this.filteredSections[index].completed) {
            this.changeStep(index);
          } else {
            const uncompletedStep = this.filteredSections.findIndex(stepObj => !stepObj.completed);
            this.changeStep(uncompletedStep);
          }
        }
      }
    },
    async previousStep () {
      this.currentTransition = 'slide-right';
      this.changeStep(this.activeStepIndex - 1);
      const element = this.$refs.content;
      element.scrollIntoView();
    },
    async nextStep () {
      await this.initErrorCheck();

      if (!this.hasError) {
        this.activeStep.completed = true;
        this.checkIfFieldsAreFilledForNextStep();
        this.currentTransition = 'slide-left';
        this.changeStep(this.activeStepIndex + 1);
        const element = this.$refs.content;
        element.scrollIntoView();
      }
    },
    checkIfFieldsAreFilledForNextStep () {
      // if dynamic questions
      if (Array.isArray(this.filteredSections[this.activeStepIndex + 1].questions)) {
        this.isFormFilled = this.filteredSections[this.activeStepIndex + 1].questions.every(que => {
          if (que.answer === null) return false;
          if (que.que_type === 3) return true;
          else return que.answer.length > 0 || (que.answer >= 0 && que.answer !== '');
        });
      } else {
        // if hardcoded questions
        const localQuestionsCopy = JSON.parse(JSON.stringify(this.filteredSections[this.activeStepIndex + 1].questions));
        
        // handles maritalStatus ( 3 fields are hidden, but still within object)
        if (localQuestionsCopy.maritalStatus !== undefined) {
          if (localQuestionsCopy.maritalStatus.value === 0) {
            delete localQuestionsCopy.partnerDateOfBirth;
            delete localQuestionsCopy.partnerFirstName;
            delete localQuestionsCopy.partnerLastName;
          }
        }
  
        for (const key in localQuestionsCopy) {
          if (typeof localQuestionsCopy[key] === 'string') {
            if (localQuestionsCopy[key].length === 0) {
              this.isFormFilled = false;
              return;
            }
          } else {
          // only object is expected here
            if (localQuestionsCopy[key].value === '') {
              this.isFormFilled = false;
              return;
            }
          }
        }
  
        this.isFormFilled = true;
      }
    },
    async initErrorCheck () {
      this.checkErrors = true;
      await this.$nextTick();
      this.checkErrors = false;
    },
    changeStep (index) {
      for (let i = 0; i < this.steps.length; i++) {
        this.steps[i].active = false;
      }

      const stepIdForActivation = this.filteredSections[index].id;
      const stepIndexFromId = this.steps.findIndex(step => step.id === stepIdForActivation);
      this.steps[stepIndexFromId].active = true;
      this.insRerenderKey++;
    },
    async submitRegistration () {
      await this.initErrorCheck();

      if (!this.hasError) {
        this.isSendingData = true;
        const dynamicStep = {...this.filteredSections[this.filteredSections.length - 1]};
        const dynamicParams = this.generateDynamicStepParams(dynamicStep);
                
        const params = this.steps.reduce((paramObj, stepObj) => {
          return paramObj = {
            ...(paramObj.questions || paramObj),
            ...stepObj.questions,
          };
        });

        params.token = await this.globalMixin_recaptcha();
        params.questionAnswers = dynamicParams;
        params.maritalStatus = params.maritalStatus.value;
        params.currentInsuranceCompany = params.currentInsuranceCompany.value != 0? params.currentInsuranceCompany.value : null;
        params.insId = this.insuranceTypeId;
        params.rents = params.rents.value;
        params.zipcodes = [{
          id: params.zipCode.value.zip_id,
        }];
        delete params.zipCode; 
        params.dateOfBirth = dayjs(params.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD');
        if (params.partnerDateOfBirth) params.partnerDateOfBirth = dayjs(params.partnerDateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD');
        if (params.address2.length === 0) delete params.address2;

        
        const apiObj = {
          api: this.globalMixin_api.register,
          params: params,
        };

        const [data, error] = await this.globalMixin_apiCall(apiObj);
        if (error) {
          const errorsArr = error.response.data.data;
          for (let i = 0; i < errorsArr.length; i++) {
            this.appendToast({ type: 'error', message: this.$t(`errors.${errorsArr[i]}`) });
          }
        }
        if (!error) {
          this.showModal = true;
        }

        this.isSendingData = false;
      }
    },
    generateDynamicStepParams (stepObj) {
      let params = [];
      for (let j = 0; j < stepObj.questions.length; j++) {
        const queObj = stepObj.questions[j];
        params.push({
          que_id: queObj.que_id,
          //if question is free input or date, fill qan_content property, otherwise fill and_id
          ans_id: [0, 4, 5, 6].includes(queObj.que_type)? null : queObj.answer,
          ans_content: ![0, 4, 5, 6].includes(queObj.que_type)? null : queObj.answer,
        });
      }
      // TODO remove unnecessary params
      return params;
    },
    errorHandler (bool) {
      this.hasError = bool;
    },
    goHome () {
      this.$router.push('/');
    },
    setIsFormFilled (bool) {
      this.isFormFilled = bool;
    },
    classBindEnter () {
      this.$refs.content.style.overflow = 'hidden';
    },
    classBindLeave () {
      this.$refs.content.style.overflow = 'visible';
    },
  },
};
</script>

<style scoped lang="scss">
.register-application-wrap {
  display: flex;
  flex-direction: column;
  min-height: 65vh;
  // gap: 2.5vw;
  @include flex-gap(2.5em, 'column wrap');
  margin-bottom: 2em;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      width: 100%;
      text-align: center;
      margin: 0;
      font-size: 2em;
    }
    .step {
      .active-step {
        color: $main-blue;
      }
      span {
        color: #a7adc2;
        font-weight: 600;
        font-size: 1.6em;
      }
    }
  }
  .content-wrap {
    display: flex;
    flex-direction: column;
    .wizard-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: .8em;
    }
    .content {
      max-width: 1180px;
      display: flex;
      flex-direction: column;
      // gap: 2em;
      @include flex-gap(.5em, 'column nowrap');
      background: #FFFFFF;
      padding: 1.5em;
      border: 1px solid #DADADA;
      border-left: 6px solid #3E94FF;
      // overflow: hidden;
    }
  }
  .button-wrap {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    // gap: 1em;
    @include flex-gap(0em, 'row-reverse nowrap');
    margin-top: 2em !important;
  }
}

@media (min-width: 769px) {
  .register-application-wrap {
    .header {
      .title {
        width: auto;
        text-align: left;
      }
      justify-content: space-between;
    }
    .content-wrap {
      flex-direction: row;
      .content {
        width: 100%;
        padding: 4em;
      }
    }
    .wizard-wrapper {
      width: min(100%, 35em);
      height: 100%;
    }
    .button-wrap {
      justify-content: flex-start;
    }
  }
}
</style>