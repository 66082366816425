export default {
  computed: {
    isMobileMixin_isMobile () {
      return this.isMobileMixin_width <= 768;
    },
  },
  data () {
    return {
      isMobileMixin_width: null,
    };
  },
  mounted () {
    window.addEventListener('resize', this.isMobileMixin_handleResize);
    this.isMobileMixin_handleResize();
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.isMobileMixin_handleResize);
  },
  methods: {
    isMobileMixin_handleResize () {
      this.isMobileMixin_width = window.innerWidth;
    },
  },
};