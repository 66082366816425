<template lang="pug">
.step-wrap
  h2.title
    span {{ title }}
  .form
    //- which base component to load
    component(
      v-for="question in localQuestions"
      :key="question.que_id"
      v-model="question.answer"
      :is="getQuestionType(question)"
      :placeholder="question.que_placeholder"
      :label="question.que_content"
      :name="`dynamic-input${question.que_id}`"
      :validatorTypes="getValidators(question)"
      :validatorData="getValidatorData(question)"
      :symbol="question.que_symbol"
      :position="question.que_symbol_pos"
      :options="question.answers !== null? question.answers : null"
      :labelKey="'ans_content'"
      :valueKey="'ans_id'"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
</template>

<script>
import stepMixin from '@/js/stepMixin';
export default {
  mixins: [stepMixin],
  watch: {
    questions: {
      handler () {
        this.localQuestions = JSON.parse(JSON.stringify(this.questions));
      },
    },
  },
  methods: {
    getValidators (que) {
      switch (que.que_type) {
        case 5: return ['number', 'required', 'maxLength'];
        case 3: return [];
        case 4: return ['required', 'maxLength', 'regex'];
        case 6: return ['required', 'maxLength', 'number', 'year'];
        default: return undefined;
      }
    },
    getValidatorData (que) {
      switch (que.que_type) {
        case 4: return { regex: this.globalMixin_dateRegex };
        default: return undefined;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.step-wrap {
  h2.title {
    display: flex;
    @include flex-gap(10px, 'row nowrap');
    margin: 0;
    margin-bottom: max(2em, 1.8vw);
    span {
      font-size: 1.4em;
    }
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    // gap: 1.7em 4em;
    @include flex-gap(1.7em, 'row wrap');
    > * {
      width: calc(50% - 2em) !important;
    }
  }
}

@media (max-width: 1367px) {
  .step-wrap {
    .form {
      > * {
        width: calc(100% - 2em) !important;
      }
    }
  }
}

@media (max-width: 689px) {
  .step-wrap {
    .form {
      > * {
        width: 100% !important;
      }
    }
  }
}
</style>