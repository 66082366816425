<template lang="pug">
.home-calculation
  h2 {{ $t('profile.homeCoverage') }}
  .form
    base-input(
      disabled
      :label="$t('profile.homeCoverageButton')"
      :questionmarkHoverText="$t('profile.homeCalcDetails')"
      v-model="getTotalAmount"
    )
</template>

<script>
export default {
  props: {
    totalAmount: {
      type: String,
    },
    otherData: {
      type: Array,
    },
    questions: {
      type: Array,
    },
  },
  computed:{
    getTotalAmount (){
      return this.totalAmount;
    }   ,
  },
};
</script>

<style lang="scss" scoped>
.home-calculation {
  border-top: 2px solid #D7DEE8;
  padding: 1em 0;
  margin-top: 1em;
  h2 {
    margin-top: 0;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
  }
}

@media (min-width: 769px) {
  .home-calculation {
    .form {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      > * {
        width: calc(33% - 2.54em) !important;
      }
    }
  }
}
</style>
<style lang="scss">
.home-calculation {
  label {
    width: 100% !important;
  }
}
</style>