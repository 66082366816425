<template lang="pug">
.verify-wrap
  notice-box(
    :icon="icon"
    :message="message"
    :buttonLabel="label"
    :buttonCallback="callback"
  )
</template>

<script>
import NoticeBox from "../../components/NoticeBox.vue";
export default {
  components: {
    NoticeBox,
  },
  data () {
    return {
      token: null,
      isVerified: false,
      isAgent: false,
    };
  },
  computed: {
    icon () {
      return this.isVerified ? this.globalMixin_images.checkBlue : null;
    },
    message () {
      return this.isVerified? this.$t('landing.successfullyVerified') : this.$t('landing.clickVerify');
    },
    label () {
      return this.isVerified? this.$t('buttons.userLogin') : this.$t('buttons.verify');
    },
    callback () {
      return this.isVerified? this.redirectToLogin : this.verifyAccount;
    },
  },
  mounted () {
    this.token = this.$route.query.token;
    if (!this.token) {
      this.$router.push({name: 'Landing'});
    }
  },
  methods: {
    async verifyAccount () {
      const apiObj = {
        api: this.globalMixin_api.verifyAccount,
        params: { 
          token: this.token,
          google_token: await this.globalMixin_recaptcha(),
        },
      };
      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.isVerified = true;
        this.isAgent = data.type == 2;
      }
    },
    redirectToLogin () {
      if (this.isAgent) this.$router.push({ name: 'AgentLanding', params: { openModal : 'login' } });
      else this.$router.push({ name: 'Landing', params: { openModal : 'login' } });
    },
  },
};
</script>

<style lang="scss" scoped>
.verify-wrap {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: min(90%, 40em);
  }
}
</style>
