<template lang="pug">
.quotes-wrap(
  :class="{ 'top-offset' : isMobileMixin_isMobile }"
)
  profile-navigation(
    v-if="isMobileMixin_isMobile"
  )
  modal(
    v-if="selectedQuote"
    background="#F7F8FBF0"
    @close-modal="selectedQuote = null"
  )
    quote-summary(
      :quote="selectedQuote"
      @on-confirm-quote="acceptQuote"
      @close-modal="selectedQuote = null"
    )
  .header
    .search-wrap
      h1 {{ $t('profile.myQuotes') }}
      //- should go to burger
      //- input.search(
      //-   v-if="!isMobileMixin_isMobile"
      //-   :placeholder="$t('profile.search') + '...'"
      //- )
    .profile-notification-wrap
      base-button(
        v-if="!isMobileMixin_isMobile && application.length > 0"
        :label="summaryLabel"
        :noFillColor="isShowingSummary"
        @click="isShowingSummary = !isShowingSummary"
      )
      sort-by-badge(
        :property="'price'"
        @click="sortQuotesByPrice"
      )
      notification-box
      profile-icon-box(
        v-if="!isMobileMixin_isMobile"
      )
  .content
    .main(
      :class="{ 'column' : cardStrech}"
    )
      .no-data(v-if="quotes.length==0")
        not-found(
          :message="$t('profile.noQuotes')"
        )
      quote-card(
        v-for="(quote, index) in quotes"
        :key="quote.qot_id"
        :quote="quote"
        :class="{ 'safari-fix': (index % 2 === 0), 'strech' : cardStrech }"
        @click="selectedQuote = quote"
        @del-quote="delQuote(quote)"
      )
    transition(
      :name="slideLeftRight"
    )
      quote-application-summary(
        v-if="isShowingSummary"
        :application="application"
      )
</template>

<script>
import ProfileNavigation from '@/components/profile/ProfileNavigation';
import NotificationBox from "@/components/profile/NotificationBox.vue";
import ProfileIconBox from "@/components/profile/ProfileIconBox.vue";
import SortByBadge from "@/components/profile/SortByBadge.vue";
import QuoteCard from "@/components/profile/my-quotes/QuoteCard.vue";
import QuoteSummary from "@/components/profile/my-quotes/QuoteSummary.vue";
import QuoteApplicationSummary from "@/components/profile/my-quotes/QuoteApplicationSummary.vue";
import Modal from "@/components/Modal.vue";
import NotFound from '@/components/NotFound';
export default {
  components: {
    ProfileNavigation,
    NotificationBox,
    ProfileIconBox,
    SortByBadge,
    QuoteCard,
    QuoteSummary,
    QuoteApplicationSummary,
    Modal,
    NotFound,
  },
  data () {
    return {
      quotes: [],
      application: [],
      selectedQuote: null,
      ascending: true,
      isShowingSummary: false,
      
      // used to delay quoteCard width transition
      cardStrech: false,
    };
  },
  computed: {
    summaryLabel () {
      return this.isShowingSummary? this.$t('buttons.closeSummary') : this.$t('buttons.openSummary');
    },
    slideLeftRight () {
      return this.isShowingSummary? 'slide-left' : 'slide-right';
    },
  },
  watch: {
    isShowingSummary: {
      handler (isTrue) {
        if (isTrue) this.cardStrech = true;
        else setTimeout(() => this.cardStrech = false, 117);
      },
    },
  },
  mounted () {
    this.init();
  },
  methods: {
    async init () {
      this.startLoading();
      this.getChosenApplication();
      await this.getQuotes();
      this.stopLoading();
    },
    async getQuotes () {
      const apiObj = {
        api: this.globalMixin_api.getQuotes,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (error) {
        this.appendToast({ message: error.message, timeout: 5000 });
        return;
      }
      if (data) {
        for (let i = 0; i < data.data.length; i++) {
          const quote = data.data[i];
          quote.number = i+1 > 10? i+1 : `0${i+1}`;
        }
  
        this.quotes = data.data;
      }
    },
    async getChosenApplication () {
      const apiObj = {
        api: this.globalMixin_api.getApplication,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);

      if (!error) {
        const selectedInsuranceIds = data.meta.types.map(insObj => insObj.ins_id);
        const rawApplication = data.data.filter(insObj => selectedInsuranceIds.includes(insObj.ins_id));

        this.application = rawApplication.map(app => {
          app.questions = [];
          for (const section of app.sections) {
            app.questions.push(...section.questions);
          }
          // TODO optimize questions map and filtering
          app.questions = app.questions.map(queObj => {
            const newQueObj = {
              ...queObj,
              // oldAnswers fix the bug with dynamic answers count
              // Selecting an answer from (hidden) list, prevent the answer from reappearing again
              oldAnswers: queObj.answers,
            };
            if (queObj.answer) {
              if (queObj.answer.ans_id) {
                newQueObj.answer = queObj.answer.ans_id;
              } else {
                newQueObj.answer = queObj.answer.qan_content;
              }
            } else {
              // checkboxGroup requires [] for v-model to handle multiple values
              if (queObj.que_type == 3) newQueObj.answer = [];
              else if (queObj.que_prefill) {
                newQueObj.answer = queObj.que_prefill;
                // isPrefilled is used for step completion logic
                // if question answer is prefilled, step wont be considered as completed, even tho it has an answer
                newQueObj.isPrefilled = true;
              }
              else newQueObj.answer = '';
            }

            return newQueObj;
          });
          app.questions = app.questions.filter(que => que.answer.length > 0);
          return app;
        });
      }
    },
    async delQuote (qot) {
      const apiObj = {
        api: this.globalMixin_api.delQuote,
        params: {
          id: qot.qot_id,
        },
      };

      await this.globalMixin_apiCall(apiObj);
      await this.getQuotes();
    },
    async acceptQuote (quoteId) {
      this.startLoading();
      const apiObj = {
        api: this.globalMixin_api.acceptQuote,
        params: {
          id: quoteId,
        },
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.appendToast({ message: 'Congratulations! You have chosen your quote. Please wait until the agent comes back to you with further details.', type: 'success' });
      }
      await this.getQuotes();
      this.stopLoading();
    },
    sortQuotesByPrice () {
      if (this.ascending){
        for (let i=0; i<this.quotes.length; i++){
          this.quotes.sort((a, b) => (a.qot_yearly > b.qot_yearly) ? 1 : -1);
        }
      }else{
        for (let i=0; i<this.quotes.length; i++){
          this.quotes.sort((a, b) => (a.qot_yearly < b.qot_yearly) ? 1 : -1);
        }
      }

      this.ascending=!this.ascending;
    },
  },
};
</script>

<style lang="scss" scoped>
.quotes-wrap {
  display: flex;
  flex-direction: column;
  // gap: 1em 0;
  height: 100%;
  width: 100%;
  &.top-offset {
    padding-top: $nav-height;
  }
  h1 {
    margin: 0;
    font-size: 1.8em;
    white-space: nowrap;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 1em;
    .search-wrap {
      // width: 60%;
      margin-right: 1em;
      display: flex;
      align-items: center;
      // gap: 1em 7em;
      @include flex-gap(1em, 'row nowrap');

    }
    .profile-notification-wrap {
      display: flex;
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
    }
  }
  .content {
    display: flex;
    .main {
      display: flex;
      flex-wrap: wrap;
      // flex-direction: column;
      // flex-grow: 1;
      width: 100%;
      .quote-card-wrap{
        margin: 0 0 1em 0;
      }
      .safari-fix {
        margin: 0 0 1em 0;
      }
      p {
        color: #172856;
      }
      .no-data {
        min-height: 82vh;
      }
    }
  }
}

@media (min-width: 950px) {
  .quotes-wrap {
    .content {
      .main {
        &.column {
          flex-direction: column;
          flex-wrap: nowrap;
          margin-right: 1em;
        }
        > * {
          //half of the parent having gap property
          // width: calc(50% - 1em);
          flex-basis: calc(50% - .9em);
          &.strech {
            flex-basis: auto;
          }
        }
        .safari-fix {
          margin: 0 1.8em 1em 0;
        }
        .no-data {
          flex-basis: 100%;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .quotes-wrap {
    .header {
      .profile-notification-wrap {
        width: 100%;
        justify-content: space-between;
        @include flex-gap-unset('row nowrap');
        margin-top: 1em;
      }
    }
  }
}
</style>