<template lang="pug">
.input-wrap
  transition(
    name="fade"
  )
    label(
      v-if="showLabel"
      :for="id"
    )
      | {{ label }}
  input(
    v-bind="$attrs"
    :id="id"
    :value="modelValue"
    :placeholder="placeholder"
    @input="onInput"
    @focus="isInputFocused = true"
    @blur="isInputFocused = false"
  )
  .error {{ error }}
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    label: {
      type: String,
    },
    modelValue: {
      required: true,
    },
    error: {
      type: String,
    },
  },
  emits: [
    'update:modelValue',
    'update:error'
  ],
  data () {
    return {
      isInputFocused: false,
    };
  },
  computed: {
    showLabel () {
      return this.modelValue.length > 0 || this.isInputFocused;
    },
  },
  methods: {
    onInput (evt) {
      this.$emit('update:modelValue', evt.target.value);
      this.$emit('update:error');
    },
  },
};
</script>

<style lang="scss" scoped>
.input-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  // gap: .3em;
  padding-top: 1.5em;
  label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: .9em;
    font-style: italic;
    color: #A3A8B6;
  }
  input {
    border: none;
    height: 3.5em;
    background: #EFEFF8;
    box-shadow: 0px 4px 11px rgba(41, 23, 92, 0.06);
    padding: 0 1.5em;
    color: #172856;
    font-family: "Inter";
    &:active, &:focus {
      border: none;
      outline: none;
    }
  }
  .error {
    display: flex;
    flex-direction: column;
    // gap: .3em;
    position: absolute;
    bottom: -1.5em;
    margin: 0;
    color: $input-error-msg-color;
    font-size: .9em;
    font-style: italic;
    font-family: 'Open Sans';
    font-weight: 600;
    white-space: nowrap;
    padding-left: .5em;
  }
}
</style>