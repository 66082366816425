<template lang="pug">
.quote-form
  h3 {{ $t('agentProfile.submitQuote') }}
  .close-btn(
    v-if="canDelete && !isDisabled"
  )
    img(
      :src="globalMixin_images.close"
      alt="close-btn"
      @click="$emit('onDeleteForm', localApplication.qot_id)"
    )
  base-select-image(
    v-model="localApplication.inc_id"
    :disabled="localApplication.qot_edit !== undefined"
    :options="getOptions"
    :label="$t('agentProfile.selectInsuranceCompany')"
    :isFormSubmitted="formMixin_checkErrors"
    @has-error="formMixin_errorHandler"
  )
  .price-row.bundle(
    v-if="localApplication.prices.length > 1"
  )
    base-input(
      v-model.number="localApplication.qot_monthly"
      :placeholder="bundleMonthlyPlaceholder"
      :disabled="isDisabled"
      :label="$t('agentProfile.bundlePrice')"
      symbol="$"
      :validatorTypes="['maxLength']"
      :position="0"
      :isFormSubmitted="formMixin_checkErrors"
      @has-error="formMixin_errorHandler"
    )
    base-input(
      v-model.number="localApplication.qot_yearly"
      :placeholder="bundleYearlyPlaceholder"
      :disabled="isDisabled"
      symbol="$"
      :validatorTypes="['maxLength']"
      :position="0"
      :isFormSubmitted="formMixin_checkErrors"
      @has-error="formMixin_errorHandler"
    )
  .price-row(
    v-for="(app, index) in localApplication.prices"
    :key="index"
    :class="{ 'is-last' : index + 1 === localApplication.prices.length }"
  )
    base-input(
      v-model.number="app.qpr_monthly"
      :placeholder="$t('profile.monthly')"
      :label="app.ins_name + ' ' + $t('profile.price')"
      :disabled="isDisabled"
      :validatorTypes="['number', 'required', 'maxLength']"
      symbol="$"
      :position="0"
      :isFormSubmitted="formMixin_checkErrors"
      @has-error="formMixin_errorHandler"
    )
    base-input(
      v-model.number="app.qpr_yearly"
      :placeholder="$t('profile.yearly')"
      :disabled="isDisabled"
      :validatorTypes="['number', 'required', 'maxLength']"
      symbol="$"
      :position="0"
      :isFormSubmitted="formMixin_checkErrors"
      @has-error="formMixin_errorHandler"
    )
</template>

<script>
import formMixin from '@/js/formMixin.js';
export default {
  mixins: [
    formMixin,
  ],
  props: {
    id: {
      type: Number,
      required: true,
    },
    insCompanies: {
      type: Array,
      required: true,
    },
    // used to display quotes that have already been quoted for
    allInsCompanies: {
      type: Array,
    },
    application: {
      type: Object,
      required: true,
    },
    checkErrors: {
      type: Boolean,
    },
    canDelete: {
      type: Boolean,
    },
  },
  emits: [
    'update:application',
    'onDeleteForm',
    'has-error',
  ],
  data () {
    return {
      localApplication: JSON.parse(JSON.stringify(this.application)),
    };
  },
  computed: {
    bundlePriceMonthly () {
      const prices = this.localApplication.prices.map(app => app.qpr_monthly);
      return prices.reduce((a, b) => Number(a) + Number(b)) || 0;
    },
    bundlePriceYearly () {
      const prices = this.localApplication.prices.map(app => app.qpr_yearly);
      return prices.reduce((a, b) => Number(a) + Number(b)) || 0;
    },
    bundleMonthlyPlaceholder () {
      return this.bundlePriceMonthly > 0? `$${this.bundlePriceMonthly}` : this.$t('profile.monthly');
    },
    bundleYearlyPlaceholder () {
      return this.bundlePriceYearly > 0? `$${this.bundlePriceYearly}` : this.$t('profile.yearly');
    },
    isDisabled () {
      return this.localApplication.qot_edit == 0;
    },
    getOptions () {
      return this.localApplication.qot_edit !== undefined? this.allInsCompanies : this.insCompanies;
    },
  },
  watch: {
    checkErrors: {
      async handler (isTrue) {
        if (isTrue) {
          await this.formMixin_initErrorCheck();
          if (!this.formMixin_hasError) {
            this.$emit('has-error', false);
          } else {
            this.$emit('has-error', true);
          }
        }
      },
    },
    localApplication: {
      deep: true,
      handler () {
        if (this.localApplication.inc_name === undefined && this.localApplication.inc_id !== null) {
          this.localApplication.inc_name = this.allInsCompanies.find(insObj => insObj.inc_id === this.localApplication.inc_id).inc_name;
        }

        this.$emit('update:application', this.localApplication);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.quote-form {
  display: flex;
  flex-direction: column;
  // gap: 1.5em;
  @include flex-gap(1.5em, 'column nowrap');
  // margin: 0 auto;
  padding: min(1.5em, 2vw);
  width: max-content;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  position: relative;
  h3 {
    margin: .5em 0;
  }
  .close-btn {
    position: absolute;
    right: 1em;
    // uncomment for gap
    top: .2em;
    cursor: pointer;
  }
  .price-row {
    display: flex;
    align-items: flex-end;
    // gap: 1em;
    @include flex-gap(1em, 'row nowrap');
    padding-bottom: 2em;
    border-bottom: 2px solid #DEDEE9B3;
    &.is-last {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  .button-line {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}
@media (max-width: 450px) {
  .price-row {
    flex-wrap: wrap;
  }
}
@media (max-width: 769px) {
  .quote-form {
    padding: 3em;
  }
}
</style>