<template lang="pug">
.how-it-works-wrap
  .img-wrap
    img(
      :src="globalMixin_images.landingWoman"
      alt="landing-woman.svg"
    )
  .content
    h1 {{ $t('landing.howItWorks') }}
    .blocks-wrap
      .text-block
        .icon-wrap
          img(
            :src="globalMixin_images.lightbulbWhite"
          )
        .paragraph
          h3 {{ $t('landing.coverageNeeds') }}
          p.text
            | {{ $t('landing.coverageAssessment') }}
      .text-block
        .icon-wrap
          img(
            :src="globalMixin_images.magnifyingGlass"
          )
        .paragraph
          h3 {{ $t('landing.weGetToWorkForYou') }}
          p.text
            | {{ $t('landing.howItWorksWorkForYouParagraph') }}
      .text-block
        .icon-wrap.check
          img(
            :src="globalMixin_images.note"
          )
          img.checkIcon(
            :src="globalMixin_images.checkBlue"
          )
        .paragraph
          h3 {{ $t('landing.chooseQuote') }}
          p.text
            | {{ $t('landing.reviewCustomQuotesParagraph') }}
</template>

<script>
export default {
  
};
</script>

<style lang="scss" scoped>

.how-it-works-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  // gap: 3em 9em;
  @include flex-gap(8em, 'row nowrap');
  > * {
    flex-basis: 100%;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    // gap: 2em;
    @include flex-gap(2em, 'column nowrap');
    h1 {
      margin: 0;
      margin-top: 1em !important;
      margin-bottom: 1em;
      font-size: 3.8em;
      color: #172856;
      font-family: 'Poppins', sans-serif;
    }
    .blocks-wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // gap: 1.5em;
      @include flex-gap(6.5em, 'column nowrap');
      height: 100%;
      .text-block {
        display: flex;
        align-items: center;
        // gap: 2.5em;
        @include flex-gap(2.5em, 'row nowrap');
        .icon-wrap {
          position: relative;
          $size: 4.5em;
          min-width: $size;
          min-height: $size;
          max-width: $size;
          max-height: $size;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $main-blue;
          border-radius: 50%;
          border: 9px solid $main-light-blue;
          &.check {
            .checkIcon {
              $checkSize: 9px;
              position: absolute;
              width: $checkSize;
              height: $checkSize;
            }
          }
        }
        .paragraph {
          display: flex;
          flex-direction: column;
          height: 100%;
          font-family: 'Poppins', sans-serif;
          // gap: 1em;
          p, h3 {
            margin: 0;
          }
          p {
            line-height: 2em;
            color: #57595A;
            font-size: 1.2em;
          }
          h3 {
            font-size: 1.6em;
            font-weight: 600;
            // uncomment for gap
            margin-bottom: 1.2em;
          }
        }
      }
    }
  }
  .img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: min(450px, 100%);
      margin-left: 3vw;
      margin-top: 4em;
    }
  }
}

@media (max-width: 1070px) {
  .how-it-works-wrap {
    @include flex-gap-unset('row nowrap');
    flex-direction: column-reverse;
    .content {
      width: 100%;
      align-items: center;
      // uncomment for gap
      margin-bottom: 1em;
      .blocks-wrap {
        flex-direction: row;
        .text-block {
          flex-basis: 100%;
        }
      }
    }
    .img-wrap {
      img {
        margin: 0;
      }
    }
  }
}

@media (max-width: 878px) {
  .how-it-works-wrap {
    .content {
      h1 {
        margin: 0;
        margin-bottom: .5em;
      }
      .blocks-wrap {
        flex-direction: column;
      }
    }
  }
}

@media (max-width: 769px) {
  .how-it-works-wrap {
    margin-top: 1em;
    .content {
      h1 {
        font-size: 3em;
      }
    }
  }
}
</style>