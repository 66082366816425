import { createStore } from "vuex";
import { toastStore } from './modules/toastStore';
import { loaderStore } from './modules/loaderStore';
import { notificationsStore } from './modules/notificationsStore';

export default createStore({
  modules: {
    toastStore,
    loaderStore,
    notificationsStore,
  },
  state: {
    isLoggedIn: false,
    // User type ID (1-Consumer, 2-Agent)
    userType: null,
    hasCarriers: null,
  },
  mutations: {
    SET_IS_LOGGED_IN (state, payload) {
      state.isLoggedIn = payload;
    },
    SET_USER_TYPE (state, payload) {
      state.userType = payload;
    },
    SET_HAS_CARRIERS (state, payload) {
      state.hasCarriers = payload;
    },
  },
  actions: {
    setIsLoggedIn ({ commit }, payload) {
      commit('SET_IS_LOGGED_IN', payload);
    },
    setUserType ({ commit }, payload) {
      commit('SET_USER_TYPE', payload);
    },
    setHasCarriers ({ commit }, payload) {
      commit('SET_HAS_CARRIERS', payload);
    },
  },
});