<template lang="pug">
.application-wrap(
  :class="{ 'top-offset' : isMobileMixin_isMobile }"
)
  confirm-box(
    ref="confirmBox"
  )
  modal(
    v-if="showModal"
    @close-modal="$router.push({ name: 'UserDashboard' })"
  )
    //- background="#F7F8FBF0"
    notice-box(
      :icon="globalMixin_images.checkBlue"
      :message="$t('profile.submittedApp')"
      :buttonLabel="$t('buttons.ok')"
      :buttonCallback="() => $router.push({ name: 'UserDashboard' })"
    )
  profile-navigation(
    v-if="isMobileMixin_isMobile"
  )
  .header
    .h1-wrap
      h1 
        | {{ $t('profile.application') }}
        notification-box(v-if="isMobileMixin_isMobile")
      .badges
        insurance-badge(
          v-for="insObj in insuranceTypes"
          :checked="insObj.selected"
          :type="getInsType(insObj)"
          :disabled="isLocked"
          style="margin: 0.5em;"
          @update-checked="selectedInsuranceHandler(insObj, $event)"
        )
    .profile-notification-wrap(
      v-if="!isMobileMixin_isMobile"
    )
      //- input.search(
      //-   v-if="!isMobileMixin_isMobile"
      //-   :placeholder="$t('profile.search') + '...'"
      //- )
      notification-box
      profile-icon-box
  transition(
    name="fade"
    mode="out-in"
  )

    .no-data(
      v-if="selectedInsurances.length === 0 && isInit"
    )
      not-found(
        :message="$t('profile.noApplicationSelected')"
      )
    .content(
      v-else
    )
      transition(
        name="fade"
      )
        .button-line(
          v-if="selectedInsurances.length > 0"
        )
          base-button(
            v-if="!isSubmitted"
            noFillColor
            label="Save Progress"
            @click="onSaveApplicationProgress"
          )
          base-button(
            v-if="!quoteWon"
            :disabled="!isLocked"
            label="Unlock to edit Application"
            @click="onEditApplication"
          )
      .sub-date(
        v-if="date"
      ) 
        | {{ 'Submission date: ' + date }}
      transition-group.forms-wrap(
        name="fade"
        tag="div"
        appear
      )
        insurance-questionnaire(
          v-for="insObj in selectedInsurances"
          :key="insObj.ins_id"
          :insObj="insObj"
          :isLocked="insObj.locked"
          :isEditing="isEditing"
          :type="getInsType(insObj)"
          :hasNext="hasNext"
          :id="getInsType(insObj)"
          @scrollToNext="scrollToNext(insObj.ins_id)"
          @submitApplication="onSubmitApplication"
          @post-answers="getAnswersForInsuranceType"
          @is-form-filled="isFormFilled"
        )
  transition(
    name="fade"
  )
    .button-line.del(
      v-if="!quoteWon && (isSubmitted || isSaved)"
    )
      base-button(
        noFillColor
        :label="$t('buttons.deleteApplicaton')"
        @click="onResubmit"
      )
</template>

<script>
import NotificationBox from "@/components/profile/NotificationBox.vue";
import ProfileIconBox from "@/components/profile/ProfileIconBox.vue";
import InsuranceBadge from "@/components/profile/application/components/InsuranceBadge.vue";
import ProfileNavigation from '@/components/profile/ProfileNavigation';
import InsuranceQuestionnaire from "@/components/profile/application/InsuranceQuestionnaire.vue";
import ConfirmBox from "@/components/ConfirmBox.vue";
import Modal from '@/components/Modal';
import NoticeBox from '@/components/NoticeBox';
import NotFound from '@/components/NotFound';
import dayjs from 'dayjs';
export default {
  components: {
    ProfileNavigation,
    NotificationBox,
    ProfileIconBox,
    InsuranceBadge,
    InsuranceQuestionnaire,
    ConfirmBox,
    Modal,
    NoticeBox,
    NotFound,
  },
  data () {
    return {
      insuranceTypes: [],
      isInit: false,
      activeInsuranceTypes: [],
      insuranceAnswers: [],
      isLocked: false,
      isSubmitted: false,
      isSaved: false,
      quoteWon: false,
      isEditing: false,
      showModal: false,
      date: null,
    };
  },
  computed: {
    selectedInsurances () {
      const newArr = this.insuranceTypes.filter(insObj => insObj.selected);
      if (this.isEditing) {
        newArr.sort((a, b) => a.locked === b.locked? 0 : a? -1 : 1);
      }
      return newArr;
    },
    isAnyInsuranceDisabled () {
      return this.insuranceTypes.some(insObj => insObj.disabled);
    },
    hasNext () {
      if(this.selectedInsurances.length > 1){
        let res = false;
        for (const insurance of this.selectedInsurances) {
          if(!insurance.filled && !insurance.locked) res = true;
        }
        return res;
      } else {
        return false;
      }
    },
  },
  watch: {
    selectedInsurances: {
      deep: true,
      handler () {
        const selectedInsuranceIds = this.selectedInsurances.map(insObj => insObj.ins_id);
        for (let i = 0; i < this.insuranceAnswers.length; i++) {
          if (!selectedInsuranceIds.includes(this.insuranceAnswers[i].ins_id)) {
            this.insuranceAnswers.splice(i, 1);
          }
        }
      },
    },
  },
  created () {
    this.init();
    this.subDate();
  },
  methods: {
    async init () {
      this.startLoading();
      await this.getChosenApplication();
      this.isInit = true;
      this.stopLoading();
    },
    async getChosenApplication () {
      const apiObj = {
        api: this.globalMixin_api.getApplication,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);

      if (!error) {
        const selectedInsuranceIds = data.meta.types.map(insObj => insObj.ins_id);

        this.insuranceTypes = data.data.map(insObj => {
          insObj.locked = false;
          if (selectedInsuranceIds.includes(insObj.ins_id)) {
            insObj.selected = true;
            insObj.locked = !!data.meta.locked;
            // this property is used to help set completed steps
            // if this ins is completed initially, checkbox-only steps will be marked as completed
            // if its later-added insurance, checkbox-only steps will be marked as not completed
            insObj.isCompletedInitially = !!data.meta.aps_submitted;
          }
          
          return insObj;
        });
        this.isSubmitted = !!data.meta.aps_submitted;
        this.isSaved = !this.isSubmitted && data.meta.types.length > 0;
        this.isLocked = !!data.meta.locked;
        this.quoteWon = !!data.meta.qot_id;
        if(data.meta.aps_submitted !== null){
          this.subDate(data.meta.aps_submitted);
        } else {
          this.date = null;
        }
      }
    },
    getAnswersForInsuranceType (answers) {
      const existingAnswerObjIndex = this.insuranceAnswers.findIndex(ansObj => ansObj.ins_id === answers.ins_id);

      if (existingAnswerObjIndex > -1) {
        this.insuranceAnswers.splice(existingAnswerObjIndex, 1, answers);
      } else {
        this.insuranceAnswers.push(answers);  
      }
    },
    async onSaveApplicationProgress () {
      const filteredAnswers = this.insuranceAnswers.map(ansObj => {
        const filledAnswers = ansObj.answers.filter(ansEl => ansEl.ans_id || ansEl.ans_content);
        return {
          ins_id: ansObj.ins_id,
          answers: filledAnswers,
        };
      });

      for (let i = 0; i < filteredAnswers.length; i++) {
        filteredAnswers[i].answers = filteredAnswers[i].answers.map(ans => {
          if (Array.isArray(ans.ans_id)) {
            if (ans.ans_id.includes('none')) ans.ans_id = [];
          }

          return ans;
        });
      }
      this.startLoading();
      const apiObj = {
        api: this.globalMixin_api.postApplications,
        params: {
          answers: filteredAnswers,
        },
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);

      if (!error) {
        this.appendToast({ message: this.$t('profile.applicationSaveProgress'), type: "success" });
        this.init();
      }
      this.stopLoading();
    },
    async onSubmitApplication () {
      this.startLoading();
      let hasNotFilledAnswer = false;

      const filteredAnswers = this.insuranceAnswers.map(ansObj => {
        const filledAnswers = ansObj.answers.filter(ansEl => {
          if ([141, 140].includes(ansEl.que_id)) return true;
          else return ansEl.ans_id !== null || ansEl.ans_content !== null;
        });
        if (filledAnswers.length !== ansObj.answers.length) {
          hasNotFilledAnswer = true;
        }
        return {
          ins_id: ansObj.ins_id,
          answers: filledAnswers,
        };
      });

      if (hasNotFilledAnswer) {
        this.appendToast({ message: this.$t('profile.applicationNotAllFilled') });
        this.stopLoading();
        return;
      }

      // special check for "none checkbox types"
      for (let i = 0; i < filteredAnswers.length; i++) {
        filteredAnswers[i].answers = filteredAnswers[i].answers.map(ans => {
          if (Array.isArray(ans.ans_id)) {
            if (ans.ans_id.includes('none')) ans.ans_id = [];
          }

          return ans;
        });
      }

      const params = {
        answers: filteredAnswers,
        submit: true,
      };
      if (this.isSubmitted) params.force = true;

      const apiObj = {
        api: this.globalMixin_api.postApplications,
        params,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);

      if (!error) {
        // this.appendToast({ message: this.$t('profile.applicationSubmitSuccess'), type: "success" });
        this.showModal = true;
        // this.init();
      }
      this.stopLoading();
      this.isLocked = true;

    },
    async onResubmit () {
      await this.$refs.confirmBox.show({
        message: this.$t('profile.deleteAppMsg'),
        callback: this.deleteApplication,
      });
    },
    async deleteApplication () {
      const apiObj = {
        api: this.globalMixin_api.deleteApplication,
        params: { 
          force: true,
        },
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);

      if (!error) {
        this.appendToast({ message: this.$t('profile.applicationDelete'), type: 'success' });
        this.init();
      }
    },
    onEditApplication () {
      this.isLocked = false;
      this.isEditing = true;
    },
    selectedInsuranceHandler (insObj, checked) {
      insObj.selected = checked;
    },
    getInsType ({ ins_id }) {
      switch (ins_id) {
        case 1: return 'home';
        case 2: return 'life';
        case 3: return 'auto';
      }
    },
    isFormFilled (val) {
      this.insuranceTypes[val.id - 1].filled = val.isFilled;
    },
    scrollToNext (id) {
      let notFilledYet = this.insuranceTypes.filter((el, index, arr) => { return arr[index].filled === false; });
      if(notFilledYet.length > 0){
        const nesto = document.getElementById(this.getInsType(notFilledYet[0]));
        if(nesto){
          nesto.scrollIntoView();
        }
      }
    },
    subDate (date) {
      this.date = dayjs(dayjs(date).utc(true), 'YYYY-MM-DD HH:mm:ss').tz(dayjs.tz.guess()).format('MM/DD/YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.application-wrap {
  display: flex;
  flex-direction: column;
  // gap: 1em;
  @include flex-gap(1em, 'column wrap');
  height: 100%;
  &.top-offset {
    padding-top: $nav-height;
  }
  h1 {
    margin: 0;
    font-size: 1.8em;
  }
  .header {
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    // gap: 1em 3em;
    > * {
      display: flex;
      align-items: center;
    }
    .h1-wrap {
      display: flex;
      flex-wrap: wrap;
      // margin-right: 3em;
      // gap: 1em 3em;
      justify-content: space-between;
      h1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin-right: 3em;
      }
    }
    .badges {
      display: flex;
      // flex-wrap: wrap;
      // gap: 1em;
      justify-content: flex-start;
      > * {
        padding: 0;
      }
    }
    .profile-notification-wrap {
      display: flex;
      width: 35%;
      // gap: 0 1em;
      .notification-wrap {
        margin-right: 1em;
      }
      justify-content: flex-end;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    // gap: 1em;
    @include flex-gap(1em, 'column nowrap');
    .sub-date {
      font-weight: 600;
      color: #888FA6;
    }
  }
  .button-line {
    // margin: .5em 0;
    display: flex;
    justify-content: flex-start;
    // gap: 1em;
    @include flex-gap(1em, 'row nowrap');

    &.del {
      margin-top: 1.5em;
    }
  }
  .forms-wrap {
    display: flex;
    flex-direction: column;
    // gap: 1em;
    @include flex-gap(1em, 'column nowrap');
    height: 100%;
  }
}
@media (max-width: 900px) {
  .badges {
    flex-wrap: wrap;
  }
}
</style>
<style lang="scss">
.application-wrap {
  .notice-wrap {
    p {
      font-weight: 600;
      font-size: 1.2em;
    }
  }
}
</style>