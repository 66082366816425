<template lang="pug">
.landing-agent-registration
  modal(
    class="confirm-email"
    v-if="showModal"
    @close-modal="emitCloseModal"
  )
    notice-box(
      :message="$t('registration.emailConfirmMessage')"
      :buttonLabel="$t('buttons.goHome')"
      :buttonCallback="emitCloseModal"
    )
  .form(
    ref="form"
  )
    p {{ $t('landing.agentRegistration') }}
    .inputs-wrap
      agent-input(
        id="agentInput1"
        v-model.trim="form.name.value"
        :label="$t('registration.yourName')"
        :placeholder="$t('registration.yourName')"
        :error="form.name.error"
        @update:error="form.name.error = ''"
      )
      agent-input(
        id="agentInput1"
        v-model.trim="form.lastname.value"
        :label="$t('registration.yourLastName')"
        :placeholder="$t('registration.yourLastName')"
        :error="form.lastname.error"
        @update:error="form.lastname.error = ''"
      )
      agent-input(
        id="agentInput2"
        v-model.trim="form.email.value"
        type="email"
        :label="$t('form.email')"
        :placeholder="$t('form.email')"
        :error="form.email.error"
        @update:error="form.email.error = ''"
      )
      agent-input(
        id="agentInput3"
        v-model.trim="form.phone.value"
        v-mask="globalMixin_phoneMask"
        autocomplete="off"
        :label="$t('form.phone')"
        :placeholder="$t('form.phone')"
        :error="form.phone.error"
        @update:error="form.phone.error = ''"
      )
      agent-input(
        id="agentInput4"
        v-model="form.password.value"
        type="password"
        :label="$t('form.password')"
        :placeholder="$t('form.password')"
        :error="form.password.error"
        @update:error="form.password.error = ''"
      )
      agent-input(
        id="agentInput5"
        v-model="form.confirmPassword.value"
        type="password"
        :label="$t('form.confirmPassword')"
        :placeholder="$t('form.confirmPassword')"
        :error="form.confirmPassword.error"
        @update:error="form.confirmPassword.error = ''"
      )
      .terms-wrap
        .checkbox-wrap
          base-checkbox(
            v-model="terms"
            class="checkbox"
            :label="$t('registration.termsOfUse')"
          )
          span(
            v-if="showTermsError"
          ) {{ $t('registration.youMustAcceptTerms') }}
        .terms-text
          a(
            href="/terms/agent.pdf"
            target="_blank"
          ) {{ $t('registration.terms') }}
    .button-wrap
      base-button(
        :label="$t('buttons.getStarted')"
        :left-icon="globalMixin_images.popupText"
        :loading="loading"
        :disabled="loading"
        aqua
        @click="submitRegistration"
      )
  .img-wrap
    img(
      :src="globalMixin_images.landingAgentRegistrationImage"
    )
</template>

<script>
import AgentInput from '../landing-page/AgentInput.vue';
import Modal from '@/components/Modal';
import NoticeBox from '@/components/NoticeBox';
export default {
  components: {
    AgentInput,
    Modal,
    NoticeBox,
  },
  data () {
    return {
      form: {
        name: {
          value: '',
          error: '',
        },
        lastname: {
          value: '',
          error: '',
        },
        email: {
          value: '',
          error: '',
        },
        phone: {
          value: '',
          error: '',
        },
        password: {
          value: '',
          error: '',
        },
        confirmPassword: {
          value: '',
          error: '',
        },
      },
      terms: false,
      showTermsError: false,
      showModal: false,
      loading: false,
    };
  },
  watch: {
    terms: {
      handler (isTrue) {
        this.showTermsError = false;
        if (isTrue) {
          const element = this.$refs.form;
          element.scrollTop = element.scrollHeight;
        }
      },
    },
  },
  methods: {
    async submitRegistration () {
      this.loading = true;
      const hasErrors = await this.checkErrors();

      if (!hasErrors) {
        const params = {
          agent: true,
          token: await this.globalMixin_recaptcha(),
        };


        for (const key in this.form) {
          params[key] = this.form[key].value;
        }

        const apiObj = {
          api: this.globalMixin_api.register,
          params,
        };
  
        const [data, error] = await this.globalMixin_apiCall(apiObj);
  
        if (!error) {
          this.showModal = true;
        } else {
          this.form.email.error = 'A user with that e-mail already exists.';
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
    },
    checkErrors () {
      this.clearErrors();
      return new Promise (resolve => {
        const minPasswordLength = 8;
        if (this.form.password.value.length <= minPasswordLength) {
          this.form.password.error = `Password must contain more than ${minPasswordLength} characters.`;
        }

        if (this.form.confirmPassword.value.length !== this.form.password.value.length) {
          this.form.confirmPassword.error = 'Passwords must match.';
        }
        
        // Phone number format is stored in globalMixin_phoneMask variable (Currently '(###)###-####')
        // with "(", ")" and "-" chars and digitsCount we get rawPhoneFormatLength (3 + 10 = 13)
        const digitsCount = 10;
        const phoneNonDigitsCount = 3;
        const rawPhoneFormatLength = digitsCount + phoneNonDigitsCount;
        if (this.form.phone.value.length < rawPhoneFormatLength) {
          this.form.phone.error = `Phone number must be ${digitsCount} characters long.`;
        }

        if (!this.globalMixin_emailRegex.test(this.form.email.value)) this.form.email.error = 'Enter a valid email address.';

        const maxLength = 255;
        for (const key in this.form) {
          if (this.form[key].value.length === 0) this.form[key].error = 'This field is required.';
          if (this.form[key].value.length > maxLength) this.form[key].error = 'Must contain less characters.';
        }

        if (!this.terms) this.showTermsError = true;

        for (const key in this.form) {
          if (this.form[key].error.length > 0) resolve(true);
        }
        if (this.showTermsError) resolve(true);

        resolve(false);
      });
    },
    clearErrors () {
      for (const key in this.form) {
        this.form[key].error = '';
      }

      this.showTermsError = false;
    },
    emitCloseModal () {
      this.$emit('close-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-agent-registration {
  display: flex;
  justify-content: space-between;
  background: #EFEFF8;
  border-radius: 10px;
  overflow: hidden;
  max-height: 95vh;
  * {
    font-family: 'Inter';
    // font-weight: 400;
  }
  .confirm-email {
    max-height: 100%;
  }
  .form {
    display: flex;
    flex-direction: column;
    // gap: 2vw;
    // @include flex-gap(1vw, 'column nowrap');
    padding: clamp(1em, 1.5vw, 3em);
    flex-grow: 1;
    max-width: 40em;
    overflow: auto; 
    scroll-behavior: smooth;
    > p {
      margin: 0;
      color: #172856;
      font-family: 'Inter';
      font-size: clamp(1.8em, 3vw, 3em);
      line-height: 1.1;
      font-weight: 600;
    }
    .inputs-wrap {
      display: flex;
      flex-direction: column;
      // padding: 1em 0 0 0;
      @include flex-gap(1.5em, 'column nowrap');
      .terms-wrap{  
        display: flex;
        align-items: center;
        justify-content: space-between;
        .checkbox-wrap {
          position: relative;
          .checkbox {
            height: 2.5em;
          }
          span {
            display: flex;
            flex-direction: column;
            // gap: .3em;
            @include flex-gap(.3em, 'column nowrap');
            position: absolute;
            bottom: -1.5em;
            margin: 0;
            color: $input-error-msg-color;
            font-size: .9em;
            font-style: italic;
            font-family: 'Open Sans'; //Open Sans
            font-weight: 600;
            white-space: nowrap;
            padding-left: .5em;
          }
          
        }
        .terms-text{
          display: flex;
          align-items: center;
          a {
            text-decoration: none;
            color: $main-blue;
            font-weight: bold;
          }
        }
      }
    }
    .message {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // gap: 2em;
      @include flex-gap(2em, 'column nowrap');
      padding: 1em;
      background: #EAEAF8;
      border-left: 5px solid #4092FE;
      p {
        font-size: .8em;
        color: #172856;
        margin: 0;
      }
      .icon {
        min-width: 35px;
        min-height: 35px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .button-wrap {
      margin-top: 2vw;
    }
  }
  .img-wrap {
    // background: url("../../assets/images/landing-agent-registration-image.png") no-repeat center center;
    // flex-basis: 500px;
    // width: max(100%, 700px);
    // width: 600px;
    // background-size: cover;
    img {
      // height: 100%;
      min-height: 102%;
    }
  }
}

@media (max-width: 950px) {
  .landing-agent-registration {
    .img-wrap {
        background: url("../../assets/images/agent-registration-illustration.png") no-repeat center center;
        background-size: cover;
        width: 600px;
      img {
        display: none;
      }
    }
  }
}

@media (max-width: 900px) {
  .landing-agent-registration {
    .form {
      width: 55%;
    }
  }
}

@media (max-width: 550px) {
  .landing-agent-registration {
    .form {
      width: 75%;
      > p {
        text-align: center;
      }
    }
  }
}

@media (max-width: 450px) {
  .landing-agent-registration {
    max-height: 85vh;
    .img-wrap {
      display: none;
    }
    .form {
      width: 100%;
    }
  }
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .landing-agent-registration {
    .form {
      padding: 1.5vw;
      > p {
        font-size: 3vw;
      }
    }
  }
}}
</style>