<template lang="pug">
.wrap
    base-loader(
        size=100
    )
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.wrap{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba($color: #000000, $alpha: 0.3);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
</style>