<template>
  <div id="calendar">
    <div id="month">
      <div class="month-content">
        <div class="selector"> 
          <select @change="createCalendar" v-model="selectedYear" class="picker year" placeholder="YYYY">
            <option v-for="i in years" :key="i + 'years'" > {{ i }} </option>  
          </select>
          <select @change="createCalendar" v-model="selectedMonth" class="picker month">
            <option v-for="(month, key) in months" :key="month" :value="key">{{ month }} </option>  
          </select>  
        </div>
      </div>
     
    </div>
    <div class="days-preview">
      <div class="week-days">
          
          <div class="day-names">Mo</div>
          <div class="day-names">Tu</div>
          <div class="day-names">We</div>
          <div class="day-names">Th</div>
          <div class="day-names">Fr</div>
          <div class="day-names">Sa</div>
          <div class="day-names">Su</div>
        
      </div>
      <div class="day-of-month">

          <div class="current-month">
            <div @click="emitDateAndSetClickedDate(i)" class="days" v-for="(i, index) in combinedDays" :key="index">
              <div class="day-wrap" :class="{ 'selected' : clickedDate.day == i.day && clickedDate.month == i.month && clickedDate.year == i.year, 'gray' : !i.isCurrentMonth}">
                {{ i.day }}
              </div>
            </div>
          </div>

          <div class="next-month">

          </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  props : [ 'theDate' ],
  emits : ['dayChange'],
  data () {
    
      let firstDayOfMonth = 0;
      
      return {
        selectedDay : '',
        selectedMonth : '',
        selectedYear : '',
        clickedDate: {},
        firstDayOfMonth,
        monthDays : "",
        lastMonth : [],
        currentMonth : [],
        nextMonth : [],
        years : [],
    };
  },
  computed: {
    combinedDays () {
      return [
        ...this.lastMonth,
        ...this.currentMonth,
        ...this.nextMonth,
      ];
    },
    months () {
      return {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      };
    },
  },
  watch: {
    theDate: {
      handler () {
        this.init();
        this.createCalendar();
      },
    },
  },
  mounted () {
    this.init();
    this.createCalendar();
  },
  methods: {
    init () {
      let dateObj = this.theDate? new Date(this.theDate) : new Date();
      //valid date check
      if (isNaN(dateObj.getMonth())) dateObj = new Date();

      this.setClickedDate(dateObj);
      this.setSelectedDate(dateObj);

      // prvo mi trebaju mesec i godina da bi mogao da postavim adekvatan broj dana
      // if (!this.theDate) {
      //   let date = Date();
      //   this.selectedMonth = new Date().getMonth() +1;
      //   this.selectedYear = date.split(' ')[3];
      //   this.selectedDay = date.split(' ')[2];
      // } else {
      //   this.selectedMonth = this.clickedDate.month;
      //   this.selectedYear = this.clickedDate.year;
      //   this.selectedDay = this.clickedDate.day;
      // }
    },
    createCalendar () {
      this.numberOfDays = new Date(this.selectedYear , this.selectedMonth, 0).getDate();

      this.monthDays = new Date(parseInt(this.selectedYear), parseInt(this.selectedMonth), 0).getDate();
      
      this.years = [];

      for( let i= 1900; i<= 2099 ; i++ ) {
          this.years.push(i);
        }
      
      let lastMonthDays = new Date(parseInt(this.selectedYear), parseInt(this.selectedMonth) -1, 0).getDate();
      this.firstDayOfMonth = new Date(parseInt(this.selectedYear),parseInt(this.selectedMonth)-1 , 1).getDay();
      let firstDayOfNextMonth = new Date(parseInt(this.selectedYear),parseInt(this.selectedMonth), 1).getDay();
      this.firstDayOfMonth = this.transfromDayOfMonth(this.firstDayOfMonth);
      firstDayOfNextMonth = this.transfromDayOfMonth(firstDayOfNextMonth);

      // for za pushovanje dana trenutnog meseca u currentMonth uz komandu za brisanje arraya pri svakoj promeni i ponovno renderovanje
      this.currentMonth = [];
        for( let i = 1 ; i <= this.monthDays ; i++ ) {
          
          this.currentMonth.push({
            day: i,
            month: this.selectedMonth,
            year: this.selectedYear,
            isCurrentMonth: true,
          });
        }
        
          // for za stavljanje dana prethodnog meseca u array
      this.lastMonth = [];
          for( let i = lastMonthDays; i > lastMonthDays - this.firstDayOfMonth ; i--) {
            this.lastMonth.push({
              day: i,
              month: Number(this.selectedMonth) - 1 === 0? 12 : Number(this.selectedMonth) - 1,
              year: Number(this.selectedMonth) - 1 === 0? Number(this.selectedYear) - 1 : this.selectedYear,
              isCurrentMonth: false,
            });
          }
      this.lastMonth.reverse();

      this.nextMonth = [];
      const howManyDaysToFill =  7 - firstDayOfNextMonth;
      if (howManyDaysToFill < 7) {
        for( let i = 1; i <= howManyDaysToFill; i++) {
          this.nextMonth.push({
              day: i,
              month: Number(this.selectedMonth) + 1 > 12 ? 1 : Number(this.selectedMonth) + 1,
              year: Number(this.selectedMonth) + 1 > 12? Number(this.selectedYear) + 1 : this.selectedYear,
              isCurrentMonth: false,
            });
        }
      } 
    },
    setClickedDate (dateObj) {
      this.clickedDate.day = dateObj.getDate();
      this.clickedDate.month = dateObj.getMonth() + 1;
      this.clickedDate.year = dateObj.getFullYear();
    },
    setSelectedDate (dateObj) {
      this.selectedDay = dateObj.getDate();
      this.selectedMonth = dateObj.getMonth() + 1;
      this.selectedYear = dateObj.getFullYear();
    },
    emitDateAndSetClickedDate (i) {
      this.clickedDate.day = i.day;
      this.clickedDate.month = i.month;
      this.clickedDate.year = i.year;

      // const newDate = new Date(i.year, i.month-1, i.day);
      const newDate = `${i.month}/${i.day}/${i.year}`;
      const formatedDate = dayjs(newDate, 'M/D/YYYY').format('MM/DD/YYYY');
      this.$emit('dayChange' , formatedDate);
    },
    transfromDayOfMonth (dayNumber) {
      if (dayNumber === 0) return 6;
      else return --dayNumber;
    },
  },
};
</script>

<style scoped lang="scss">
#calendar {
  background: #FDFDFE;
  box-shadow: 0px 5px 9px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 10px 10px;
  padding: 1em;
  border-top: 4px solid $main-blue;
  color: #414141;
  width: 22em;
  max-width: 115%;
}
#month {
  display: flex;
  justify-content: center;
  align-items: center;
  .month-content {
    max-width: 100%;
  }
}
.prev .next {
  justify-content: center;
  align-items: center;
}

.selector {
  display: flex;
  justify-content: center;
  // gap: 0 1em;
  @include flex-gap(1em, 'row nowrap');
  
}
.picker {
  width: 120px;
  max-width: 100%;
  margin-bottom: 1em;
  outline: none;
  background: #FFF;
  border: 1px solid #DBDBDB;
  box-sizing: border-box;
  border-radius: 4px;
  padding: .3em;
  font-weight: 600;
  color: #3E3E3E;
  cursor: pointer;
}
#month {
  display: flex;
}
.prev .next {
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
}
.days-preview {
  display: flex;
  width: 100%;
  flex-direction: column;
  // gap: .4em 0;
}
.week-days {
  display: flex;
  width: 100%;
  font-size: 11px;
}
.current-month {
  display: flex;
  // gap: 1.6em 0;
  font-size: 11px;
  font-weight: 600;
}
.day-names {
  width: calc(20em / 7);
  text-align: center;
}
.days {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 7);
  height: calc(20em / 7);
  cursor: pointer;
}
.day-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 70%;
  &:hover {
    background: $button-background-disabled;
  }
}
.day-wrap.selected, .day-wrap.gray.selected {
  background: $main-blue;
  color: white;
}

.day-wrap.gray {
  color: #747474;
  &:hover {
    background: $button-background-disabled;
  }
}

.day-of-month {
  display: flex;
  flex-wrap: wrap;
  /* padding : 5px; */
  width: 100%;
}
.current-month {
  display: flex;
  flex-wrap: wrap;
}
</style>