<template lang="pug">
#app(
  :class="{ loading: isLoading }"
)
  transition(
    name="fade"
    mode="out-in"
    appear
  )
    loader-wrap(
      v-if="isLoading"
    )
  toast-wrapper
  router-view(
    v-slot="{ Component }"
  )
    transition(
      name="fade"
      mode="out-in"
      appear
    )
      component(
        :is="Component"
      )
</template>

<script>
import ToastWrapper from './components/toast/ToastWrapper.vue';
import LoaderWrap from './components/LoaderWrap.vue';

import { mapState, mapActions } from 'vuex';
export default {
  components: {
    ToastWrapper,
    LoaderWrap,
  },
  computed: {
    ...mapState('toastStore', ['toastArray']),
    ...mapState('loaderStore', ['isLoading']),
    ...mapState(['isLoggedIn']),
  },
  mounted () {
    this.checkSessionAndGetNotifications();
    setInterval(this.checkSessionAndGetNotifications, 1000*30);
  },
  methods: {
    ...mapActions('notificationsStore', ['getNotifications']),
    async checkSessionAndGetNotifications () {
      const apiObj = {
        api: this.globalMixin_api.checkSession,
      };
      
      const [data, error] = await this.globalMixin_apiCall(apiObj);

      if (!error) {
        this.setIsLoggedIn(true);
        this.setUserType(data.type);
        this.getNotifications();
      } else {
        this.setIsLoggedIn(false);
        this.setUserType(null);
        // this.$router.push('/');
      }
    },
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

// *::-webkit-scrollbar {
//   width: .5em;
// }
 
// *::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 6px $main-blue;
//   border-radius: 5px;
//   width: 50px;
// }
 
// *::-webkit-scrollbar-thumb {
//   background-color: $main-blue;
//   border-radius: 5px;
//   // outline: 1px solid rgb(250, 133, 0);
// }

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter';
  background-color: $background-color;
  //overflow rules could cause unwanted behaviour later
  overflow: hidden;
  overflow-y: auto;
  * {
    font-size: clamp(.85rem, .8vw, 1rem);
    -webkit-tap-highlight-color: transparent;
  }

  // TODO add h and p tags global margin 0
  h1, h2, h3, h4, h5, h6 {
    color: #313450;
  }
}

img {
  max-width: 100%;
}

#app {
  height: 100vh;
  width: 100vw;
}

.grecaptcha-badge { 
    visibility: hidden;
}

.loading {
  overflow-y: hidden;
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  body {
    * {
      font-size: 1rem;
    }
  }
}}
</style>
