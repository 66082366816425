<template>
  <div id="auth">
    <router-view></router-view>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#auth {
  min-height: 100vh;
}
</style>
