import axios from "axios";

let baseURL = process.env.VUE_APP_API_URL;
if (process.env.NODE_ENV !== "production") {
  baseURL = process.env.VUE_APP_API_URL_DEV;
}
const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(config => {
  handleRequestInterceptor(config);

  return config;
});

function handleRequestInterceptor (config) {
  try {
    const sid = localStorage.getItem('sid');
    if (config.params) {
      config.params.sid = sid;
    } else {
      config.params = {
        sid,
      };
    }
  } catch (err) {
    console.warn("error", err);
  }
}

export default instance;