<template lang="pug">
.step-wrap
  h2.title
    img.icon(
      :src="globalMixin_images.userAvatar"
      alt="user-avatar.svg"
    )
    span {{ title }}
  .form
    base-input(
      v-model.trim="localQuestions.firstName"
      :label="'First name'"
      :placeholder="'Your first name goes here'"
      :validatorTypes="['required']"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    base-input(
      v-model.trim="localQuestions.lastName"
      :label="'Last name'"
      :placeholder="'Your last name'"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    base-date-picker(
      v-model.trim="localQuestions.dateOfBirth"
      :label="'Date of birth'"
      :validatorTypes="['required', 'maxLength', 'regex']"
      :validatorData="{ regex: globalMixin_dateRegex }"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    base-input(
      v-model.trim="localQuestions.address1"
      :label="'Street address'"
      :placeholder="'Your address'"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    base-input(
      v-model.trim="localQuestions.address2"
      :label="'Address #2 (suite number, address number .etc)'"
      :placeholder="'Your address'"
      :validatorTypes="['maxLength']"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    base-input(
      v-model.trim="localQuestions.city"
      :label="'City'"
      :placeholder="'City'"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    base-input(
      v-model.trim="localQuestions.state"
      :label="'State'"
      :placeholder="'State'"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    base-radio-group(
      v-model="localQuestions.rents.value"
      :options="localQuestions.rents.options"
      name="rents-group"
      :label="localQuestions.rents.label"
      :validatorTypes="['required']"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    base-type-dropdown(
      v-model="localQuestions.zipCode.value"
      :options="localQuestions.zipCode.options"
      :label="'Zip code'"
      trackBy="zip_id"
      showBy="zip_code"
      :placeholder="'Your zip code'"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
</template>

<script>
import stepMixin from '@/js/stepMixin';
export default {
  mixins: [stepMixin],
};
</script>

<style scoped lang="scss">
.step-wrap {
  h2.title {
    display: flex;
    // gap: 0 10px;
    @include flex-gap(10px, 'row nowrap');
    margin: 0;
    margin-bottom: max(2em, 1.8vw);
    span {
      font-size: 1.4em;
    }
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    // gap: 1.7em 4em;
    @include flex-gap(1.7em, 'row wrap');
    > * {
      width: calc(50% - 2em) !important;
    }
  }
}

@media (max-width: 1367px) {
  .step-wrap {
    .form {
      > * {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 689px) {
  .step-wrap {
    .form {
      > * {
        width: calc(100% - 2em) !important;
      }
    }
  }
}
</style>