<template lang="pug">
.notification(
  :class="{ 'new' : !notification.ntf_seen }"
)
  .heading
    .name {{ notification.ntp_name }}
    .rightHeader
      .time {{ formatDate(notification.ntf_date) }}
      .close(
        @click="$emit('del-notif')"
      )
        img(
          :src="globalMixin_images.close"
        )
  p.text(
    ref="text"
    v-html="$t(`notifications.code${notification.ntp_id}`, { firstName: notification.usr_firstname })"
  )
</template>

<script>
import dayjs from 'dayjs';
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
    isLast: {
      type: Boolean,
    },
  },
  emits: [
    'del-notif',
  ],
  methods: {
    formatDate (date) {
      return dayjs(dayjs(date).utc(true), 'YYYY-MM-DD HH:mm:ss').tz(dayjs.tz.guess()).format('MM/DD HH:mm');
      // return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  padding: 1em;
  &.new {
    background: #F0FAF3;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .name {
      color: #434961;
      font-weight: 600;
    }
    .rightHeader {
      display: flex;
      align-items: center;
      .time {
        font-style: italic;
        color: #999BA3;
        margin-right: 10px;
      }
      .close {
        height: 15px;
        &:hover{
          cursor: pointer;
        }
        img {
          height: 15px;
          filter: contrast(0%);
        }
      }
    }

  }
  .text {
    color: #6C7490;
    transition: all .3s ease;
    &.ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      -webkit-box-orient: vertical;
      transition: all .3s ease;
    }
  }
  .read-more {
    display: flex;
    @include flex-gap(.5em, 'row nowrap');
    cursor: pointer;
    width: max-content;
    &.less {
      img {
        transform: rotate(180deg);
      }
    }
    span {
      margin-top: -2px;
      font-size: .8em;
      color: $main-blue;
    }
    img {
      width: 10px;
      height: 10px;
    }
    &.has-border {
      margin: 0;
      padding: 1em 0 2em 0;
      border-bottom: 1px solid #EDEDED;
    }
  }
}
</style>