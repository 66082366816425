<template lang="pug">
.landing-login
  .form(
    v-if="showForgorPassword"
  )
    p {{ $t('form.forgotPassword') }}
    span {{ $t('form.forgotPasswordInfo') }}
    .inputs-wrap
      base-input(
        v-model.trim="email.value"
        type="email"
        :label="$t('form.email')"
        :placeholder="$t('form.email')"
        :validatorTypes="['required', 'maxLength', 'regex']"
        :validatorData="{ regex: globalMixin_emailRegex }"
        :customErrorMsg="{ regex: $t('registration.validEmail') }"
        :isFormSubmitted="formMixin_checkErrors"
        :backendErrors="email.errors"
        @has-error="formMixin_errorHandler"
      )
      .button-wrap
        base-button(
          :label="$t('buttons.resetPassword')"
          :left-icon="globalMixin_images.popupText"
          :loading="loading"
          :disabled="loading"
          aqua
          bindEnter
          @click="formMixin_onFormSubmit(onReset)"
        )
  .form(
    v-else
  )
    p {{ $t('buttons.userLogin') }}
    .inputs-wrap
      base-input(
        v-model.trim="email.value"
        :label="$t('form.email')"
        :placeholder="$t('form.email')"
        :validatorTypes="['required', 'maxLength', 'regex']"
        :validatorData="{ regex: globalMixin_emailRegex }"
        :customErrorMsg="{ regex: $t('registration.validEmail') }"
        :isFormSubmitted="formMixin_checkErrors"
        :backendErrors="email.errors"
        @has-error="formMixin_errorHandler"
      )
      base-input(
        v-model="password.value"
        type="password"
        :label="$t('form.password')"
        :placeholder="$t('form.password')"
        :isFormSubmitted="formMixin_checkErrors"
        :backendErrors="password.errors"
        @has-error="formMixin_errorHandler"
      )
      .forgot-pw
        p.forgot-pw-text(
          @click="clearBeforeSwitch"
        )
          | {{ $t('form.forgotPassword') }}
    .button-wrap
      base-button(
        :label="$t('buttons.userLogin')"
        :left-icon="globalMixin_images.popupText"
        aqua
        bindEnter
        :disabled="loading"
        :loading="loading"
        @click="formMixin_onFormSubmit(onLogin)"
      )
  .img-wrap
    img(
      :src="globalMixin_images.landingAgentRegistrationImage"
    )
</template>

<script>
import formMixin from '@/js/formMixin.js';
import { mapActions } from 'vuex';
export default {
  mixins: [
    formMixin,
  ],
  data () {
    return {
      email: {
        value: '',
        errors: [],
      },
      password: {
        value: '',
        errors: [],
      },
      loading: false,
      showForgorPassword: false,
    };
  },
  methods: {
    ...mapActions('notificationsStore', ['getNotifications']),
    async onLogin () {
      this.clearErrors();
      await this.login();
    },
    async onReset () {
      this.clearErrors();
      await this.resetpw();
    },
    clearErrors () {
      this.email.errors = [];
      this.password.errors = [];
    },
    clearBeforeSwitch () {
      this.showForgorPassword = true;
      this.clearErrors();
    },
    async login () {
      const token = await this.globalMixin_recaptcha();
      
      const apiObj = {
        api: this.globalMixin_api.login,
        params: {
          email: this.email.value,
          password: this.password.value,
          type: 2,
          token,
        },
      };
      
      this.loading = true;
      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.setIsLoggedIn(true);
        this.setUserType(data.type);
        localStorage.setItem('sid', data.sid);
        this.getNotifications();
        this.globalMixin_profileRedirect();
      } else {
        this.email.errors.push({
          message: this.$t('registration.yourEmailOrPasswordInvalid'),
        });
        this.password.errors.push({
          message: this.$t('registration.yourEmailOrPasswordInvalid'),
        });
      }
      this.loading = false;
    },
    async resetpw () {
      this.loading = true;
      const token = await this.globalMixin_recaptcha();
      
      const apiObj = {
        api: this.globalMixin_api.getResetPasswordMail,
        params: {
          email: this.email.value,
          token,
        },
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);

      if(error){
        this.email.errors.push({
          message: this.$t('errors.doesnt-exist'),
        });
      } else {
        this.appendToast({
          message: this.$t('registration.resetPasswordSuccess'),
          type: 'success',
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.landing-login {
  display: flex;
  justify-content: space-between;
  background: #EFEFF8;
  border-radius: 10px;
  overflow: hidden;
  max-height: 95vh;
  * {
    font-family: 'Inter';
    // font-weight: 400;
  }
  .form {
    display: flex;
    flex-direction: column;
    // gap: 3em;
    padding: 3em;
    padding-top: 8vw;
    flex-grow: 1;
    width: 40em;
    > p {
      margin: 0;
      color: #172856;
      font-family: 'Inter';
      font-size: clamp(2.5em, 3vw, 3.3em);
      line-height: 1.1;
    }
    > span {
      color: #172856;
    }
    .inputs-wrap {
      display: flex;
      flex-direction: column;
      margin: 3em 0;
      // gap: 2em;
      .base-input {
        margin-bottom: 2em;
      }
      .forgot-pw{
        display: flex;
        justify-content: baseline;
        margin-top: -1.5em;
        margin-bottom: -3em;
        .forgot-pw-text{
          font-size: .9em;
          cursor: pointer;
          &:hover{
            color: $main-blue;
          }
        }
      }
    }
  }
  .img-wrap {
    // background: url("../../assets/images/landing-agent-registration-image.png") no-repeat center center;
    // flex-basis: 500px;
    // width: max(100%, 700px);
    // width: 600px;
    // background-size: cover;
    img {
      // height: 100%;
      min-height: 102%;
    }
  }
}

@media (max-width: 950px) {
  .landing-login {
    .img-wrap {
        background: url("../../assets/images/agent-registration-illustration.png") no-repeat center center;
        background-size: cover;
        width: 600px;
      img {
        display: none;
      }
    }
  }
}

@media (max-width: 900px) {
  .landing-login {
    .form {
      width: 55%;
    }
  }
}

@media (max-width: 550px) {
  .landing-login {
    .form {
      width: 75%;
    }
  }
}

@media (max-width: 450px) {
  .landing-login {
    .img-wrap {
      display: none;
    }
    .form {
      width: 100%;
    }
  }
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .landing-login {
    .form {
      > p {
        font-size: 3vw;
      }
    }
  }
}}
</style>