<template lang="pug">
.auto-calculation
  h2 {{ $t('profile.autoCoverage') }}
  .form
    .risk
      base-input(
        v-model="getTotalRequired"
        :label="$t('profile.autoTotalAssets')"
        :symbol="'$'"
        :position="0"
        disabled
      )
      base-input(
        v-if="atRisk > 0"
        v-model="getAtRisk"
        :symbol="'$'"
        :position="0"
        :label="$t('profile.autoTotalRisk')"
        disabled
      )
      p(v-html="recommended")
    component(
      v-if="localQuestions.length > 0 && isAnsArr" 
      v-model="localQuestions[0].answer"
      :is="getQuestionType(localQuestions[0])"
      :label="localQuestions[0].que_content"
      :placeholder="localQuestions[0].que_content"
      :disabled="localQuestions[0].que_type === 0 || isInLead"
      :symbol="localQuestions[0].que_symbol"
      :position="localQuestions[0].que_symbol_pos"
      :name="`dynamic-input-coverage${localQuestions[0].que_id}`"
      :validatorTypes="[]"
      :options="localQuestions[0].answers !== null? localQuestions[0].answers : undefined"
      :labelKey="'ans_content'"
      :valueKey="'ans_id'"
      :isFormSubmitted="checkErrors"
      :questionmarkHoverText="localQuestions[0].que_text"
      @has-error="errorHandler"
    )
    component(
      v-if="showQue" 
      v-model="localQuestions[1].answer"
      :is="getQuestionType(localQuestions[1])"
      :label="localQuestions[1].que_content"
      :disabled="localQuestions[1].que_type === 0 || isInLead"
      :placeholder="localQuestions[1].que_content"
      :symbol="localQuestions[1].que_symbol"
      :position="localQuestions[1].que_symbol_pos"
      :name="`dynamic-input-coverage${localQuestions[1].que_id}`"
      :validatorTypes="[]"
      :options="localQuestions[1].answers !== null? localQuestions[1].answers : undefined"
      :labelKey="'ans_content'"
      :valueKey="'ans_id'"
      :isFormSubmitted="checkErrors"
      :questionmarkHoverText="localQuestions[1].que_text"
      @has-error="errorHandler"
    )
</template>

<script>
import stepMixin from "@/js/stepMixin";
export default {
  mixins: [
    stepMixin,
  ],
  props: {
    totalAmount: {
      required: false,
    },
    otherData: {
      type: Array,
    },
    questions: {
      type: Array,
    },
    atRisk: {
      type: Number,
      default: 0,
    },
    totalRequired: {
      type: Number,
      default: 0,
    },
    umbrella: {
      type: Boolean,
      default: false,
    },
    isInLead: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: [
    'send-questions'
  ],
  data () {
    return {
      showQue: false,
    };
  },
  computed: {
    recommended () {
      let message = '';
      if (this.umbrella) message = this.$t('profile.umbrellaText');
      else if (this.atRisk > 0) message = this.$t('profile.recommendedCoverage', { coverage: this.totalAmount });
      else message = this.$t('profile.greatNews');
      return message;
    },
    isAnsArr () {
      return Array.isArray(this.localQuestions[0].answer);
    },
    getTotalRequired (){
      return this.totalRequired;
    },  
    getAtRisk (){
      return this.atRisk;
    },
  },
  watch: {
    questions: {
      deep: true,
      handler () {
        this.formatLocalquestions();
      },
    },
    localQuestions: {
      deep: true,
      handler () {
        this.questionsHandler();
      },
    },
  },
  mounted () {
    this.formatLocalquestions();
    this.questionsHandler();
  },
  methods: {
    formatLocalquestions () {
      const questions = JSON.parse(JSON.stringify(this.questions));
      this.localQuestions = questions.map(que => {
        if (que.answer && typeof que.answer === 'object' && Object.keys(que.answer).length) {
          if (que.que_type === 3) que.answer = [Number(que.answer.ans_id)];
          else que.answer = que.answer.ans_id;
        } else {
          if (que.que_type === 3) que.answer = [];
          else que.answer = '';
        }
        if (this.localQuestions[0].answer.length === 0) this.localQuestions[1].answer = ''; 

        return que;
      });
    },
    questionsHandler () {
      this.showQue = !!this.localQuestions[0].answer.length;

      const params = [];
      for (let j = 0; j < this.localQuestions.length; j++) {
        const queObj = this.localQuestions[j];
        params.push({
          que_id: queObj.que_id,
          // if question is free input or date or number, fill ans_content property, otherwise fill ans_id
          // array includes non free text inputs
          ans_id: [0, 4, 5, 6].includes(queObj.que_type)? null : queObj.answer,
          ans_content: ![0, 4, 5, 6].includes(queObj.que_type)? null : queObj.answer,
        });
      }

      let ansId;
      if (this.showQue && this.localQuestions[1].answer) ansId = this.localQuestions[1].answer;
      else {
        const queObj = this.localQuestions[1].answers.find(ansObj => ansObj.ans_content === this.totalAmount);
        ansId = queObj.ans_id;
      }

      params[1] = {
        que_id: this.localQuestions[1].que_id,
        ans_id: ansId,
      };

      this.$emit('send-questions', params);
    },
  },
};
</script>

<style lang="scss" scoped>
.auto-calculation {
  border-top: 2px solid #D7DEE8;
  padding: 1em 0;
  margin-top: 1em;
  h2 {
    margin-top: 0;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    // gap: 2em 4em;
    @include flex-gap(3em, 'column wrap');
    .risk {
      display: flex;
      flex-direction: column;
      // gap: 1em;
      // @include flex-gap(1em, 'column nowrap');
      p {
        font-family: 'Open Sans';
        font-weight: 600;
        color: #656B7E;
      }
    }
  }
}

@media (min-width: 769px) {
  .auto-calculation {
    .form {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        width: calc(33% - 2.54em) !important;
      }
    }
  }
}
</style>
<style lang="scss">
.auto-calculation {
  .form {
    .base-label-el {
      width: 100% !important;
    }
  }
}
</style>