<template lang="pug">
.step-wrap
  h2.title
    img.icon(
      :src="globalMixin_images.userAvatar"
      alt="user-avatar.svg"
    )
    span {{ title }}
  .form
    base-select-image(
      v-model="localQuestions.currentInsuranceCompany.value"
      :options="localQuestions.currentInsuranceCompany.options"
      :label="'Current carrier'"
      :placeholder="'Current carrier'"
      :validatorTypes="['required']"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    base-checkbox.checkbox(
      v-model="localQuestions.emailNotification"
      :label="'Receive e-mail notifications'"
      :validatorTypes="['required']"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    base-input(
      v-model.trim="localQuestions.password"
      type="password"
      :label="'Password'"
      :placeholder="'Password'"
      :validatorTypes="['required', 'password']"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    base-input(
      v-model.trim="localQuestions.confirmPassword"
      type="password"
      :label="'Confirm password'"
      :placeholder="'Confirm password'"
      :validatorTypes="['required', 'confirmPassword']"
      :validatorData="{ confirmPassword: localQuestions.password }"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    .card
      h3
        | {{ $t('registration.dontWorry') }}
      p
        | {{ $t('registration.infoNotice') }}
</template>

<script>
import stepMixin from '@/js/stepMixin';
export default {
  mixins: [stepMixin],
};
</script>


<style scoped lang="scss">
.step-wrap {
  h2.title {
    display: flex;
    @include flex-gap(10px, 'row nowrap');
    margin: 0;
    margin-bottom: max(2em, 1.8vw);
    span {
      font-size: 1.4em;
    }
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    // gap: 1.7em 4em;
    @include flex-gap(1.7em, 'row wrap');
    .card {
      width: 100% !important;
      display: flex;
      flex-direction: column;
      background: #D3E5FD4A;
      border-radius: 10px;
      padding: 1.5em 2em;
      gap: 1em;
      h3 {
        margin: 0;
        color: $main-blue;
      }
      p {
        font-family: 'Spartan';
        font-weight: 400;
        margin: 0;
        color: #5B6074;
      }
    }
    .checkbox {
      margin-top: 1em;
    }
    > * {
      width: calc(50% - 2em) !important;
    }
  }
}

@media (max-width: 1367px) {
  .step-wrap {
    .form {
      > * {
        width: 100% !important;
      }
    }
  }
}

@media (max-width: 689px) {
  .step-wrap {
    .form {
      > * {
        width: calc(100% - 2em) !important;
      }
    }
  }
}
</style>