<template>
  <div class="questionmark-wrapper">
      <transition name="fade">
        <div class="text-container-abs" v-if="showText" :style="position" ref="tooltip">
          {{questionmarkHoverText}}
          <div class="triangle" ref="triangle" ></div>
        </div>
      </transition>
      <span class="questionmark" @mouseenter="showTooltip" @mouseleave="showText=false" ref="info">
        <img
          :src="globalMixin_images.moreInfo"
          alt="more-info"
        /> 
      </span>
  </div>
</template>

<script>
export default {
    props: {
        questionmarkHoverText: {
            type: String,
        },
    },
    mounted () {
      this.position = this.setPosition();
      window.addEventListener('resize', () => {
        if(this.showText){
          this.position = this.setPosition();
        }
      });
      window.addEventListener('scroll', () => {
        if (this.showText) {
          this.position = this.setPosition();
        }
      });
    },
    data () {
        return {
            showText: false,
            coords: null,
            position: {},
            trianglePos: {},
        };
    },
    methods: {
      showTooltip () {
        this.position = this.setPosition();
        this.showText = true;
      },
      setPosition () {
        const style = {
          left: `${this.$refs.info.getBoundingClientRect().x}px`,
          top: `${this.$refs.info.getBoundingClientRect().y + 25}px`,
        };

        const maxWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        if(parseInt(style.left) + 200 > maxWidth){
            style.left = maxWidth - 180+'px';
        }

        return style;
      },
    },
    beforeUnmount () {
      window.removeEventListener('resize', () => {
        this.position = this.setPosition();
      });
      window.removeEventListener('scroll', () => {
        this.position = this.setPosition();
      });
    },
  
};
</script>

<style lang='scss' scoped>
.questionmark-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 17px;
    min-width: 17px;
    border-radius: 50%;
    box-shadow: 0px 2px 4px rgba(29, 99, 186, 0.11);
    .text-container-abs {
        position: fixed;
        // position: absolute;
        // top: calc(100% + 10px);
        // left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5em 1em;
        color: white;
        background: $main-blue;
        width: max-content;
        max-width: 25em;
        border-radius: 3px;
        z-index: 5;
        transform: translateX(-50%);
        .triangle {
          $size: 10px;
          width: $size;
          height: $size;
          position: absolute;
          // top: calc(50% - (#{$size} / 2));
          // left: calc(0% - (#{$size} / 2));
          // top: calc(0 - (#{$size} / 2));
          top: -3px;
          right: calc(50% - #{$size} - 2.5px);
          transform: rotate(45deg);
          background: $main-blue;
        }
    }
    .questionmark {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
// fold support
@media screen and (max-width: 280px) {
  .questionmark-wrapper{
    .text-container-abs{
      width: 260px;
    }
  }
}
</style>