import api from '@/api';

export const notificationsStore = {
  namespaced: true,
  state: {
    notifications: [],
  },
  mutations: {
    GET_NOTIFICATIONS (state, payload) {
      state.notifications = payload;
    },
  },
  actions: {
    async getNotifications ({ commit }) {
      try {
        const res = await api.getNotifications();
        commit('GET_NOTIFICATIONS', res.data.data);
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
