<template lang="pug">
.lds-ring(
  :style="calcSize"
)
  div(
    :style="calcInnerSize"
  )
  div(
    :style="calcInnerSize"
  )
  div(
    :style="calcInnerSize"
  )
  div(
    :style="calcInnerSize"
  )
</template>

<script>
export default {
  // the size values are calculated in pixels for the size prop
  props: {
    size: Number,
    color: String,
  },
  data () {
    return {
      calcSize: {},
      calcInnerSize: {},
    };
  },
  mounted () {
    this.calcSize = this.getSize();
    this.calcInnerSize = this.getInnerSize();
  },
  methods: {
    getSize () {
      const style = {
        height: "80px",
        width: "80px",
      };

      if(this.size){
        style.height = 80 * this.size/80 + 'px';
        style.width = 80 * this.size/80 + 'px';
        return style;
      }
      
      return style;
    },
    getInnerSize () {
      const style = {
        width: "64px",
        height: "64px",
        margin: "8px",
        borderWidth: "8px",
      };

      if (this.color) {
        style.borderTopColor = this.color;
      }

      if (this.size) {
        style.width = 64 * this.size/80 + 'px';
        style.height = 64 * this.size/80 + 'px';
        style.margin = 8 * this.size/80 + 'px';
        style.borderWidth = 8 * this.size/80 + 'px';

        return style;
      }

      return style;
    },
  },
};
</script>

<style lang="scss" scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  /* width: 80px;
  height: 80px; */
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  /* width: 64px;
  height: 64px;
  margin: 8px; */
  border: solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $main-blue transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>