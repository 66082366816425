<template lang="pug">
.step-wrap
  h2.title
    span {{ title }}
  .form
    //- which base component to load
    template(
      v-for="question in localQuestions"
      :key="question.que_id"
    )
      component(
        v-model="question.answer"
        :is="getQuestionType(question)"
        :disabled="!!disabled"
        :label="question.que_content"
        :placeholder="question.que_placeholder"
        :symbol="question.que_symbol"
        :position="question.que_symbol_pos"
        :name="`dynamic-input${question.que_id}`"
        :options="question.answers !== null ? question.answers : undefined"
        :validatorTypes="getValidators(question)"
        :validatorData="getValidatorData(question)"
        :labelKey="'ans_content'"
        :valueKey="'ans_id'"
        :isFormSubmitted="checkErrors"
        :questionmarkHoverText="question.que_text"
        :isNoneCheckbox="question.que_type === 3 && question.que_placeholder !== null"
        @has-error="errorHandler"
      )
</template>

<script>
import stepMixin from '@/js/stepMixin';
export default {
  mixins: [stepMixin],
  props: {
    hiddenQuestions: {
      type: Set,
    },
    disabled: {
      type: Boolean,
    },
  },
  watch: {
    questions: {
      deep: true,
      handler () {
        this.localQuestions = JSON.parse(JSON.stringify(this.questions));
      },
    },
  },
  methods: {
    getValidators (que) {
      // special currency check
      if (que.que_type === 5 && que.que_symbol === '$') return;

      switch (que.que_type) {
        case 5: return ['number', 'required', 'maxLength'];
        case 3: return [];
        case 4: return ['required', 'maxLength', 'regex'];
        case 6: return ['required', 'maxLength', 'number', 'year'];
        default: return undefined;
      }
    },
    getValidatorData (que) {
      switch (que.que_type) {
        case 4: return { regex: this.globalMixin_dateRegex };
        default: return undefined;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.step-wrap {
  width: 100%;
  h2.title {
    display: flex;
    margin: 0;
    margin-bottom: .7em;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    // gap: 2em 4em;
    @include flex-gap(2em, 'column wrap');
    max-width: 100%;
  }
}

@media (min-width: 769px) {
  .step-wrap {
    .form {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      > * {
        width: calc(33% - 2.54em) !important;
      }
    }
  }
}
</style>