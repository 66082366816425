// For more info check the LandingAgentRegistration.vue file for a demonstration
// of how the mixin works with conjuntion with base-components

export default {
  data () {
    return {
      formMixin_errors: [],
      formMixin_checkErrors: false,
    };
  },
  computed: {
    formMixin_hasError () {
      return this.formMixin_errors.some(errorObject => errorObject.errors.length > 0);
    },
  },
  methods: {
    // On call, performs error checks
    async formMixin_initErrorCheck () {
      this.formMixin_checkErrors = true;
      await this.$nextTick();
      this.formMixin_checkErrors = false;
    },

    // Handles error objects returned from base components (@has-error="formMixin_errorHandler")
    formMixin_errorHandler (errorObject) {
      const objToReplace = this.formMixin_errors.find(errObj => errObj.id === errorObject.id);
      if (objToReplace) {
        const index = this.formMixin_errors.findIndex(errObj => errObj.id === errorObject.id);
        this.formMixin_errors.splice(index, 1, errorObject);
      } else {
        this.formMixin_errors.push(errorObject);
      }
    },

    // Wrapper function that calls the callback after performing error checks
    // Example of using it:
    // button(
    //   @click="formMixin_onFormSubmit(sendDataToServer)"
    // )
    // Where sendDataToServer is a method from local vue component
    async formMixin_onFormSubmit (callback) {
      await this.formMixin_initErrorCheck();
      if (!this.formMixin_hasError) {
        await callback();
      }
    },

    // Helper function to be used only for forgot password to clear all errors
    // AKA hakic
    async formMixin_clearErrors () {
      this.formMixin_errors.forEach(errObj => errObj.errors = []);
      this.formMixin_initErrorCheck();
    },
  },
};