<template>
  <div class="calendar-wrap">
    <label 
      class="base-label-el"
      v-if="label"
      :for="globalMixin_uuid"
    >
      {{ label }}
      <questionMark
        v-if='questionmarkHoverText'
        :questionmarkHoverText='questionmarkHoverText'
      />
    </label>
    <input 
      class="base-input-el"
      v-bind="$attrs"
      v-mask="DATE_MASK"
      :disabled="disabled"
      :class="{ 'error' : hasError }"
      :id="globalMixin_uuid"
      :ref="'ref' + globalMixin_uuid"
      :value="modelValue"
      :placeholder="placeholder"
      @input="emitFormattedDate($event.target.value)"
      @blur="onBlurHandler($event.target.value)"
    >
    <p 
      class="error-msg"
      v-if="localErrors.length > 0"
    >
      {{ localErrors[0].message }}
    </p>
    <img 
      class="calendar-icon"
      :src="globalMixin_images.calendar"
      alt="calendar.svg"
      @click="toggleCalendar"
    >
    <transition name="fade">
      <Calendar
        v-if="showCalendar"
        class="calendar-wrap"
        :class="{ 'has-label' : label }"
        :theDate="modelValue"
        @dayChange="displayDate"
      />
    </transition>
  </div>
</template>

<script>
import Calendar from '../Calendar.vue';
import baseValidatorMixin from '../../js/baseValidatorMixin';
import questionMark from '../questionMark.vue';
export default {
  mixins: [
    baseValidatorMixin
  ],
  components: {
    Calendar,
    questionMark,
  },
  props: {
    questionmarkHoverText: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "MM/DD/YYYY",
    },
    label: String,
    format : String,
    modelValue: String,
    disabled: Boolean,
  },
  emits: [
    'update:modelValue'
  ],
  data () {
    return {
      DATE_MASK: '##/##/####',
      showCalendar: false,
    };
  },
  methods: {
    async displayDate (i) {
      this.showCalendar = false;
      this.emitFormattedDate(i);
      await this.$nextTick();
      this.onApplyValidators();
    },
    toggleCalendar () {
      if (!this.disabled) this.showCalendar = !this.showCalendar;
    },
    async onBlurHandler (date) {
      this.emitFormattedDate(date);
      await this.$nextTick();
      this.onApplyValidators();
    },
    emitFormattedDate (date) {
      this.$emit('update:modelValue', date);
    },
  },
};
</script>

<style scoped lang="scss">
* {
  font-family: 'Open Sans';
}
.calendar-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  width: $input-width;
  @include flex-gap(.5em, 'column nowrap');
  .base-label-el {
    display: flex;
    align-items: center;
    @include flex-gap(.1em, 'row nowrap');
    font-size: .9em;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #656B7E;
  }
  .base-input-el {
    height: $input-height;
    padding: 0 3em 0 1em;
    border: 2px solid #DEDEE9;
    border-radius: .5em;
    outline: none;
    font-size: 1em;
    font-weight: 600;
    color: #434961;
    &::placeholder {
      font-weight: 400;
      color: #9CA0AF;
    }
    &.error {
      border-color: $input-error-border-color;
      &:active, &:focus {
        border: 2px solid $input-error-border-color;
      }
    }
    &:active, &:focus {
      border: 2px solid $main-blue;
    }
    &:disabled {
      border: none;
      background: #EFF0F1;
    }
  }
  .calendar-icon {
    position: absolute;
    bottom: calc(#{$input-height} / 2 - 8px);
    right: 20px;
    cursor: pointer;
  }
  .calendar-wrap {
    position: absolute;
    top: calc(#{$input-height} - .8em);
    right: 0;
    z-index: 10;
    &.has-label {
      top: calc(#{$input-height} + 1.1em);
    }
  }
}
</style>