<template lang="pug">
.better-way-wrap
  .content
    h1(
      v-html="$t('landing.betterWay')"
    )
    p  {{ $t('landing.optionsAgent') }}
    .buttons(
      v-if="!isLoggedIn"
    )
      base-button(
        :label="$t('buttons.startQuoting')"
        :left-icon="globalMixin_images.popupText"
        aqua
        @click="onAgentRegister"
      )
      //- base-button.agent-login(
      //-   label="Not an agent?"
      //-   :left-icon="globalMixin_images.userAvatar"
      //-   noFillColor
      //-   iconWhite
      //-   @click="$router.push({ name: 'Landing' })"
      //- )
  .img-wrap
    img(
      :src="globalMixin_images.landingMan"
      alt="landing-man.svg"
    )
</template>

<script>
import { mapState } from 'vuex';
export default {
  emits: [
    'open-modal',
  ],
  computed: {
    ...mapState(['isLoggedIn']),
  },
  methods: {
    onAgentRegister () {
      this.$emit('open-modal', 'register');
    },
  },
};
</script>

<style lang="scss" scoped>
.better-way-wrap {
  display: flex;
  justify-content: space-between;
  // gap: 3em;
  margin-top: 5em;
  @include flex-gap(3em, 'row nowrap');
  > * {
    flex-basis: 100%;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // gap: 3.5em;
    @include flex-gap(7em, 'column nowrap');
    h1 {
      font-size: 3.7em;
      margin: 0;
      color: #FFF;
      // uncomment for gap
      margin-bottom: .7em;
      font-family: 'Poppins', sans-serif; //Open Sans
    }
    p {
      margin: 1em 0 2em 0;
      color: #FFF;
      font-weight: 600;
      font-family: 'Poppins', sans-serif; //Open Sans
      word-spacing: .2em;
      line-height: 2em;
      font-size: 1.3em;
    }
    .buttons {
      display: flex;
      // gap: 1.5em;
      @include flex-gap(1.5em, 'row wrap');
      margin-top: 1em;
      button {
        padding: .9em 1.4em;
        font-size: 1.2em;
      }
      .agent-login {
        border: 2px solid rgba(255, 255, 255, 0.35);
        color: #FFF;
      }
    }
  }
  .img-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5em !important;
    img {
      width: 100%;
    }
  }
}

@media (max-width: 1070px) {
  .better-way-wrap {
    flex-direction: column;
    .content {
      .buttons {
        button {
          padding: .5em;
        }
      }
    }
    .img-wrap {
      img {
        max-width: 550px;
      }
    }
  }
}

@media (max-width: 769px) {
  .better-way-wrap {
    .content {
      h1 {
        text-align: center;
        font-size: 3em;
      }
      p {
        text-align: center;
      }
      .buttons {
        // justify-content: center;
        button {
          padding: 1.2em;
        }
      }
    }
    .img-wrap {
      margin-top: 2em;
    }
  }
}

@media (max-width: 500px) {
  .better-way-wrap {
    .content {
      // gap: 2em;
      @include flex-gap(8em, 'column nowrap');
      margin-bottom: 0em;
      p {
        line-height: 1.5em;
        font-size: 1.1em;
      }
      h1 {
        // uncomment for gap
        margin-top: 1em;
        font-size: 2.4em;
      }
    }
    .img-wrap {
      img {
        max-width: 550px;
      }
    }
  }
}

@media screen and (max-width: 410px){
  .better-way-wrap{
    .content{
      .buttons{
        flex-direction: column;
        @include flex-gap-unset('row nowrap');
        @include flex-gap(2em, 'column nowrap');
        // uncomment for gap
        margin-top: 1em;
      }
    }
  }
}
</style>