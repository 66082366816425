<template>
    <div id="home-page">
        <modal v-if="modalComponent" @close-modal="modalComponent = null">
            <component :is="modalComponent" :insuranceTypes="insuranceTypes" @close-modal="modalComponent = null"></component>
        </modal>
        <div class="nav-better-wrap" id="observable">
            <div class="nav-replacer" v-if="shouldNavPop && !isMobileMixin_isMobile"></div>
            <div class="filler" v-show="shouldNavPop && isMobileMixin_isMobile"></div>
            <agent-landing-navigation :shouldNavPop="shouldNavPop" @open-modal="openModal" @logout="globalMixin_onLogout"></agent-landing-navigation>
            <agent-landing-block-better-way @open-modal="openModal"></agent-landing-block-better-way>
        </div>
        <div class="how-it-works" :style="{'background-image': 'url(' + globalMixin_images.gornjiDesni + ')'}" id="how-it-works">
            <agent-landing-block-how-it-works></agent-landing-block-how-it-works>
        </div>
        <!-- <div class="how-we-make-money">
            <landing-block-how-we-make-money></landing-block-how-we-make-money>
        </div> -->
        <div class="how-fairwey-diff" id="how-we-diff">
            <img class="left-blob" :src="globalMixin_images.leftBlob" alt="">
            <img class="graph" :src="globalMixin_images.graph" alt="">
            <img class="polygon" :src="globalMixin_images.polygon" alt="">

            <landing-block-how-is-fairwey-different></landing-block-how-is-fairwey-different>
        </div>
        <div class="footer" id="footer">
            <register-footer></register-footer>
        </div>
    </div>
</template>

<script>
import AgentLandingNavigation from '@/components/agent-landing-page/AgentLandingNavigation';
import AgentLandingBlockBetterWay from '@/components/agent-landing-page/AgentLandingBlockBetterWay';
import AgentLandingBlockHowItWorks from '@/components/agent-landing-page/AgentLandingBlockHowItWorks';
import LandingBlockHowWeMakeMoney from '@/components/landing-page/LandingBlockHowWeMakeMoney';
import LandingBlockHowIsFairweyDifferent from '@/components/landing-page/LandingBlockHowIsFairweyDifferent';
import LandingUserRegistration from '@/components/landing-page/LandingUserRegistration';
import AgentLandingRegistration from '@/components/agent-landing-page/AgentLandingRegistration';
import AgentLandingLogin from '@/components/agent-landing-page/AgentLandingLogin';
import RegisterFooter from '@/components/register-page/RegisterFooter';
import Modal from '@/components//Modal';

import { mapActions } from 'vuex';

export default {
  name: "AgentLandingPage",
  components: {
    AgentLandingNavigation,
    AgentLandingBlockBetterWay,
    AgentLandingBlockHowItWorks,
    LandingBlockHowWeMakeMoney,
    LandingBlockHowIsFairweyDifferent,
    LandingUserRegistration,
    AgentLandingRegistration,
    AgentLandingLogin,
    RegisterFooter,
    Modal,
  },
  data () {
    return {
      insuranceTypes: [],
      modalComponent: null,
      shouldNavPop: false,
    };
  },
  created () {
    this.getInsuranceTypes();
  },
  mounted () {
    this.observeInit();
    this.listenForOpenModal();
  },
  methods: {
    ...mapActions(['setIsLoggedIn']),
    async getInsuranceTypes () {
      const apiObj = {
        api: this.globalMixin_api.getInsuranceTypes,
      };
      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.insuranceTypes = data.data;
      }  
    },
    listenForOpenModal () {
      if (this.$route.params.openModal) this.openModal(this.$route.params.openModal);
    },
    openModal (type) {
      switch (type) {
        case 'register': this.modalComponent = 'AgentLandingRegistration'; break;
        case 'login': this.modalComponent = 'AgentLandingLogin'; break;
      }
    },
    observeInit () {
      const options = {
        root: null,
        rootMargin: '0px 0px -100%',
        threshold: 0,
      };

      const target = document.getElementById('observable');

      const callback = entries => {
        for (let i = 0; i < entries.length; i++) {
          this.shouldNavPop = !entries[i].isIntersecting;
        }
      };

      const observer = new IntersectionObserver(callback, options);
      observer.observe(target);
    },
  },
};
</script>
<style lang="scss" scoped>
#home-page {
  position:relative;
  $padding-inline: min(12vw, 12em);
  background-color: #FFF;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  #how-we-diff{
    scroll-margin-top: 120px;
  }
  #how-it-works{
    scroll-margin-top: -40px;
  }
  > * {
    width: min(3840px, 100%);
    padding-left: $padding-inline;
    padding-right: $padding-inline;
  }
  .nav-better-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    // gap: 5em;
    // @include flex-gap(5em, 'column nowrap');
    padding-top: min(1.5vw, 1.5em);
    padding-bottom: min(5vw, 5em);
    background: url('../assets/images/greatF.png') no-repeat, linear-gradient(260.62deg, #5942E6 -0.28%, #3664D9 102.05%);
    .nav-replacer {
      height: $nav-height;
    }
    .filler{
      height: $nav-height;
      width: 100%;
    }
  }
  .how-it-works {
    display: flex;
    justify-content: center;
    padding-top: min(8vw, 8em);
    padding-bottom: min(5vw, 5em);
    background-repeat: no-repeat;
    background-position: right top;
  }
  .how-we-make-money {
    padding-top: min(3vw, 3em);
    padding-bottom: min(5vw, 5em);
  }
  .how-fairwey-diff {
    padding-top: min(3vw, 3em);
    padding-bottom: min(5vw, 5em);
    position: relative;
    .left-blob {
      left: 0;
      width: 1% important;
      display: inline-block;
      position:absolute
    }
    .graph{
      position: absolute;
      right: 10%;
    }
    .polygon{
      position: absolute;
      right: 5%;
      top: 15%;
    }
  }
  .footer {
    padding: 0;
    background: #4068DA;
  }
}

@media (max-width: 1070px) {
  #home-page {
    > * {
      padding-left: 4em;
      padding-right: 4em;
    }
    .how-fairwey-diff {
      .graph{
        top: 10%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #home-page{
    #how-we-diff{
      scroll-margin-top: 85px;
    }
    #how-it-works{
      scroll-margin-top: -26px;
    }
  }
}

@media (max-width: 600px) {
  #home-page {
    > * {
      padding-left: 2em;
      padding-right: 2em;
    }
    #how-we-diff{
      scroll-margin-top: 90px;
    }
    #how-it-works{
      scroll-margin-top: -27px;
    }
    #footer{
      scroll-margin-top: 170px;
    }
    .how-fairwey-diff {
      .graph{
        top: 10%;
      }
    }
  }
}

@media (max-width: 500px) {
  #home-page {
    .nav-better-wrap{
      // gap: 2em; 
    }
  }
}

@media screen and (max-width: 280px){
  #home-page{
    .how-fairwey-diff{
      .graph{
        top: 12%;
      }
    }
  }
}

// Safari 11+ specific css
// Either rework the scroll function in LandingNavigation.vue and delete this or keep this and pray it works
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  #home-page {
    #how-it-works{
      scroll-snap-margin-top: -35px;
    }
    #how-we-diff{
      scroll-snap-margin-top: 120px;
    }
  }
  @media screen and (max-width: 768px) {
    #home-page{
      #how-we-diff{
        scroll-snap-margin-top: 85px;
      }
      #how-it-works{
        scroll-snap-margin-top: -28px;
      }
    }
  }
  @media (max-width: 500px) {
    #home-page {
      #how-it-works{
        scroll-snap-margin-top: -26px;
      }
    }
  }
}}
</style>
