<template lang="pug">
#agent-user-info-modal
  .header
    h1 {{ $t('agentProfile.clientInfo') }}
  .input-wrap
    base-input(
      disabled
      :label="$t('form.firstname')"
      v-model="data.usr_firstname"
    )
    base-input(
      disabled
      :label="$t('form.lastname')"
      v-model="data.usr_lastname"
    )
    base-input(
      disabled
      :label="$t('form.email')"
      v-model="data.usr_email"
    )
    base-input(
      disabled
      :label="$t('form.phone')"
      v-model="data.usr_phonenumber"
    )
  .button-line
    base-button(
      :label="'Back'"
      @click="$emit('close-modal')"
    )
</template>
<script>
import { capitalize } from "@/js/helpers.js";
export default {
  props: {
    data: {
      type: Object,
    },
  },
  methods: {
    getLabelFromKey (key) {
      return capitalize(key.slice(4));
    },
  },
};
</script>

<style lang="scss" scoped>
#agent-user-info-modal {
  display: flex;
  flex-direction: column;
  padding: 2em;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-left: 6px solid #3E94FF;
  border-radius: 5px;
  // gap: 2em;
  .header {
    h1 {
      font-size: 2em;
    }
  }
  .input-wrap {
    display: flex;
    flex-direction: column;
    margin: 2em 0;
    // gap: 2em;
    .base-input {
      margin-bottom: 2em;
    }
  }
}
</style>