<template lang="pug">
.quote-summary
  h1 {{ $t('profile.quoteSummary') }}
  .breakdown-box
    h3 {{ quote.inc_name }}
    .bundle(
      v-if="quote.prices.length > 1"
    )
      quote-price-badge(
        :label="$t('agentProfile.bundlePrice')"
        :placeholder="$t('profile.monthly')"
        :number="quote.qot_monthly"
      )
        //- :number="summOfMonthlyPrices"
      quote-price-badge(
        :label="' '"
        :placeholder="$t('profile.yearly')"
        :number="quote.qot_yearly"
      )
        //- :number="summOfYearlyPrices"
    .line-container(
      v-if="quote.prices.length > 1"
    )
      .line
    .each-badge(
      v-for="price in quote.prices"
    )
      quote-price-badge(
        :insId="price.ins_id"
        :placeholder="$t('profile.monthly')"
        :number="price.qpr_monthly"
      )
      quote-price-badge(
        :insId="price.ins_id"
        :label="' '"
        :placeholder="$t('profile.yearly')"
        :number="price.qpr_yearly"
      )
  .button-line
    base-button(
      :label="$t('buttons.backToQuotes')"
      noFillColor
      @click="$emit('close-modal')"
      id="safari-fix"
    )
    base-button(
      v-if="!quote.accepted"
      :label="$t('buttons.chooseQot')"
      @click="onConfirmQuote"
    )
</template>

<script>
import QuotePriceBadge from './QuotePriceBadge.vue';
export default {
  components: {
    QuotePriceBadge,
  },
  props: {
    quote: {
      type: Object,
    },
    quoteNumber: {
      type: Number,
    },
  },
  emits: [
    'close-modal',
    'on-confirm-quote'
  ],
  computed: {
    summOfMonthlyPrices () {
      const prices = this.quote.prices.map(price => price.qpr_monthly);
      return prices.reduce((a, b) => a + b);
    },
    summOfYearlyPrices () {
      const prices = this.quote.prices.map(price => price.qpr_yearly);
      return prices.reduce((a, b) => a + b);
    },
  },
  methods: {
    onConfirmQuote () {
      this.$emit('on-confirm-quote', this.quote.qot_id);
      this.$emit('close-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.quote-summary {
  display: flex;
  flex-direction: column;
  padding: 3em;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-left: 6px solid #3E94FF;
  border-radius: 5px;
  // margin: 2em 0;
  // @include flex-gap(2em, 'column nowrap');
  h1 {
    font-size: 2.2em;
    margin: 0 0 0 .1em;
  }
  .breakdown-box {
    display: flex;
    flex-direction: column;
    margin: 2em 0;
    // gap: 1em;
    // @include flex-gap(1em, 'column nowrap');
    padding: 1.8em 2.3em 2.3em 2.3em;
    border: 2px solid #DEDEE9;
    border-radius: 6px;
    width: max-content;
    h3 {
      margin: 0;
      color: #656B7E;
      margin-bottom: 1.2em;
      // margin-top: -1em;
    }
    .bundle {
      display: flex;
      margin: 2em 0;
      // gap: 1em 4em;
      // @include flex-gap(4em, 'row nowrap');
    }
    .line-container {
      position: relative;
      height: 1px;
      .line {
        position: absolute;
        top: -.5em;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right, #D2D2D2 50%, transparent 50%);
        background-size: 15px 100%;
      }
    }
    .each-badge {
      display: flex;
      flex-wrap: wrap;
      // gap: 1em 4em;
      margin: .5em 0;
    }
  }
  .button-line {
    display: flex;
    justify-content: flex-end;
    // gap: 1.5em;
    #safari-fix {
      margin-right: 1.5em;
    }
    > * {
      width: 10em;
    }
  }
}

@media (max-width: 768px) {
  .quote-summary {
    padding: 4vw;
    .bundle {
      justify-content: center;
    }
    .line-container {
      width: 100%;
    }
    .breakdown-box {
      align-items: center;
      .bundle{
        flex-direction: column;
      }
      .each-badge {
        display: flex;
        flex-direction: column;
      }
    }
    .button-line {
      flex-direction: column;
      align-items: center;
      #safari-fix {
        margin-right: 0;
        margin-bottom: 1.5em;
      }
    }
  }
}
</style>