<template lang="pug">
.add-new-carrier-wrap
  h1 {{ $t('agentProfile.addNewCarrier') }}
  base-select-image(
    v-if="carriers.length > 0"
    v-model="selectedCarrier"
    :label="$t('agentProfile.selectNewCarrier')"
    :options="carriers"
    :isFormSubmitted="formMixin_checkErrors"
    @has-error="formMixin_errorHandler"
  )
  base-button(
    @click="formMixin_onFormSubmit(onAddNewCarrier)"
    :label="$t('buttons.confirm')"
  )
</template>

<script>
import formMixin from '@/js/formMixin';
export default {
  mixins: [
    formMixin,
  ],
  emits: [
    'close-modal',
  ],
  data () {
    return {
      carriers: [],
      selectedCarrier: '',
    };
  },
  created () {
    this.getInsuranceCompanies();
  },
  methods: {
    async getInsuranceCompanies () {
      const apiObj = {
        api: this.globalMixin_api.getAvailableInsuranceCompanies,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.carriers = data.data;
      }
    },
    async onAddNewCarrier () {
      this.$emit('add-carrier', this.selectedCarrier);
      this.$emit('close-modal');
    },
    
  },
};
</script>

<style lang="scss" scoped>
.add-new-carrier-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  padding-top: 3em;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-left: 6px solid #3E94FF;
  border-radius: 5px;
  // gap: 2vw;
  // @include flex-gap(2vw, 'column nowrap');
  width: 30em;
  min-height: 40vh;
  // height: 100%;
  h1 {
    margin:0 0 1em 0;
    font-size: 2em;
  }
  button {
    margin: 1em 0;
  }
}
</style>