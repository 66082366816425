import { uniqueID } from '@/js/helpers';

export const toastStore = {
  namespaced: true,
  state: {
    toastArray: [],
  },
  mutations: {
    APPEND_TOAST (state, payload) {
      payload.id = uniqueID().getID();
      state.toastArray.push(payload);
      //cap is 10 toasts at a time
      if (state.toastArray.length > 10) {
        state.toastArray.shift();
      }
    },
    DELETE_TOAST (state, id) {
      const toastIndexToDelete = state.toastArray.findIndex(toast => toast.id === id);
      state.toastArray.splice(toastIndexToDelete, 1);
    },
  },
  actions: {
    appendToast ({ commit }, payload) {
      commit('APPEND_TOAST', payload);
    },
    deleteToast ({ commit }, id) {
      commit('DELETE_TOAST', id);
    },
  },
};
