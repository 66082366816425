import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import globalMixin from './js/globalMixin';
import { BaseAutoImporter } from "./js/helpers";
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueTheMask from 'vue-the-mask';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { InlineSvgPlugin } from "vue-inline-svg";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault(dayjs.tz.guess());

const app = createApp(App);

BaseAutoImporter(app);

app.mixin(globalMixin);

app
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueTheMask)
  .use(VueReCaptcha, { siteKey: '6LezzYMcAAAAAMYNB7LSmjf8M1zzlS0APXA9sbBD' }, {
    
  })
  .use(InlineSvgPlugin)
  .mount("#app");