/*
    Handles dynamic image import to components via globalMixin_images computed.
    Import every image here and add to images object
*/

//images
import logo from '@/assets/images/logo.svg';
import logoDark from '@/assets/images/logo-dark.svg';
import landingMan from '@/assets/images/landing-man.png';
import landingWoman from '@/assets/images/landing-woman.png';
import greatF from '@/assets/images/greatF.png';
import howMakeMoneyBackground from '@/assets/images/greatpurple.png';
import autoInsuranceImage from '@/assets/images/auto-illustraition.png';
import lifeInsuranceImage from '@/assets/images/life-illustraition.png';
import homeInsuranceImage from '@/assets/images/home-illustraition.png';
import leftBlob from '@/assets/images/left-blob.png';
import userDashboardIllustation from '@/assets/images/user-dashboard-illustration.png';
import paymentIllustration from '@/assets/images/payment-illustration.png';
import gornjiDesni from '@/assets/images/gornjidesni.png';
import landingAgentRegistrationImage from '@/assets/images/agent-registration-illustration.png';
import landingUserLoginImage from '@/assets/images/user-login-illustration.png';
import notFound from '@/assets/images/not-found.png';


//icons
import userAvatar from '@/assets/icons/user-avatar.svg';
import userAvatarBox from '@/assets/icons/user-avatar-box.svg';
import checkBlue from '@/assets/icons/check-blue.svg';
import checkWhite from '@/assets/icons/check-white.svg';
import calendar from '@/assets/icons/calendar.svg';
import selectArrow from '@/assets/icons/select-arrow.svg';
import backArrowLeft from '@/assets/icons/back-arror-left.svg';
import backArrowGray from '@/assets/icons/back-arrow-gray.svg';
import burger from '@/assets/icons/burger.svg';
import dashboard from '@/assets/icons/dashboard.svg';
import bullet from '@/assets/icons/bullet.svg';
import bell from '@/assets/icons/bell.svg';
import shareArrow from '@/assets/icons/share-arrow.svg';
import buttonArrow from '@/assets/icons/button-arrow.svg';
import plus from '@/assets/icons/plus.svg';
import sortArrow from '@/assets/icons/sort-arrow.svg';
import close from '@/assets/icons/close.svg';
import cancelWhite from '@/assets/icons/cancelWhite.svg';
import plusRound from '@/assets/icons/plus-round.svg';
import starBlue from '@/assets/icons/star-blue.svg';
import starPurple from '@/assets/icons/star-purple.svg';
import hourglass from '@/assets/icons/hourglass.svg';
import checkGreen from '@/assets/icons/check-green.svg';
import arrowDownBlue from '@/assets/icons/arrow-down-blue.svg';
import arrowDownGray from '@/assets/icons/arrow-down-gray.svg';
import lightbulbWhite from '@/assets/icons/lightbulb-white.svg';
import userIcon from '@/assets/icons/user-icon.png';
import phoneIcon from '@/assets/icons/phone-icon.png';
import magnifyingGlass from '@/assets/icons/magnifying-glass.svg';
import note from '@/assets/icons/note.svg';
import popupText from '@/assets/icons/popup-text.svg';
import exclamationRound from '@/assets/icons/exclamation-round.svg';
import exclamationRoundRed from '@/assets/icons/exclamation-round-red.svg';
import homeInsuranceIcon from '@/assets/icons/home-insurance-icon.svg';
import lifeInsuranceIcon from '@/assets/icons/life-insurance-icon.svg';
import autoInsuranceIcon from '@/assets/icons/auto-insurance-icon.svg';
import logoutIcon from '@/assets/icons/logout.png';
import agencyIllustration from '@/assets/icons/agency-illustration.svg';
import moreInfo from '@/assets/icons/more-info.svg';
import polygon from '@/assets/icons/polygon.png';
import graph from '@/assets/icons/graph.png';
import noteBlack from '@/assets/icons/noteBlack.jpg';
import lock from '@/assets/icons/lock.png';
import gears from '@/assets/icons/gears.svg';
import deleted from '@/assets/icons/deletedquote.png';
import adress from '@/assets/icons/adress.png';
import avatar from '@/assets/icons/avatar.png';
import calendarAlt from '@/assets/icons/calendar-alt.png';

const images = {
  userAvatar,
  userAvatarBox,
  checkBlue,
  checkWhite,
  calendar,
  selectArrow,
  backArrowLeft,
  backArrowGray,
  burger,
  logoDark,
  dashboard,
  bullet,
  bell,
  shareArrow,
  logo,
  buttonArrow,
  plus,
  sortArrow,
  close,
  cancelWhite,
  plusRound,
  starBlue,
  starPurple,
  hourglass,
  checkGreen,
  arrowDownBlue,
  arrowDownGray,
  lightbulbWhite,
  landingMan,
  landingWoman,
  popupText,
  magnifyingGlass,
  note,
  howMakeMoneyBackground,
  landingAgentRegistrationImage,
  landingUserLoginImage,
  autoInsuranceImage,
  lifeInsuranceImage,
  homeInsuranceImage,
  exclamationRound,
  exclamationRoundRed,
  homeInsuranceIcon,
  lifeInsuranceIcon,
  autoInsuranceIcon,
  logoutIcon,
  agencyIllustration,
  greatF,
  userIcon,
  phoneIcon,
  leftBlob,
  moreInfo,
  polygon,
  graph,
  userDashboardIllustation,
  gornjiDesni,
  paymentIllustration,
  noteBlack,
  notFound,
  lock,
  gears,
  deleted,
  adress,
  avatar,
  calendarAlt,
};

export default images;