<template>
  <div class="lead-wrap" :class="{ 'is-unread' : lead.isUnread }">
    <div class="status">
        <h3><img :src="getImageAndLeadStatus.img" /><span>{{ getImageAndLeadStatus.status }}</span></h3>
        <div class="date">{{ getDate }}</div>
    </div>
    <div class="type" v-show="!lead.aps_deleted">
        <div>
          <span v-if="lead.ins_types.length > 1">
            {{ $t('agentProfile.bundle') + ': ' }}
          </span>
          <span v-for="insurance in lead.ins_types" :key="insurance.aps_id">
            {{lead.ins_types.length > 1 && insurance != lead.ins_types[lead.ins_types.length-1] ?  insurance.ins_name + " + " : insurance.ins_name}}
          </span>
        </div>
        <div class="ins-type">{{ lead.insType }}</div>
        <p>{{ $t('agentProfile.insuranceType') }}</p>
    </div>
    <img
      class="note"
      v-if="lead.aps_confirmed || lead.won_quote"
      v-show="!lead.aps_deleted"
      :src="globalMixin_images.noteBlack"
      alt="checklist"
      @click="setSelectedLead"
    >
    <div
      v-if="!lead.won_quote || (lead.won_quote && lead.aps_confirmed)"
      v-show="!lead.aps_deleted"
      class="button-wrap"
    >
      <base-button
        v-if="!lead.aps_confirmed"
        :label="$t('buttons.viewSummary')"
        noFillColor
        @click="setSelectedLead"
      ></base-button>
      <base-button
        v-else
        :label="$t('buttons.showClientInfo')"
        @click="onShowClientInfo"
      ></base-button>
    </div>
    <div
      v-if="lead.won_quote == 1 && !lead.aps_confirmed"
      v-show="!lead.aps_deleted"
      class="button-wrap"
    >
      <base-button
        :label="$t('buttons.showClientInfo')"
        @click="onShowClientInfo"
      ></base-button>
    </div>
    <!-- <div
      class="button-wrap"
      v-if="lead.edit"
    >
      <base-button
        :label="$t('buttons.editQuote')"
        @click="onEditQuote"
      ></base-button>
    </div> -->
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  props: {
    lead: {
      type: Object,
    },
  },
  emits: [
    'set-selected-lead',
    'show-client-info',
    'edit-quote',
  ],
  computed: {
    getImageAndLeadStatus () {
      let obj = {};
      if(this.lead.aps_submitted && !this.lead.has_quote) {
        obj.img = this.globalMixin_images.starBlue;
        obj.status = "New";
      }
      if(this.lead.aps_submitted && this.lead.has_quote){
        obj.img = this.globalMixin_images.hourglass;
        obj.status = "Quoted";
      }
      if(this.lead.aps_submitted && this.lead.has_quote && this.lead.won_quote){
        obj.img = this.globalMixin_images.starPurple;
        obj.status = "Won";
      }
      if(this.lead.aps_submitted && this.lead.has_quote && this.lead.won_quote && this.lead.aps_confirmed)  {
        obj.img = this.globalMixin_images.checkGreen;
        obj.status = "Complete";
      }
      if(this.lead.edit && this.lead.has_quote){
        obj.img = this.globalMixin_images.hourglass;
        obj.status = "Updated";
      }
      if(this.lead.aps_deleted){
        obj.img = this.globalMixin_images.deleted;
        obj.status = 'Deleted';
      }
      return obj;
    },
    getDate () {
      return dayjs(dayjs(this.lead.aps_submitted).utc(true), 'YYYY-MM-DD HH:mm:ss').tz(dayjs.tz.guess()).format('MM/DD/YYYY');
      // return dayjs(this.lead.aps_submitted).format('MM/DD/YYYY');
    },
  },
  methods: {
    setSelectedLead () {
      this.$emit('set-selected-lead', this.lead);
    },
    onShowClientInfo () {
      this.$emit('show-client-info', this.lead);
    },
    onEditQuote () {
      this.$emit('edit-quote', this.lead);
    },
  },
};
</script>

<style lang="scss" scoped>
.lead-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1em 1em 1em 4px;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  &.is-unread {
    border-left: 5px solid #F95F83;
    padding: 1em 0;
  }
  h3, p {
    margin: 0;
  }
  > * {
    padding: 0 1vw;
    height: 100%;
  }
  h3 {
    display: flex;
    align-items: center;
    // gap: 0 .4em;
    @include flex-gap(.35em, 'row nowrap');
    span {
      font-size: 1.3em;
    }
    img {
      margin-top: 2px;
      width: 1em;
      height: 1em;
    }
  }
  .status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 8em;
    width: 35%;
    // gap: .2em 0;
    @include flex-gap(.2em, 'column nowrap');
    .date {
      margin-left: 1.4em;
      font-size: .95em;
      color: #9093AE;
      font-weight: 600;
    }
  }
  .type {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // width: 40%;
    flex-grow: 1;
    // width: 12em;
    border-left: 1px solid #E6E7EB;
    // gap: .2em 0;
    @include flex-gap(.15em, 'column nowrap');
    .ins-type {
      font-size: 1.5em;
    }
    p {
      font-size: .95em;
      font-weight: 600;
      color: #9093AE;
    }
  }
  .button-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // flex-grow: 1;
    width: 25%;
    button {
      // padding: 1vw;
      height: max-content;
      width: 10em;
    }
  }

  .note {
    height: 20px;
    cursor: pointer;
  }
}

@media (max-width: 400px) {
  .lead-wrap {
    .status {
      width: auto;
      h3 {
        flex-direction: column;
      }
      .date {
        margin: 0;
      }
    }
    .type {
      width: 20%;
      div {
        display: flex;
        flex-direction: column;
      }
    }
    .button-wrap {
      width: 35%;
      button {
        padding: .5em 0;
      }
    }
  }
}
</style>