<template lang="pug">
.base-radio-group(
  :id="globalMixin_uuid"
  :class="{ 'max-width' : maxWidth }"
  tabindex=0
)
  label.base-radio-label(
    v-if="label"
  )
    | {{ label }}
    question-mark(
      v-if='questionmarkHoverText'
      :questionmarkHoverText='questionmarkHoverText'
    )
  .radio-wrap(
    :class="{ vertical: vertical, 'has-error' : localErrors.length > 0 }"
  )
    base-radio(
      v-for="(option, index) in options",
      :disabled="disabled"
      :key="index",
      :name="name",
      :label="option[labelKey]",
      :value="option[valueKey]",
      :modelValue="modelValue",
      @update:modelValue="onChangeData"
    )
  p.error-msg(
    v-if="localErrors.length > 0"
    id="msg-error"
  )
    | {{ localErrors[0].message }}
</template>

<script>
import questionMark from '../questionMark.vue';
import baseValidatorMixin from '../../js/baseValidatorMixin';
export default {
  mixins: [
    baseValidatorMixin
  ],
  components: {
    questionMark,
  },
  props: {
    questionmarkHoverText: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    valueKey: {
      type: String,
      default: "id",
    },
    labelKey: {
      type: String,
      default: "label",
    },
    vertical: {
      type: Boolean,
    },
    maxWidth: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: [
    "update:modelValue"
  ],
  watch: {
    // this usually runs when hides/shows answeres triggers with dynamic questions
    options: {
      deep: true,
      handler () {
        const optionIds = this.options.map(option => option[this.valueKey]);
        if (!optionIds.includes(Number(this.modelValue))) this.$emit('update:modelValue', '');
      },
    },
  },
  methods: {
    async onChangeData (evt) {
      this.$emit('update:modelValue', evt);
      await this.$nextTick();
      this.onApplyValidators();
    },
  },
};
</script>

<style scoped lang="scss">
.base-radio-group {
  position: relative;
  display: flex;
  flex-direction: column;
  width: $input-width;
  outline: none;
  &.max-width {
    width: 100%;
  }
  .base-radio-label {
    display: flex;
    align-items: center;
    font-size: $input-label-font-size;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #656B7E;
  }
  .radio-wrap {
    min-height: $input-height;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin-bottom: .5em;
    border-radius: .5em;
    padding: .2em .5em;
    // font-size: .8em;
    > * {
      margin: .2em 0 !important;
    }
    &.vertical {
      flex-direction: column;    
    }
    &.has-error {
      border: 2px solid $input-error-border-color;
    }
    > * {
      margin: .3em;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>