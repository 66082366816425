<template lang="pug">
#choose-card-wrap
  h1 {{ $t('agentProfile.paymentAccounts') }}
  .cards-wrap
    base-radio-group(
      v-model="selectedCard"
      :options="cardOptions"
      name="paymentCards"
      :customErrorMsg="{ required: $t('agentProfile.pleaseChooseCard') }"
      :isFormSubmitted="formMixin_checkErrors"
      @has-error="formMixin_errorHandler"
      vertical
    )
  .button-line
    p(
      @click="handleNewCard"
    ) 
      | {{ $t('agentProfile.useDifferentMethod') }}
  .boxes
    base-checkbox(
      v-model="terms"
      :label="$t('registration.termsOfUseSpecial')"
    )
    span.error-msg(
      v-if="showTermsError"
    ) {{ $t('registration.youMustAcceptTerms') }}
  .button-line
    base-button(
      :label="$t('agentProfile.payWithSelectedCard')"
      :disabled="loading"
      :loading="loading"
      @click="payWithSelectedCard"
    )
</template>

<script>
import { capitalize } from '@/js/helpers';
import formMixin from '@/js/formMixin';
export default {
  mixins: [
    formMixin,
  ],
  props: {
    cards: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  data () {
    return {
      selectedCard: null,
      terms: false,
      showTermsError: false,
    };
  },
  watch: {
    terms: {
      handler (isTrue) {
        if (isTrue) {
          this.showTermsError = false;
        }
      },
    },
  },
  computed: {
    cardOptions () {
      return this.cards.map(cardObj => {
        return {
          id: cardObj.id,
          label: `${capitalize(cardObj.card.brand)} **** **** **** ${cardObj.card.last4}`,
        };
      });
    },
    selectedCardObj () {
      return this.cards.find(cardObj => cardObj.id === this.selectedCard);
    },
  },
  methods: {
    payWithSelectedCard () {
      if (this.terms) this.$emit('make-payment', this.selectedCardObj);
      else this.showTermsError = true;
    },
    handleNewCard () {
      this.$emit('new-card');
    },
  },
};
</script>

<style lang="scss" scoped>
#choose-card-wrap {
  display: flex;
  flex-direction: column;
  .base-radio-group {
    margin: 2em 0;
  }
  h1 {
    margin: 0;
    font-size: 1.3em;
  }
  .cards-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    // > * {
    //   width: max-content;
    // }
  }
  .button-line {
    display: flex;
    justify-content: center;
    .base-button {
      margin-top: 2em;
    }
    p {
      margin: 0;
      color: $main-blue;
      cursor: pointer;
      text-decoration: underline;
      width: max-content;
    }
  }
  .boxes {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 1em;
  }
}
</style>