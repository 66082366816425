<template lang="pug">
.base-select
  label.base-select-label(
    v-if="label" 
    :for="globalMixin_uuid"
  )
    | {{ label }}
    question-mark(
      v-if='questionmarkHoverText'
      :questionmarkHoverText='questionmarkHoverText'
    )
  select(
    v-bind="$attrs" 
    :id="globalMixin_uuid" 
    :value="modelValue" 
    :class="{ 'has-error' : localErrors.length > 0 }"
    @change="onChangeData"
  )
    option(
      v-if="placeholder"
      selected 
      disabled
    )
      //- :value="modelValue" 
      | {{ placeholder }}
    option(
      v-for="(option, index) in options"
      :key="index"
      :value="option[valueKey]"
      :selected="modelValue == option[valueKey]"
    )
      | {{ option[labelKey] }}
  p.error-msg(
    v-if="localErrors.length > 0"
  )
    | {{ localErrors[0].message }}
</template>

<script>
import questionMark from '../questionMark.vue';
import baseValidatorMixin from '../../js/baseValidatorMixin';
export default {
  components: {
    questionMark,
  },
  mixins: [
    baseValidatorMixin
  ],
  props: {
    questionmarkHoverText: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    valueKey: {
      type: String,
      default: "id",
    },
    labelKey: {
      type: String,
      default: "label",
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
  },
  emits: [
    'update:modelValue'
  ],
  watch: {
    // this usually runs when hides/shows answeres triggers with dynamic questions
    options: {
      deep: true,
      handler () {
        const optionIds = this.options.map(option => option[this.valueKey]);
        if (!optionIds.includes(Number(this.modelValue))) this.$emit('update:modelValue', '');
      },
    },
  },
  methods: {
    async onChangeData (evt) {
      this.$emit('update:modelValue', Number(evt.target.value));
      await this.$nextTick();
      this.onApplyValidators();
    },
  },
};
</script>

<style scoped lang="scss">
.base-select {
  display: flex;
  flex-direction: column;
  position: relative;
  width: $input-width;
  @include flex-gap(.5em, 'column nowrap');
  .base-select-label {
    display: flex;
    align-items: center;
    @include flex-gap(.1em, 'row nowrap');
    font-size: $input-label-font-size;
    font-weight: 600;
    font-family: 'Open Sans';
    color: #656B7E;
  }
  select {
    height: $input-height;
    padding: 0 1em;
    border: 2px solid #DEDEE9;
    border-radius: .5em;
    outline: none;
    font-size: 1em;
    font-weight: 600;
    color: #434961;
    font-family: 'Open Sans';
    //arrow styles
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../../assets/icons/select-arrow.svg");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 1em);
    background-position-y: calc((#{$input-height} / 2) - 8px);
    background-color: #FFF;
    //arrow
    &.has-error {
      border: 2px solid $input-error-border-color;
    }
    option {
      cursor: pointer;
    }
    &.error {
      border-color: $input-error-border-color;
    }
    &:active, &:focus {
      border: 2px solid #3E94FF;
    }
    &:disabled {
      border: none;
      background: #EFF0F1;
      opacity: 1;
      &:active {
        outline: none;
      }
    }
  }
}
</style>