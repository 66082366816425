export const loaderStore = {
  namespaced: true,
  state: {
    isLoading: false,
    cancelLoading: false,
    timeout: null,
  },
  mutations: {
    START_LOADING (state) {
      state.isLoading = true;
    },
    STOP_LOADING (state) {
      state.isLoading = false;
    },
    SET_CANCEL_LOADING (state, bool) {
      state.cancelLoading = bool;
    },
    SET_TIMEOUT (state, payload) {
      state.timeout = payload;
    },
  },
  actions: {
    startLoading (store, delay = 800) {
      store.commit('SET_CANCEL_LOADING', false);
      clearTimeout(store.state.timeout);
      store.commit('SET_TIMEOUT', setTimeout(() => {
        if (!store.state.cancelLoading) store.commit('START_LOADING');
      }, delay));
    },
    stopLoading (store) {
      if (!store.state.isLoading) store.commit('SET_CANCEL_LOADING', true);
      store.commit('STOP_LOADING');
    },
  },
};
