export default {
  props: {
    questions: {
      type: Object,
    },
    title: {
      type: String,
    },
    checkErrors: {
      type: Boolean,
    },
  },
  emits: [
    'update:questions',
    'hasError',
    'is-form-filled',
  ],
  data () {
    return {
      //creating questions copy prevent watcher and debugging errors
      localQuestions: JSON.parse(JSON.stringify(this.questions)),
      errors: [],
    };
  },
  watch: {
    localQuestions: {
      deep: true,
      handler () {
        this.isFormFilledCheck();
      },
    },
    checkErrors: {
      async handler (isTrue) {
        if (isTrue) {
          //clear errors before checking again
          this.errors = [];

          //wait for errors to fill, then focus the top input with error
          await this.$nextTick();
          const inputObjectsWithErrors = this.errors.filter(errObj => errObj.errors.length > 0);
          if (inputObjectsWithErrors.length > 0) {
            const inputsWithErrorIds = inputObjectsWithErrors.map(errObj => errObj.id);
            const inputIdToFocus = Math.min(...inputsWithErrorIds);
            const inputDomEl = document.getElementById(inputIdToFocus);
            inputDomEl.focus();
          }
        }
      },
    },
  },
  mounted () {
    this.isFormFilledCheck();
  },
  methods: {
    errorHandler (errorObject) {
      const objToReplace = this.errors.find(errObj => errObj.id === errorObject.id);
      if (objToReplace) {
        const index = this.errors.findIndex(errObj => errObj.id === errorObject.id);
        this.errors.splice(index, 1, errorObject);
        // TODO TEST THIS
        // this.errors.splice(index, 1, objToReplace);
      } else {
        this.errors.push(errorObject);
      }

      const hasError = this.errors.some(errorObject => errorObject.errors.length > 0);
      this.$emit('hasError', hasError);
      
      // if statement is commented because of the bug
      // questions need to be updated for dymanic question to proccess
      // if (!hasError) {
        // TODO CONSIDER CHANGING THIS TO RETURN ONLY CHANGED QUESTIONS
        this.$emit('update:questions', this.localQuestions);
      // }
    },
    getQuestionType (question) {
      let inputComponent;
      switch (question.que_type) {
        case 1: inputComponent = 'BaseSelect'; break;
        case 2: inputComponent = 'BaseRadioGroup'; break;
        case 3: inputComponent = 'BaseCheckboxGroup'; break;
        case 4: inputComponent = 'BaseDatePicker'; break;
        default: inputComponent = 'BaseInput'; break;
      }
      return inputComponent;
    },
    isFormFilledCheck () {
      // if dynamic questions
      if (Array.isArray(this.localQuestions)) {
        this.$emit('is-form-filled', this.localQuestions.every(que => {
          if (que.answer === null) return false;
          if (que.que_type === 3) return true;
          else return que.answer.length > 0 || (que.answer >= 0 && que.answer !== '');
        }));
      } else {
      // if hardcoded questions
        const localQuestionsCopy = JSON.parse(JSON.stringify(this.localQuestions));
        
        // handles maritalStatus (3 fields are hidden, but still within object)
        if (this.localQuestions.maritalStatus !== undefined) {
          if (this.localQuestions.maritalStatus.value === 0) {
            delete localQuestionsCopy.partnerDateOfBirth;
            delete localQuestionsCopy.partnerFirstName;
            delete localQuestionsCopy.partnerLastName;
          }
        }

        for (const key in localQuestionsCopy) {
          // if field is not required, skip check
          if (localQuestionsCopy.notRequiredFields && localQuestionsCopy.notRequiredFields.includes(key)) continue;
          if (typeof this.localQuestions[key] === 'string') {
            if (this.localQuestions[key].length === 0) {
              this.$emit('is-form-filled', false);
              return;
            }
          } else {
          // only object is expected here
            if (this.localQuestions[key].value === '') {
              this.$emit('is-form-filled', false);
              return;
            }
          }
        }

        this.$emit('is-form-filled', true);
      }
    },
  },
};