<template lang="pug">
.wizard-wrap(
  :class="{ 'horizontal' : horizontal }"
)
  .step-wrap(
    v-for="(step, index) in steps"
    :key="index"
    :class="{ 'horizontal' : horizontal, 'is-mobile': isMobileMixin_isMobile, 'is-last-horizontal': index === steps.length-1 && horizontal }"
  )
    .circle-description(
      @click="setActiveStep(index)"
    )
      .step-circle(
        :class="{ 'active': step.active, 'is-mobile': isMobileMixin_isMobile, 'small' : small }"
        v-if="!step.completed"
      )
        | {{ index + 1 }}
      .completed-circle(
        :class="{ 'active': step.active, 'is-mobile': isMobileMixin_isMobile, 'small' : small }"
        v-if="step.completed"
      )
        img(
          :src="step.active? globalMixin_images.checkWhite: globalMixin_images.checkBlue"
          :alt="globalMixin_images.checkBlue"
        )
      .step-description
        span.step(
          v-if="!horizontal"
        ) 
          | {{ (`Step  ${index + 1}`).toUpperCase() }}
        span.title(
          :class="{ 'active': step.active }"
          v-if="!horizontal"
        )
          | {{ step.title }}
  .line(
    v-if="steps.length > 1"
    :class="{ 'is-mobile': isMobileMixin_isMobile, 'is-horizontal': horizontal }"
  )
</template>

<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'setActiveStep'
  ],
  methods: {
    setActiveStep (index) {
      this.$emit('setActiveStep', index);
    },
  },
};
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}
.wizard-wrap {
  $circleSize: 2.4em;
  $circleSizeSmall: 3em;

  display: flex;
  flex-direction: column;
  color: #94969D;
  font-weight: 600;
  // gap: 5em .5em;
  @include flex-gap (5em, 'column nowrap');
  width: 100%;
  position: relative;
  &.horizontal {
    flex-direction: row;
    // gap: .5em 0;
  }
  .step-wrap {
    display: flex;
    flex-direction: column;
    z-index: 1;
    &.horizontal {
      width: 100%;
    }
    &.is-mobile {
      width: 100%;
    }
    &.is-last-horizontal {
      width: $circleSize;
    }
    .circle-description {
      display: flex;
      width: 100%;
      cursor: pointer;
      // gap: 0 1em;
      @include flex-gap (1em, 'row nowrap');
      .step-circle, .completed-circle {
        font-size: 1.8em;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: $circleSize;
        min-width: $circleSize;
        max-height: $circleSize;
        max-width: $circleSize;
        border-radius: 50%;
        background: #EFF1F8;
        padding-bottom: 2px;
        transition: background 0.2s ease-out;
        &.is-mobile, &.small {
          min-height: $circleSizeSmall;
          min-width: $circleSizeSmall;
          max-height: $circleSizeSmall;
          max-width: $circleSizeSmall;
          font-size: 1em;
        }
        &.active {
          color: white;
          background: $main-blue;
          border: 9px solid $main-light-blue;
          &.is-mobile, &.small {
            border: 5px solid $main-light-blue;
          }
        }
      }
      .completed-circle {
        background: $main-light-blue;
        &.is-mobile, &.small {
          min-height: $circleSizeSmall;
          min-width: $circleSizeSmall;
          max-height: $circleSizeSmall;
          max-width: $circleSizeSmall;
        }
        img {
          width: 1.5em;
          height: 1.5em;
        }
      }
      
      .step-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        .step {
          color:#ABAEB9;
          font-size: .9em;
          height: 20px;
          margin-left: 8px;
          margin-bottom: 5px;
        }
        .title {
          font-size: 1.4em;
          white-space: nowrap;
          margin-left: 8px;
          transition: color 0.3s ease-out;
          &.active {
            color: $main-blue;
          }
        }
      }
    }
  }
  .line {
    font-size: 1.8em;
    min-height: 80%;
    width: 3px;
    position: absolute;
    top: 0;
    left: calc((#{$circleSize} / 2) + 4px);
    background: #DEE2ED;
    z-index: .5;
    &.is-mobile {
      left: 18px;
    }
    &.is-horizontal {
      min-height: 3px;
      top: -8%;
      left: 0;
      width: 100%;
    }
  }
}
</style>