<template lang="pug">
.submit-quote-wrap
  h2 {{ $t('profile.quoteSummary') }}
  .forms-wrap
    .form(
      v-for="form in forms"
    )
      h3 {{ form.inc_name }}
      .badges-wrap
        .price-row.bundle(
          v-if="form.qot_monthly && form.qot_yearly"
        )
          quote-price-badge(
            :label="$t('agentProfile.bundlePrice')"
            :placeholder="$t('profile.monthly')"
            :number="form.qot_monthly"
          )
          quote-price-badge(
            :label="' '"
            :placeholder="$t('profile.yearly')"
            :number="form.qot_yearly"
          )
        .price-row(
          v-for="price in form.prices"
          :key="price.ins_id"
        )
          quote-price-badge(
            :insId="price.ins_id"
            :placeholder="$t('profile.monthly')"
            :number="price.qpr_monthly"
          )
          quote-price-badge(
            :insId="price.ins_id"
            :label="' '"
            :placeholder="$t('profile.yearly')"
            :number="price.qpr_yearly"
          )
  .buttons-line
    base-button(
      noFillColor
      :label="$t('buttons.backToQuotes')"
      @click="$emit('close-modal')"
    )
    base-button(
      :label="$t('buttons.confirm')"
      @click="onConfirmQuote"
    )
</template>

<script>
import QuotePriceBadge from '../my-quotes/QuotePriceBadge.vue';
export default {
  components: {
    QuotePriceBadge,
  },
  props: {
    forms: {
      type: Array,
      required: true,
    },
  },
  emits: [
    'close-modal',
    'on-confirm-quote',
  ],
  methods: {
    onConfirmQuote () {
      this.$emit('on-confirm-quote');
      this.$emit('close-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.submit-quote-wrap {
  display: flex;
  flex-direction: column;
  padding: 2em;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-left: 6px solid #3E94FF;
  border-radius: 5px;
  // gap: 2em;
  h2 {
    font-size: 2.2em;
    margin: 0;
  }
  .forms-wrap {
    display: flex;
    flex-direction: column;
    // gap: 1em;
    // uncomment for gap
    margin: 2em 0;
    .form {
      display: flex;
      flex-direction: column;
      padding: 2em;
      border: 2px solid #DEDEE9;
      border-radius: 6px;
      h3 {
        font-size: 1.2em;
        margin-top: 0;
        color: #5F6174;
      }
      .badges-wrap {
        display: flex;
        flex-wrap: wrap;
        // gap: 3em;
        @include flex-gap(3em, 'row wrap');
        .price-row {
          display: flex;
          flex-wrap: wrap;
          // gap: 1em;
          // uncomment for gap
          .badge-wrap {
            &:first-child {
              margin-right: 1em;
            }
          }
        }
      }
    }
  }
  .buttons-line {
    display: flex;
    justify-content: flex-end;
    // gap: 2em;
    @include flex-gap(2em, 'row nowrap');
    button {
      width: 10em;
    }
  }
}

@media (max-width: 450px) {
  .submit-quote-wrap {
    .forms-wrap {
      .form {
        padding: 1em;
        h3 {
          text-align: center;
        }
        .badges-wrap {
          .price-row {
            // display: flex;
            // flex-wrap: wrap;
            justify-content: center;
            // gap: 1em;
            .badge-wrap {
              width: 100%;
              &:first-child {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 280px){
  .submit-quote-wrap{
    .buttons-line{
      flex-direction: column;
      &:first-child {
        margin-bottom: 2em;
      }
    }
  }
}
</style>