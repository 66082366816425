const DEFAULT_MAX_INPUT_LENGTH = 500;

export default {
  props: {
    validatorTypes: {
      // array of strings indicating what requirements the input has etc. required, isLessthan255, isLongerThanEight...
      type: Array,
      default: () => ['required', 'maxLength'], // 'required',
    },
    validatorData: {
      type: Object,
      default: () => ({}),
    },
    customErrorMsg: {
      type: Object,
      default: () => ({}),
    },
    isFormSubmitted: {
      type: Boolean,
      default: false,
    },
    backendErrors: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    'hasError'
  ],
  data () {
    return {
      localErrors: [...this.backendErrors],
    };
  },
  computed: {
    hasError () {
      return this.localErrors.length > 0;
    },
  },
  watch: {
    isFormSubmitted: {
      handler (isTrue) {
        if (isTrue) {
          this.onApplyValidators();
        }
      },
    },
    backendErrors: {
      deep: true,
      handler () {
        this.localErrors.push(...this.backendErrors);
      },
    },
  },
  methods: {
    onApplyValidators () {
      this.clearErrors();
      this.applyValidators();
      this.emitErrorBack();
    },
    clearErrors () {
      this.localErrors = [];
    },
    applyValidators () {
      // all of current validators
      for (const type of this.validatorTypes) {
        switch (type) {
          case 'maxLenght':
            this.maxLengthValidator();
            break;
          case 'regex':
            this.regexValidator();
            break;
          case 'required':
            this.requiredValidator();
            break;
          case 'password':
            this.passwordValidator();
            break;
          case 'confirmPassword':
            this.confirmPasswordValidator();
            break;
          case 'number':
            this.isNumber();
            break;
          case 'year':
            this.yearValidator();
            break;
          case 'phoneNum':
            this.phoneNumValidator();
            break;
          default:
            break;
        }
      }
    },
    emitErrorBack () {
      const errorObj = {
        id: this.globalMixin_uuid, //inputID
        errors: this.localErrors,
      };
      this.$emit('hasError', errorObj);
    },
    maxLengthValidator () {
      const maxLength = this.validatorData.maxLength || DEFAULT_MAX_INPUT_LENGTH; 

      if (this.modelValue.length > maxLength) {
        const errorObj = {
          type: 'maxLength',
          message: this.customErrorMsg.maxLenght || 'Must containt less characters.',
        };
        this.localErrors.push(errorObj);
      }
    },
    phoneNumValidator () {
      // Phone number format is stored in globalMixin_phoneMask variable (Currently '(###)###-####')
      // with "(", ")" and "-" chars and digitsCount we get rawPhoneFormatLength (3 + 10 = 13)
      const digitsCount = 10;
      const phoneNonDigitsCount = 3;
      const rawPhoneFormatLength = digitsCount + phoneNonDigitsCount;
      
      if(this.modelValue && this.modelValue.length < rawPhoneFormatLength){
        const errorObj = {
          type: 'phoneNum',
          message: `Phone number must be ${digitsCount} characters long`,
        };
        this.localErrors.push(errorObj); 
      }
    },
    regexValidator () {
      if (!this.validatorData.regex) return;
      
      const regex = new RegExp(this.validatorData.regex);

      if (!regex.test(this.modelValue)) {
        const errorObj = {
          type: 'regex',
          message: this.customErrorMsg.regex || 'This field doesn\'t match the pattern.',
        };
        this.localErrors.push(errorObj);
      }
    },
    requiredValidator () {
      if (this.modelValue === undefined || this.modelValue === null || this.modelValue.length === 0) {
        const errorObj = {
          type: 'required',
          message: this.customErrorMsg.required || 'This field is required.',
        };
        this.localErrors.push(errorObj);
      }
    },
    passwordValidator () {
      const minPasswordLength = 8;
      if (this.modelValue.length <= minPasswordLength) {
        const errorObj = {
          type: 'password',
          message: this.customErrorMsg.required || `Password must contain more than ${minPasswordLength} characters.`,
        };
        this.localErrors.push(errorObj);
      }
    },
    confirmPasswordValidator () {
      if (!this.validatorData.confirmPassword) return;

      if (this.modelValue !== this.validatorData.confirmPassword) {
        const errorObj = {
          type: 'confirmPassword',
          message: this.customErrorMsg.confirmPassword || 'Passwords must match.',
        };
        this.localErrors.push(errorObj);
      }
    },
    isNumber () {
      if (Number.isNaN(+this.modelValue)) {
        const errorObj = {
          type: 'number',
          message: this.customErrorMsg.required || 'This field must be a number.',
        };
        this.localErrors.push(errorObj);
      }
    },
    yearValidator () {
      const minYear = 1900;
      if (this.modelValue > new Date().getFullYear() || this.modelValue < minYear) {
        const errorObj = {
          type: 'year',
          message: this.customErrorMsg.required || `The year must be between ${minYear} and current year.`,
        };
        this.localErrors.push(errorObj);
      }
    },
  },
};