<template lang="pug">
.base-type-dropdown
  label.base-label-el(
    v-if="label"
  )
    //- :for="globalMixin_uuid"
    | {{ label }}
    question-mark(
      v-if='questionmarkHoverText'
      :questionmarkHoverText='questionmarkHoverText'
    )
  .input(
    :class="{ 'error' : hasError, 'is-open' : isOpen, 'disabled' : disabled }"
    @click.stop="onToggleOpen"
  )
    //- img(
    //-   v-if="selectedOption && selectedOption.inc_image"
    //-   :src="selectedOption.inc_image"
    //-   alt="company-icon.svg"
    //- )
    p.name(
      v-if="selectedOption"
    ) 
      | {{ selectedOption.inc_name }}
    .x(
      v-if="!disabled"
      :class="{ 'has-label' : label, 'is-open': isOpen }"
    )
  transition(
    name="fade"
    appear
  )
    .options(
      v-show="isOpen && localOptions.length > 0"
    )
      .option(
        v-for="option in localOptions"
        :class="{ 'selected' : option.selected, 'disabled' : option.hidden }"
        @mouseenter="setHover(option)"
        @mouseleave="setHover(option)"
        @click.stop="!option.hidden? onOptionClick(option) : null"
      )
        //- img(
        //-   :src="option.inc_image"
        //-   alt="company-image.svg"
        //- )
        p.name {{ option.inc_name }}
        transition(
          name="fade"
        )
          //- .option-hover(
          //-   v-if="option.showTooltip"
          //- ) 
          //-   .triangle
          //-   | {{ option.disabledTooltipMsg }}
  p.error-msg(
    v-if="localErrors.length > 0"
  )
    | {{ localErrors[0].message }}
</template>

<script>
import questionMark from '../questionMark.vue';
import baseValidatorMixin from '../../js/baseValidatorMixin';
export default {
  mixins: [
    baseValidatorMixin
  ],
  components: {
    questionMark,
  },
  props: {
    questionmarkHoverText: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: [String, Number],
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: [
    'update:modelValue'
  ],
  data () {
    return {
      isOpen: false,
      localOptions: [],
    };
  },
  computed: {
    selectedOption () {
      return this.localOptions.find(option => option.inc_id === this.modelValue);
    },
    // hide options if neccessary
    filteredOptions () {
      return this.localOptions.filter(opt => !opt.hidden);
    },
  },
  watch: {
    options: {
      deep: true,
      handler () {
        this.initLocalOptions();
      },
    },
  },
  created () {
    this.init();
  },
  beforeUnmount () {
    document.removeEventListener('click', () => this.isOpen = false);
  },
  methods: {
    init () {
      this.initLocalOptions();
      document.addEventListener('click', () => this.isOpen = false);
    },
    initLocalOptions () {
      this.localOptions = this.options.map(opt => {
        return {
          ...opt,
          showTooltip: false,
        };
      });
    },
    async onOptionClick (option) {
      this.isOpen = false;
      this.$emit('update:modelValue', option.inc_id);
      await this.$nextTick();
      this.onApplyValidators();
    },
    onToggleOpen () {
      if (this.disabled) return;
      this.toggleOpen();
    },
    toggleOpen () {
      this.clearSelected();
      this.isOpen = !this.isOpen;
    },
    bindArrowsAndEnter (evt) {
      if (evt.code === 'ArrowDown') {
        this.setSelectedOption(1, this.localOptions[0].index);
      }

      if (evt.code === 'ArrowUp') {
        const lastIndex = this.localOptions.length - 1;
        this.setSelectedOption(-1, this.localOptions[lastIndex].index);
      }

      if (evt.code === 'Enter') {
        this.onOptionClick(this.localOptions.find(option => option.selected).value);
      }

      if (evt.code === 'Escape') {
        this.isOpen = false;
      }
      //prevents dropdown being open while tabbing out of focus
      if (evt.code === 'Tab') {
        this.isOpen = false;
      }
    },
    setSelectedOption (offset, defaultIndex) {
      // offset = 1 would select the next option based on currently selected option
      // offset = -1 would select the previous
      let toBeSelectIndex = defaultIndex;

      for (let i = 0; i < this.filteredOptions.length; i++) {
        if (this.filteredOptions[i].selected) {
          //handle edges
          const nextSelectIndex = i + offset;
          const lastIndex = this.filteredOptions.length - 1;
          if (nextSelectIndex > lastIndex || nextSelectIndex < 0) {
            toBeSelectIndex = defaultIndex;
            break;
          }

          toBeSelectIndex = this.filteredOptions[nextSelectIndex].index;
          break;
        }
      }
      this.clearSelected();
      this.localOptions[toBeSelectIndex].selected = true;
    },
    setHover (option) {
      option.showTooltip = !option.showTooltip;
    },
    clearSelected () {
      for (let i = 0; i < this.localOptions.length; i++) {
        this.localOptions[i].selected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-type-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  @include flex-gap(.5em, 'column nowrap');
  .base-label-el {
    display: flex;
    align-items: center;
    // @include flex-gap(0em, 'row nowrap');
    font-size: $input-label-font-size;
    font-weight: 600;
    color: #656B7E;
  }
  .input {
    background-color: #FFF;
    display: flex;
    align-items: center;
    @include flex-gap(0, 'row nowrap');
    height: $input-height;
    padding: 0 1em;
    border: 2px solid #DEDEE9;
    border-radius: .5em;
    transition: all .2s ease;
    outline: none;
    &.error {
      border-color: $input-error-border-color;
      // &:active, &:focus {
      //   border: 2px solid $input-error-border-color;
      // }
    }
    &.is-open {
      border-radius: .5em .5em  0 0;
    }
    img {
      max-width: 4.5em;
      max-height: 2.5em;
    }
    &.disabled {
      border: none;
      background: #EFF0F1;
    }
  }
  .options {
    padding: .5em 0;
    position: absolute;
    width: 100%;
    background: #FFF;
    overflow: auto;
    top: 100%;
    z-index: 2;
    border-radius: 0 0 11px 11px;
    border: 1px solid $box-border-color;
    border-top: none;
    max-height: 10em;
    .option {
      // position: relative;
      display: flex;
      align-items: center;
      @include flex-gap(1em, 'row nowrap');
      padding: 0 .5em;
      width: 100%;
      cursor: pointer;
      .option-hover {
        position: absolute;
        left: 50%;
        color: #FFF;
        background-color: $main-blue;
        z-index: 10;
        padding: .5em;
        border-radius: 6px;
        .triangle {
          position: absolute;
          $size: 10px;
          width: $size;
          height: $size;
          left: -5px;
          top: calc(50% - 5px);
          transform: rotate(45deg);
          background-color: $main-blue;
        }
      }
      img {
        max-width: 4.5em;
        max-height: 2.5em;
      }
      &:hover {
        background: $main-blue;
        color: #FFF;
        transition: all .2s ease;
      }
      &.selected {
        background: #CECEE1;
      }
      &.disabled {
        background: #EFF0F1;
        &:hover {
          color: black;
        }
      }
    }
  }
  .x {
    user-select: none;
    position: absolute;
    top: .3em;
    right: 1em;
    cursor: pointer;
    //arrow styles
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../../assets/icons/select-arrow.svg");
    background-repeat: no-repeat;
    background-position-x: calc(50%);
    background-position-y: -.2em;
    background-color: #FFF;
    //arrow
    height: 15px;
    width: 15px;
    transition: all .2s;
    &.has-label {
      top: 3.5em;
    }
    &.is-open {
      transform: rotate(180deg);
      top: 2.9em;
    }
  }
}
</style>