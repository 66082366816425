<template lang="pug">
#agent-quote-summary(
  v-if="dataForFinishingQuote"
)
  .header
    h1 Client Quote Summary
    base-button(
      :label="$t('buttons.quote')"
      @click="$router.push({ name: 'AgentLeadQuote', params: { id: lead.aps_id } })"
    )
  .user-info-wrap
    .user-info(
      v-if="user"
    )
      //- | {{ user }}
      p.client-title {{ $t('agentProfile.leadClientHeading') }}
      .info
        .ico
          img(
            :src="globalMixin_images.avatar"
          )
        .text
          | {{ user.usr_firstname }} {{ user.usr_lastname }}
      .info
        .ico
          img(
            :src="globalMixin_images.avatar"
          )
        .text
          | {{ genda }}
      .info
        .ico
          img(
            :src="globalMixin_images.adress"
          )
        .text
          | {{ user.usr_address1 }}, {{ user.usr_city }}
      .info
        .ico
          img(
            :src="globalMixin_images.calendarAlt"
          )
        .text
          | {{ user.usr_dateofbirth }}
    .spouse-info(
      v-if="user.usr_partnerfirstname"
    )
      p.client-title {{ $t('agentProfile.leadSpouseHeading') }}
      .info
        .ico
          img(
            :src="globalMixin_images.avatar"
          )
        .text
          | {{ user.usr_partnerfirstname }}
      .info
        .ico
          img(
            :src="globalMixin_images.avatar"
          )
        .text
          | {{ user.usr_partnerlastname }}
      .info
        .ico
          img(
            :src="globalMixin_images.calendarAlt"
          )
        .text
          | {{ user.usr_partnerdateofbirth }}
  .forms-wrap
    .form(
      v-for="app in submittedApplications"
      :key="app.ins_id"
    )
      h3 
        img(
          :src="globalMixin_images[`${getInsType(app)}InsuranceIcon`]"
          alt="insurance-icon.svg"
        )
        span(
          :class="getInsType(app)"
        ) 
          | {{ $t(`insurances.${getInsType(app)}`) }}
      .section(
        v-for="sec in app.sections"
      )
        span(v-if="sec.sec_name") {{ sec.sec_name }}
        .inputs
          component(
            disabled
            v-for="question in sec.questions"
            :key="question.que_id"
            v-model="question.answer"
            :is="getQuestionType(question)"
            :label="question.que_content"
            :symbol="question.que_symbol"
            :position="question.que_symbol_pos"
            :placeholder="question.que_placeholder"
            :name="`input${question.que_id}`"
            :options="question.answers !== null? question.answers : undefined"
            :labelKey="'ans_content'"
            :valueKey="'ans_id'"
          )
      component(
        v-if="bingbong"
        :is="getCalculationComponent(app.ins_id)"
        :totalAmount="getAppTotal(app.ins_id)"
        :otherData="getOtherData(app.ins_id)"
        :questions="coverageQuestions"
        :atRisk="atRisk"
        :umbrella="showUmbrella"
        :totalRequired="totalRequired"
        :isInLead="true"
      )
</template>

<script>
import dayjs from 'dayjs';
import InsuranceCalculationAuto from '@/components/profile/application/components/InsuranceCalculationAuto.vue';
import InsuranceCalculationLife from '@/components/profile/application/components/InsuranceCalculationLife.vue';
import InsuranceCalculationHome from '@/components/profile/application/components/InsuranceCalculationHome.vue';

export default {
  components: {
    InsuranceCalculationAuto,
    InsuranceCalculationLife,
    InsuranceCalculationHome,
  },
  props: {
    lead: {
      type: Object,
    },
  },
  emits: [
    'close-modal',
  ],
  data () {
    return {
      bingbong: false,

      submittedApplications: [],
      dataForFinishingQuote: null,
      user: null,

      autoTotal: 0,
      homeTotal: 0,
      lifeTotal: 0,

      autoOtherData: [],
      homeOtherData: [],
      lifeOtherData: [],

      coverageQuestions: [],
      autoCoverageQuestions: [],
      showUmbrella: false,
      atRisk: null,
      totalRequired: 0,
    };
  },
  computed: {
    genda () {
      switch (this.user.usr_gender) {
        case "0":
          return "Male";
       case "1":
          return "Female";
      
        default:
          return "Other";
      }
    },
  },
  mounted () {
    this.init();
  },
  methods: {
    holup () {
      setTimeout(() => {
        this.bingbong = true;
      }, 300);
    },
    async init () {
      await this.getQuoteSummary();
      this.funkyTown();
      this.holup();
    },
    getCalculationComponent (index) {
      let comp;
      switch (index) {
        case 1: comp = 'InsuranceCalculationHome'; break;
        case 2: comp = 'InsuranceCalculationLife'; break;
        case 3: comp = 'InsuranceCalculationAuto'; break;
      }
      return comp;
    },
    getAppTotal (index) {
      let comp;
      switch (index) {
        case 1: comp = this.homeTotal; break;
        case 2: comp = this.lifeTotal; break;
        case 3: comp = this.autoTotal; break;
      }
      return comp;
    },
    getOtherData (index) {
      let comp;
      switch (index) {
        case 1: comp = this.homeOtherData; break;
        case 2: comp = this.lifeOtherData; break;
        case 3: comp = this.autoOtherData; break;
      }
      return comp;
    },
    calcQuestions (id) {
      const calcQueArr = [];
      let newObj = this.submittedApplications.find(el => el.ins_id === id);
      for(let i = 0; i < newObj.sections.length; i++){
        newObj.sections[i].questions.forEach(que => {
          if(que.que_calc) calcQueArr.push(que);
        });
      }

      return calcQueArr;
    },
    async getQuoteSummary () {
      this.startLoading();
      const apiObj = {
        api: this.globalMixin_api.getLead,
        params: {
          id: this.lead.aps_id,
        },
      };


      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.dataForFinishingQuote = data.data;
        this.submittedApplications = this.transformData(data);
        this.user = data.user_info;
        this.user.usr_dateofbirth = dayjs(this.user.usr_dateofbirth).format('MM/DD/YYYY');
        if(this.user.usr_partnerdateofbirth) this.user.usr_partnerdateofbirth = dayjs(this.user.usr_partnerdateofbirth).format('MM/DD/YYYY');
      }
      this.stopLoading();
    },
    transformData (data) {
      for (const app of data.data) {
        for (const sec of app.sections) {
          sec.questions =  sec.questions.map(queObj => {
            const newQueObj = {
              ...queObj,
            };
            if (queObj.answer) {
              if (queObj.answer.ans_id) {
                // checkboxGroup answer is returned like "[1, 4]" so the string needs to be checked
                if (queObj.answer.ans_id.includes("[") || queObj.answer.ans_id.includes("]")) {
                  const parsedArr = queObj.answer.ans_id.substring(1, queObj.answer.ans_id.length - 1).split(',').map(int => Number(int));
                  newQueObj.answer = parsedArr;
                } else {
                  newQueObj.answer = queObj.answer.ans_id;
                }
              } else {
                newQueObj.answer = queObj.answer.qan_content;
              }
            } else {
              // checkboxGroup requires [] for v-model to handle multiple values
              if (queObj.que_type == 3) newQueObj.answer = [];
              else newQueObj.answer = '';
            }

            return newQueObj;
          });
        }
      }
      return data.data;
    },
    async funkyTown () {
      this.submittedApplications.forEach(app => this.postCalcQuestions(app.ins_id));
    },
    async postCalcQuestions (id) {
      const params = {
        id: id,
        answers: [],
        aps_id: this.lead.aps_id,
      };
      //question answer doesnt get updated so we await nextTick
      await this.$nextTick();

      for (let j = 0; j < this.calcQuestions(id).length; j++) {
        const queObj = this.calcQuestions(id)[j];
        params.answers.push({
          que_id: queObj.que_id,
          // if question is free input or date or number, fill ans_content property, otherwise fill ans_id
          // array includes non free text inputs
          ans_id: [0, 4, 5, 6].includes(queObj.que_type)? null : queObj.answer,
          ans_content: ![0, 4, 5, 6].includes(queObj.que_type)? null : queObj.answer,
        });
      }
      
      const apiObj = {
        api: this.globalMixin_api.postCalcQuestions,
        params,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);

      if (!error) {
        switch (id) {
          case 1:
            this.homeTotal = data.data;
            if (data['other-data']) this.homeOtherData = data['other-data']; 
            break;
          
          case 2:
            this.lifeTotal = data.data;
            if (data['other-data']) this.lifeOtherData = data['other-data']; 
            break;
          
          case 3:
            this.autoTotal = data.data;
            if (data['other-data']) this.autoOtherData = data['other-data']; 
            this.showUmbrella = data.umbrella;
            this.atRisk = data.at_risk;
            this.totalRequired = data.total_required;
            if (data.questions) this.coverageQuestions = data.questions.map(que => {
              if (que.que_type === 3 && !que.answer) que.answer = [];
              return que;
            });
            break;
          default:
            break;
        }
        // this.totalCalcAmount = data.data;
        // this.showUmbrella = data.umbrella;
        // this.atRisk = data.at_risk;
        // this.totalRequired = data.total_required;
        // if (data['other-data']) this.otherData = data['other-data'];
        // if (data.questions) this.coverageQuestions = data.questions.map(que => {
        //   if (que.que_type === 3 && !que.answer) que.answer = [];
        //   return que;
        // });
      }
    },
    getQuestionType (question) {
      let inputComponent;
      switch (question.que_type) {
        case 0: inputComponent = 'BaseInput'; break;
        case 1: inputComponent = 'BaseSelect'; break;
        case 2: inputComponent = 'BaseRadioGroup'; break;
        case 3: inputComponent = 'BaseCheckboxGroup'; break;
        case 4: inputComponent = 'BaseDatePicker'; break;
        case 5: inputComponent = 'BaseInput'; break;
      }
      return inputComponent;
    },
    getInsType ({ ins_id }) {
      switch (ins_id) {
        case 1: return 'home';
        case 2: return 'life';
        case 3: return 'auto';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#agent-quote-summary {
  display: flex;
  flex-direction: column;
  padding: 2em;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-left: 6px solid #3E94FF;
  border-radius: 5px;
  // min-width: 1055px;
  // min-height: 60vh;
  // gap: 2em;
  // @include flex-gap(2em, 'column nowrap');
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
    h1 {
      font-size: 2.2em;
      margin: 0 0 0 .1em;
    }
    button {
      margin-right: 2em;
      height: $input-height;
      width: min(100%, 11em);
    }
  }
  .user-info-wrap{
    display: flex;
    flex-direction: row;
    .user-info,
    .spouse-info {
      display: flex;
      flex-direction: column;
      // height: 22px;
      min-width: 30%;
      margin-bottom: 1em;
      .client-title {
        font-weight: bold;
      }
      .info{
        display: flex;
        align-items: center;
        .ico{
          height: 60%;
          margin-right: 5px;
          img {
            height: 100%;
          }
        }
        .text {
          // font-family: 'Century Gothic';
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 23px;
          text-align: center;
          /* or 23px */
          color: #A1A4AC;
        }
      }
    }
  }
  .forms-wrap {
    display: flex;
    flex-direction: column;
    // gap: 1em;
    @include flex-gap(1em, 'column nowrap');
    overflow: auto;
    max-height: 65.5vh;
    .form {
      display: flex;
      flex-direction: column;
      padding: 1.5em 2em;
      border: 2px solid #DEDEE9;
      box-sizing: border-box;
      border-radius: 6px;
      h3 {
        display: flex;
        align-items: center;
        // gap: min(0.5em, 1vw);
        @include flex-gap(.5em, 'row nowrap');
        span {
          &.home {
            color: $button-color-aqua;
          }
          &.life {
            color: #654BE4;
          }
          &.auto {
            color: $main-blue;
          }
        }
      }
      .section {
        margin: 1em 0;
        span {
          display: inline-block;
          font-weight: 700;
          font-size: 1.2em;
          margin: .5em 0;
        }
        .inputs {
          display: flex;
          align-items: flex-end;
          flex-wrap: wrap;
          margin: 1em 0;
          // gap: 1.5em;
          @include flex-gap(1.5em, 'row wrap');
          > * {
            width: min(25em, 100%);
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  #agent-quote-summary {
    width: 100%;
    overflow-x: hidden;
    .header {
      flex-direction: column;
      align-items: center;
      // gap: 1em;
      h1 {
        text-align: center;
      }
      button {
        margin-right: 0;
        //uncomment for gap
        margin-top: 1em;
      }
    }
    .forms-wrap {
      max-height: 55vh;
      
    }
  }
}

@media (max-width: 414px) {
  #agent-quote-summary {
    .forms-wrap {
      max-height: 49.5vh;
    }
  }
}
</style>
<style lang="scss">
@media (max-width: 650px) {
  #agent-quote-summary {
    .form {
      .inputs {
        label {
          width: 100%;
        }
      }
    }

    .questionnaire-wrap {
      overflow: auto;
    }

    .wizard-wrap {
      overflow: auto;
      margin-bottom: 0;
    }
  }
}

#agent-quote-summary {
  .questionnaire-wrap {
    min-height: 420px;
    overflow-y: auto;
  }
}
</style>