<template lang="pug">
.notifications-wrap(
  :class="{ 'top-offset' : isMobileMixin_isMobile }"
)
  confirm-box(
    ref="confirmBox"
  )
  profile-navigation(
    v-if="isMobileMixin_isMobile"
  )
  .header
    .search-wrap
      h1 {{ $t('sidebarMenus.notifications') }}
      //- should go to burger
      //- input.search(
      //-   v-if="!isMobileMixin_isMobile"
      //-   :placeholder="$t('profile.search') + '...'"
      //- )
    .profile-notification-wrap
      notification-box
      profile-icon-box(
        v-if="!isMobileMixin_isMobile"
      )
  .button-line(
    v-if="notifications.length > 0"
  )
    base-button(
      :label="$t('buttons.markAllAsRead')"
      :disabled="loading || areAllNtfsRead"
      :loading="loading"
      noFillColor
      @click="markAllAsRead"
    )
    base-button(
      :label="$t('buttons.deleteAllNotifications')"
      :disabled="notifications.length === 0"
      :loading="loading"
      noFillColor
      @click="onDelAllNotifications"
    )
  .notifications(
    v-if="notifications.length > 0"
  )
    profile-notification-block(
      v-for="(notification, index) in notifications"
      :key="notification.id"
      :notification="notification"
      :isLast="index === notifications.length - 1"
      @del-notif="delNotification(notification)"
    )
  .no-data(
    v-else
  )
    not-found(
      :message="$t('notifications.noNotifications')"
    )
  //- pagination(
  //-   :pageCount="pageCount"
  //-   :pageNumber="pageNumber"
  //-   @page-change="pageNumber = $event"
  //- )
</template>

<script>
import Modal from '@/components/Modal';
import ProfileNotificationBlock from '@/components/profile/ProfileNotificationBlock';
import ProfileNavigation from '@/components/profile/ProfileNavigation';
import NotificationBox from "@/components/profile/NotificationBox.vue";
import ProfileIconBox from "@/components/profile/ProfileIconBox.vue";
import { mapActions, mapState } from 'vuex';
import Pagination from '@/components/Pagination';
import ConfirmBox from "@/components/ConfirmBox.vue";
import NotFound from '@/components/NotFound';

export default {
  components: {
    Modal,
    ProfileNotificationBlock,
    ProfileNavigation,
    NotificationBox,
    ProfileIconBox,
    ConfirmBox,
    NotFound,
    Pagination,
  },
  data () {
    return {
      loading: false,
      pageNumber: 1,
      pageCount: 10,
      limit: 10,
    };
  },
  computed: {
    ...mapState('notificationsStore', ['notifications']),
    areAllNtfsRead () {
      return this.notifications.every(ntf => ntf.ntf_seen);
    },
  },
  mounted () {
    this.init();
  },
  methods: {
    ...mapActions('notificationsStore', ['getNotifications']),
    async init () {
      this.startLoading();
      await this.getNotifications();
      this.stopLoading();
    },
    async markAllAsRead () {
      this.loading = true;
      const apiObj = {
        api: this.globalMixin_api.markAllNtfsAsRead,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        await this.getNotifications();
      }
      
      this.loading = false;
    },
    async delNotification (ntf) {
      await this.$refs.confirmBox.show({
        message: this.$t("profile.deleteNotificationConfirm"),
        callback: async () => {
          const apiObj = {
            api: this.globalMixin_api.delNotification,
            params: {
              id: ntf.ntf_id,
            },
          };

          await this.globalMixin_apiCall(apiObj);
          await this.getNotifications();
        },
      });
    },
    async onDelAllNotifications () {
      await this.$refs.confirmBox.show({
        message: this.$t("profile.deleteAllNotificationsConfirm"),
        callback: this.delAllNotifications,
      });
    },
    async delAllNotifications () {
      const apiObj = {
        api: this.globalMixin_api.delAllNotifications,
      };

      await this.globalMixin_apiCall(apiObj);
      await this.getNotifications();
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications-wrap {
  height: 100%;
  h1 {
    margin: 0;
    font-size: 1.8em;
    white-space: nowrap;
  }
  display: flex;
  flex-direction: column;
  // gap: 1em 0;
  @include flex-gap(1em, 'column nowrap');
  &.top-offset {
    padding-top: $nav-height;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 3vw;
    @include flex-gap(3vw, 'row nowrap');
    .search-wrap {
      display: flex;
      gap: 1em;
      align-items: center;
      flex-grow: 1;
    }
    .profile-notification-wrap {
      display: flex;
      gap: 1em;
      .notifications-wrap {
        margin-right: 1em;
      }
    }
  }
  .notifications {
    display: flex;
    flex-direction: column;
    // border: 1px solid #E9E9E9;
    border-radius: 10px;
    overflow: hidden;
    // gap: .5em;
    @include flex-gap(0.5em, 'column nowrap');
  }
  .button-line {
    display: flex;
    justify-content: space-between;
    margin-left: .4em;
  }
}

@media (min-width: 769px) {}
</style>