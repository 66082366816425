<template>
  <div id="agent-leads" :class="{ 'top-offset': isMobileMixin_isMobile }">
    <profile-navigation v-if="isMobileMixin_isMobile"></profile-navigation>
    <modal
      v-if="selectedLead"
      background="#F7F8FBF0"
      @close-modal="selectedLead = null"
    >
      <agent-lead-summary :lead="selectedLead"></agent-lead-summary>
    </modal>
    <modal
      v-if="user"
      background="#F7F8FBF0"
      @close-modal="user = null"
    >
      <agent-user-info-modal :data="user" @close-modal="user = null"></agent-user-info-modal>
    </modal>
    <modal v-if="acceptBid" background="#F7F8FBF0" @close-modal="acceptBid = false">
      <notice-box
        :message="$t('agentProfile.sureToProceed')"
        :buttonLabel="$t('buttons.ok')"
        :buttonCallback="() => $router.push({ name: 'PaymentPage', params: { leadId: redirectLead.aps_id } })"
      ></notice-box>
    </modal>
    <modal
      v-if="isViableIncEmpty"
      background="#F7F8FBF0"
      @close-modal="isViableIncEmpty = false"
    >
      <!-- @close-modal="$router.push({ name: 'AgentAgencyDetails' })" -->
      <notice-box
        :icon="globalMixin_images.exclamationRoundRed"
        :message="$t('agentProfile.allCarriersQuoted')"
        :buttonLabel="$t('buttons.ok')"
        :buttonCallback="() => isViableIncEmpty = false"
      ></notice-box>
    </modal>
    <div class="header">
      <div class="search-wrap">
        <h1>{{ $t("agentProfile.leads") }}</h1>
        <!-- <input
          class="search"
          v-if="!isMobileMixin_isMobile"
          :placeholder="$t('profile.search') + '...'"
        /> -->
      </div>
      <div class="profile-notification-wrap">
        <div class="filter" @click="filterExpanded=!filterExpanded">
          <span>{{ $t("agentProfile.filterLeads") }}</span
          ><img :src="globalMixin_images.arrowDownBlue" />
          <div v-if="filterExpanded" class="filter-dropdown">
            <div
              class="filter-option"
              v-for="filterOption in filterOptions"
              :key="filterOption.id"
              @click="selectedFilter = filterOption.name"
            >
              {{ filterOption.label }}
            </div>
          </div>
        </div>
        <notification-box></notification-box>
        <profile-icon-box v-if="!isMobileMixin_isMobile"></profile-icon-box>
      </div>
    </div>
    <transition-group tag="div" name="list-fade" appear class="leads-wrap" v-if="!showErrorModal && leads.length > 0">
      <agent-lead-card
        v-for="lead in leads"
        :key="lead.aps_id"
        :lead="lead"
        @set-selected-lead="setSelectedLead"
        @show-client-info="onShowClientInfo"
        @edit-quote="onEditQuote"
      ></agent-lead-card>
    </transition-group>
    <div 
      v-if="showErrorModal"
      class="error-modal-wrap"
    >
      <NoticeBox 
        :buttonLabel="$t('buttons.goToAgencyDetails')"
        :icon="globalMixin_images.exclamationRoundRed"
        :message="$t('noticeBox.insufficientInfoFilled')"
        :buttonCallback="navigateToAgencyDetails"
      />
    </div>
    <div class="no-data" v-if="leads.length === 0 && isFinishedLoadingData && !showErrorModal">
      <NotFound 
        :message="initalLeads === 0 ? $t('agentProfile.noLeads') : $t('agentProfile.noLeadsInCat')"
      />
    </div>
    <Pagination
      v-if="isFinishedLoadingData && pageCount > 1"
      :pageCount="pageCount"
      :pageNumber="pageNumber"
      :loading="paginationLoading"
      @page-change="pageNumber = $event"
    /> 
  </div>
</template>

<script>
import ProfileNavigation from "@/components/profile/ProfileNavigation";
import Modal from "@/components/Modal.vue";
import NotificationBox from "@/components/profile/NotificationBox.vue";
import ProfileIconBox from "@/components/profile/ProfileIconBox.vue";
import AgentLeadCard from "@/components/profile/leads/AgentLeadCard.vue";
import AgentLeadSummary from "@/components/profile/leads/AgentLeadSummary.vue";
import AgentUserInfoModal from "@/components/profile/leads/AgentUserInfoModal.vue";
import NoticeBox from '@/components/NoticeBox';
import NotFound from '@/components/NotFound';
import Pagination from '@/components/Pagination';
import api from '@/api';

export default {
  components: {
    ProfileNavigation,
    Modal,
    NotificationBox,
    ProfileIconBox,
    AgentLeadCard,
    AgentLeadSummary,
    AgentUserInfoModal,
    NoticeBox,
    NotFound,
    Pagination,
  },
  data () {
    return {
      leads: [],
      isFinishedLoadingData: false,
      selectedLead: null,
      redirectLead: null,
      isQuoting: false,
      selectedCompany: null,
      showErrorModal: false,
      card: null,
      filterExpanded: false,
      filterOptions: [
        {id: 0, label:'Show all', name:'all'},
        {id: 1, label:'New', name:'new'},
        {id: 2, label:'Quoted', name:'quoted'},
        {id: 3, label:'Won', name:'won'},
        {id: 4, label:'Completed', name:'completed'},
      ],
      selectedFilter: 'all',
      user: null,
      acceptBid: false,
      isViableIncEmpty: false,

      pageNumber: 1,
      pageCount: 0,
      limit: 12,
      paginationLoading: false,
      initalLeads: 0,
    };
  },
  watch: {
    pageNumber: {
      async handler () {
        this.paginationLoading = true;
        await this.getLeads();
        this.paginationLoading = false;
      },
    },
    selectedFilter: {
      async handler () {
        await this.init();
      },
    },
  },
  mounted () {
    this.init();
    this.getInitialLeads();
  },
  methods: {
    async init () {
      this.startLoading();
      this.pageNumber = 1;
      await this.getLeads();
      this.stopLoading();
    },
    async getLeads () {
      try {
        const params = {
          limit: this.limit,
          offset: (this.pageNumber - 1) * this.limit,
          filter: this.selectedFilter !== 'all'? this.selectedFilter : null,
        };
        const res = await api.getAgentLeads(params);
        this.leads = res.data.data;
        const numOfLeads = res.data.count;
        this.pageCount = Math.ceil(numOfLeads / this.limit);
      } catch (error) {
        if(error && error.response && error.response.status == 400) {
          this.showErrorModal = true;
        }
      }

      this.isFinishedLoadingData = true;
    },
    async onShowClientInfo (lead) {
      this.startLoading();
      this.redirectLead = lead;
      if (lead.aps_confirmed) await this.showClientInfo(lead.aps_id);
      else {
        this.acceptBid=true;
        // this.$router.push({ name: 'PaymentPage', params: { leadId: lead.aps_id } });
      }
      this.stopLoading();
    },
    async showClientInfo (aps_id) {
      const apiObj = {
        api: this.globalMixin_api.showClientInfo,
        params: {
          id: aps_id,
        },
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.user = data.user_data;
      }
    },
    navigateToAgencyDetails () {
      this.$router.push({ name: 'AgentAgencyDetails' });
    },
    setSelectedLead (lead) {
      if (lead.viable_inc && lead.viable_inc.length === 0) {
        this.isViableIncEmpty = true;
        return;
      }
      
      this.selectedLead = lead;
    },
    onEditQuote () {

    },
    async getInitialLeads () {
      await this.init();
      this.initalLeads = this.leads.length;
    },
  },
};
</script>

<style lang="scss" scoped>
#agent-leads {
  display: flex;
  flex-direction: column;
  height: 100%;
  // gap: 1em;
  @include flex-gap(2em, 'column nowrap');
  &.top-offset {
    padding-top: $nav-height;
  }
  h1 {
    margin: 0;
    font-size: 1.8em;
    white-space: nowrap;
  }
  h3 {
    margin: 0;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .profile-notification-wrap {
      display: flex;
      align-items: center;
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
      .filter {
        display: flex;
        align-items: center;
        // gap: 0 0.5em;
        @include flex-gap(.5em, 'row nowrap');
        margin-right: .5em;
        padding: 1em;
        height: 80%;
        border-radius: 6px;
        color: $main-blue;
        font-weight: 600;
        background: #3e94ff26;
        cursor: pointer;
        position: relative;
        span {
          font-size: 0.8em;
          white-space: nowrap;
        }
        .filter-dropdown {
          width: 100%;
          position: absolute;
          top: 100%;
          left: 0;
          background-color: #ffffff;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          border: 1px solid #3e94ff26;

          display: flex;
          flex-direction: column;

          z-index: 1;

          .filter-option {
            padding: 10px ;
            transition: .2s;

            &:hover {
              background-color: #3e94ff26;
            }
          }
        }

      }
    }
  }
  .leads-wrap {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    // gap: 1em 1em;
    max-width: 100%;
    @include flex-gap(1em, 'row wrap');
  }
  .error-modal-wrap {
    width: max(50%, 20em);
    margin: 0 auto;
  }
}

@media (min-width: 950px) {
  #agent-leads {
    .leads-wrap {
      > * {
        // uncomment for gap
        // flex-basis: calc(50% - .5em);
        flex-basis: calc(50% - 1em);
      }
    }
  }
}
</style>
<style lang="scss">
#agent-leads {
  .modal-wrap {
    .form-wrap {
      max-width: 75%;
      min-width: 320px;
    }
  }
}
</style>