<template lang="pug">
.profile-nav-wrap(
  :class="{ 'is-mobile': isMobileMixin_isMobile }"
)
  img(
    v-if="isMobileMixin_isMobile"
    :src="globalMixin_images.backArrowLeft"
    alt="back-arrow-left.svg"
    @click="goBack"
  )
  img.logo(
    :src="globalMixin_images.logo"
    alt="logo-white.svg"
    @click="$router.push('/')"
  )
  profile-burger
</template>

<script>
import ProfileBurger from '@/components/profile/ProfileBurger';
export default {
  components: {
    ProfileBurger,
  },
  methods: {
    goBack () {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
$padding: clamp(1em, 3vw, 3em);
.profile-nav-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  margin: 0 !important;
  right: calc(#{$padding} / 100);
  height: $nav-height;
  background-image: linear-gradient(to right, #3E94FF, #886CF5);
  padding: 1em;
  z-index: 3;
  top: 0;
  .logo {
    max-height: 80%;
  }
  &.is-mobile {
    height: $nav-height;
    justify-content: space-between;;
  }
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .profile-nav-wrap {
    right: calc(3vw / 100);
  }
}}
</style>