<template lang="pug">
.notice-wrap
  .content
    img(
      v-if="icon"
      :src="icon"
      alt="notice-icon"
    )
    p {{ message }}
    base-button(
      v-if="buttonLabel && buttonCallback"
      :label="buttonLabel"
      @click="buttonCallback"
    )
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    message: {
      type: String,
    },
    buttonLabel: {
      type: String,
    },
    buttonCallback: {
      type: Function,
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-wrap {
  padding: 3vw 4vw;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  width: 100%;
  max-width: 50em;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    // gap: 2vw;
    @include flex-gap (3vw, 'column nowrap');
    color: #546882;
    p{ 
      text-align: center;
    }
    img {
      width: 6em;
    }
  }
}
</style>