import { createRouter, createWebHistory } from "vue-router";
import api from "@/api";

import Landing from "../views/Landing.vue";
import AgentLanding from "../views/AgentLanding.vue";
import PaymentPage from "../views/PaymentPage.vue";

import Auth from "../views/Auth/Auth.vue";
import Register from "../views/Auth/user/Register.vue";

import ProfileNotifications from "../views/ProfileNotifications";

import UserProfile from "../views/user-profile/UserProfile.vue";
import UserDashboard from "../views/user-profile/UserDashboard.vue";
import UserApplication from "../views/user-profile/UserApplication.vue";
import UserQuotes from "../views/user-profile/UserQuotes.vue";

import AgentProfile from "../views/agent-profile/AgentProfile.vue";
import AgentAgencyDetails from "../views/agent-profile/AgentAgencyDetails.vue";
import AgentLeadsWrapper from "../views/agent-profile/AgentLeadsWrapper.vue";
import AgentLeadQuote from "../views/agent-profile/AgentLeadQuote.vue";
import AgentLeads from "../views/agent-profile/AgentLeads.vue";

import Verify from "../views/Auth/Verify.vue";
import ResetPassword from "../views/Auth/ResetPassword.vue";


const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/agent",
    name: "AgentLanding",
    component: AgentLanding,
  },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
    children: [
      {
        //:type = insurance type ('home', 'auto', 'life')
        path: "register/:insuranceTypeId",
        name: "Register",
        component: Register,
      },
    ],
  },
  {
    path: "/profile",
    redirect: '/profile/dashboard',
    name: "UserProfile",
    component: UserProfile,
    meta: {
      requiresLogin: true,
    },
    children: [
      {
        path: "dashboard",
        name: "UserDashboard",
        component: UserDashboard,
      },
      {
        path: "application",
        name: "UserApplication",
        component: UserApplication,
      },
      {
        path: "my-quotes",
        name: "UserQuotes",
        component: UserQuotes,
      },
      {
        path: "notifications",
        name: "UserNotifications",
        component: ProfileNotifications,
      }
    ],
  },
  {
    path: "/agent/profile",
    redirect: '/agent/profile/agency-details',
    name: "AgentProfile",
    component: AgentProfile,
    meta: {
      requiresLogin: true,
    },
    children: [
      {
        path: "agency-details",
        name: "AgentAgencyDetails",
        component: AgentAgencyDetails,
      },
      {
        path: "leads",
        name: "AgentLeadsWrapper",
        redirect: "/agent/profile/leads/all",
        component: AgentLeadsWrapper,
        children: [
          {
            path: "all",
            name: "AgentLeads",
            component: AgentLeads,
          },
          {
            path: ":id",
            name: "AgentLeadQuote",
            component: AgentLeadQuote,
          },
        ],
      },
      {
        path: "notifications",
        name: "AgentNotifications",
        component: ProfileNotifications,
      }
    ],
  },
  {
    path: "/payment",
    name: "PaymentPage",
    component: PaymentPage,
    meta: {
      requiresLogin: true,
    },
    beforeEnter: (to, from) => {
      if (to.params.leadId === undefined) router.push({ name: "AgentLeadsWrapper" });
    },
  },
  {
    path: '/verify',
    name: 'Verify',
    component: Verify,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    beforeEnter: (to, from) => {
      if (to.query.token === undefined) router.push({ name: "Landing" });
      else return true;
    },
  },
  {
    path: '/:catchAll(.*)',
    beforeEnter: (to, from) => {
      // return to previous valid url (can be recursive if VALID_PATH/NONVALID_PATH/NONVALID_PATH, it will run two times)
      const returnPathArr = to.fullPath.split('/');
      returnPathArr.pop();
      const returnPath = returnPathArr.join('/');

      router.push(returnPath || '/');
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from) => {
  if (to.meta.requiresLogin) {
    let isAuth;
    try {
      await api.checkSession();
      isAuth = true;
    } catch (error) {
      isAuth = false;
    }
    if(sessionStorage.getItem('to')) {
      router.push({path: sessionStorage.getItem('to')});
      sessionStorage.removeItem('to');
    }
    if (!isAuth) {
      if(!from.redirectedFrom){
        sessionStorage.setItem('to', to.path);
      }
      router.push({ name: "Landing" });
    } else {
      return true;
    }
    
  }
});

export default router;
