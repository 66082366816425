import { uniqueID } from "./helpers";
import images from '@/js/images';
import Wizard from '@/components/Wizard.vue';
import Burger from '@/components/Burger.vue';
import ProfileBurger from '@/components/profile/ProfileBurger.vue';
import api from '@/api';
import isMobileMixin from "./isMobileMixin";

import { mapState } from 'vuex';
import { mapActions } from "vuex";
export default {
  components: {
    Wizard,
    Burger,
    ProfileBurger,
  },
  mixins: [
    isMobileMixin,
  ],
  computed: {
    ...mapState(['userType']),
    globalMixin_uuid () {
      return uniqueID().getID();
    },
    globalMixin_images () {
      return images;
    },
    globalMixin_api () {
      return api;
    },
    globalMixin_dateRegex () {
      return /^(?:(?:(?:0?[13578]|1[02])(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2])(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:0?2(\/|-|\.)29\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9])|(?:1[0-2]))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    },
    globalMixin_emailRegex () {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    },
    // used by VueMask plugin
    globalMixin_phoneMask () {
      return '(###)###-####';
    },
  },
  methods: {
    ...mapActions('toastStore', ['appendToast']),
    ...mapActions('loaderStore', ['startLoading', 'stopLoading']),
    ...mapActions(['setIsLoggedIn', 'setUserType']),
    async globalMixin_apiCall ({ api, params = {} }) {
      try {
        const res = await api(params);
        return [res.data, null];
      } catch (error) {
        return [null, error];
      }
    },
    globalMixin_scrollTop () {
      window.scrollTo({ top: 0 });
    },
    globalMixin_onLogout () {
      this.globalMixin_logout();
    },
    async globalMixin_logout () {
      const apiObj = {
        api: this.globalMixin_api.logout,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);

      if (!error) {
        this.$router.push('/');
        this.setIsLoggedIn(false);

        localStorage.removeItem('sid');
      }
    },
    globalMixin_profileRedirect () {
      if (this.userType === 1) this.$router.push({ name: 'UserProfile' });
      if (this.userType === 2) this.$router.push({ name: 'AgentProfile' });
    },
    async globalMixin_recaptcha () {
      await this.$recaptchaLoaded();
      return await this.$recaptcha();
    },
  },
};