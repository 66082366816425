<template lang="pug">
#not-found-wrap
  img(
    :src="image"
    alt="not-found.png"
  )
  p(
    v-html="message"
  )
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  computed: {
    image () {
      return this.icon? this.icon : this.globalMixin_images.notFound;
    },
  },
};
</script>

<style lang="scss">
#not-found-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // gap: 1em;
  margin: 0 1em;
  p {
    color: #434961;
    font-size: 1.4em;
    font-weight: 600;
    text-align: center;
    max-width: 25em;
    span {
      font-size: 1em;
    }
  }
}
</style>