<template lang="pug">
.life-calculation
  h2 {{ $t('profile.lifeInsBreakdown') }}
  .form
    base-input(
      v-for="(data, index) in otherData"
      :key="index"
      disabled
      v-model="data.data"
      :label="data.label"
    )
  h2 {{ $t('profile.totalLifeNeeded')}}
  .form
    base-input.life-coverage-button(
      disabled
      v-model="getTotalAmount"
      :label="$t('profile.calculatedPolicyAmount')"
    )
</template>

<script>
export default {
  props: {
    totalAmount: {
      type: String,
    },
    otherData: {
      type: Array,
      default: () => [],
    },
    questions: {
      type: Array,
    },
  },
  computed: {
    getTotalAmount () {
      return this.totalAmount;
    },
  },
};
</script>

<style lang="scss" scoped>
.life-calculation {
  display: flex;
  flex-direction: column;
  // gap: 1.5em;
  @include flex-gap(2em, "column nowrap");
  border-top: 2px solid #d7dee8;
  padding: 1em 0;
  margin-top: 1em;
  h2 {
    margin: 0;
    margin-top: 1em;
    font-size: 1.2em;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    // gap: 2em 4em;
    @include flex-gap(2em, "column wrap");
  }
}

@media (min-width: 769px) {
  .life-calculation {
    .form {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      > * {
        width: calc(33% - 2.54em) !important;
      }
    }
  }
}
</style>
