<template lang="pug">
.how-make-money(
  :style="{ 'background-image': 'url(' + globalMixin_images.howMakeMoneyBackground + ')' }"
)
  //- img(
  //-   :src="globalMixin_images.howMakeMoneyBackground"
  //-   alt="money-background.svg"
  //- )
  .paragraph
    h1 {{ $t('landing.makeMoneyHeading') }}
    p(
      v-html="$t('landing.makeMoneyParagraph')"
    )
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.how-make-money {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  background-image: url('../../assets/images/greatpurple.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 18px;
  padding: 6em 2em;
  img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  .paragraph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    h1 {
      text-align: center;
      margin: 0;
      color: #FFF;
      font-size: 3.8em;
      margin-bottom: .9em;
    }
    p {
      width: 60%;
      text-align: center;
      margin: 0;
      color: #FFF;
      font-family: 'Open Sans';
      letter-spacing: .05em;
      line-height: 2em;
      span {
        font-size: 1.3em;
        color: red;
      }
    }
  }
}

@media (max-width: 1070px) {
  .how-make-money {
    padding: 3em 2em;
    .paragraph {
      h1 {
        padding: 0 .5em;
      }
      p {
        width: 100%;
      }
    }
  }
}

@media (max-width: 769px) {
  .how-make-money {
    .paragraph {
      h1 {
        font-size: 3em;
      }
    }
  }
}
</style>