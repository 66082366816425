<template lang="pug">
teleport(to="body")
  transition-group(
    class="toast-wrapper"
    tag="div"
    name="list"
  )
    toast(
      v-for="toast in toastArray"
      :key="toast.id"
      :message="toast.message"
      :type="toast.type"
      :id="toast.id"
      :timeout="toast.timeout"
    )
</template>

<script>
import Toast from './Toast.vue';
import { mapState } from 'vuex';
export default {
  components: {
    Toast,
  },
  computed: {
    ...mapState('toastStore', ['toastArray']),
  },
};
</script>

<style lang="scss" scoped>
.toast-wrapper {
  position: fixed;
  width: 18em;
  bottom: 6em;
  right: 6em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // gap: .5em;
  z-index: 9999;
}

.list-enter-active {
  transition: all .3s ease-out;
}
.list-leave-active {
  position: absolute;
  transition: all .3s ease-out;
}
.list-enter-from {
  opacity: 0;
  transform: translateY(30px);
}
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.list-move {
  transition: all .3s;
}
</style>