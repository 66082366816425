<template lang="pug">
.footer-wrap(
  :style="{ 'background-image': 'url(' + globalMixin_images.greatF + ')'}"
)
  .content-wrap
    .logo-contact
      .logo
        img(
          :src="globalMixin_images.logo"
        )
        p {{ $t('footer.getQuotes') }}
      .contact
        h1 {{ $t('footer.contact') }}
        .info 
          p 
            | Email: 
            strong questions@fairwey.com
          p 
            | Business Hours: 
            strong Mon. - Fri. 9:00am - 6:00pm
          p
            a(
              href="/terms/client.pdf"
              target="_blank"
            )
              strong {{ $t('footer.legalInfo') }}
    .get-in-touch
      h1 {{ $t('footer.getInTouch') }}
      p {{ $t('footer.getInTouchText') }}
      .form
        .input-line.two-buttons
          base-input(
            v-model.trim="name"
            :placeholder="'Name'"
            spellcheck="false"
            singleLine
            :isFormSubmitted="formMixin_checkErrors"
            @has-error="formMixin_errorHandler"
          )
          base-input(
            v-model.trim="email"
            type="email"
            :placeholder="'Email*'"
            spellcheck="false"
            singleLine
            :validatorTypes="['required', 'maxLength', 'regex']"
            :validatorData="{ regex: globalMixin_emailRegex }"
            :isFormSubmitted="formMixin_checkErrors"
            @has-error="formMixin_errorHandler"
          )
        .input-line.one-button
          base-input(
            v-model.trim="message"
            :placeholder="'Message'"
            spellcheck="false"
            singleLine
            :isFormSubmitted="formMixin_checkErrors"
            @has-error="formMixin_errorHandler"
          )
        base-button(
          label="Send message"
          :left-icon="globalMixin_images.buttonArrow"
          :disabled="loading"
          :loading="loading"
          @click="formMixin_onFormSubmit(onSubmitMessage)"
          aqua
        )
  .copyright
    | {{ $t('footer.copyright') }}
</template>

<script>
import formMixin from '@/js/formMixin';
export default {
  mixins: [
    formMixin,
  ],
  data () {
    return {
      name: '',
      email: '',
      message: '',
      loading: false,
    };
  },
  methods: {
    onSubmitMessage () {
      this.submitMessage();
    },
    async submitMessage () {
      this.loading = true;
      const token = await this.globalMixin_recaptcha();
      const apiObj = {
        api: this.globalMixin_api.postFeedback,
        params: {
          name: this.name,
          email: this.email,
          message: this.message,
          token: token,
        },
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);

      if (error) {
        console.warn(error);
        this.loading = false;
        return;
      }

      this.appendToast({ message: this.$t('footer.getInTouchThanks'), type: 'success' });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // gap: 8em 0;
  // @include flex-gap(4em, 'column nowrap');
  background: $footer-blue;
  background-repeat: no-repeat;
  color: white;
  padding-left: min(12vw, 12em);
  padding-right: min(12vw, 12em);
  padding-top: min(7vw, 7em);
  padding-bottom: min(2vw, 2em);
  .content-wrap {
    display: flex;
    width: 100%;
    > * {
      flex-basis: 100%;
      font-family: 'Poppins', sans-serif;
    }
    h1 {
      font-size: 3.8em;
      margin: 0;
      line-height: 1em;
      color: #FFF;
    }
    .logo-contact {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // gap: 4em 0;
      .logo {
        max-width: 23em;
        display: flex;
        flex-direction: column;
        // gap: 2em;
        img {
          width: 13em;
        }
      }
      p {
        line-height: 2em;
        margin: 1em 0 4em;
        // uncomment for gap
        // margin: 0
      }
      .contact {
        .info {
          display: flex;
          flex-direction: column;
          // gap: .5em;
          margin-top: 2em;
          a{
            text-decoration: none;
            color: inherit;
          }
          // comment for gap
          p {
            margin: 0;
          }
        }
      }
    }
    .get-in-touch {
      line-height: 2em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .form {
        display: flex;
        flex-direction: column;
        // gap: 2em;
        @include flex-gap(2em, 'column nowrap');
        .input-line {
          display: flex;
          // gap: 2em 4em;
          @include flex-gap(4em, 'row nowrap');
          &.one-button {
            > * {
              width: 100%;
            }
          }
          &.two-buttons {
            > * {
              width: calc(50% - 2em);
            }
          }
        }
        button {
          margin-top: 1em;
        }
      }
      p {
        margin: 0;
      }
    }
    .get-in-touch-thx {
      line-height: 2em;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
    }
  }
  .copyright {
    width: 100%;
    text-align: center;
    font-size: .7em;
    padding: 1.5em 0;
    border-top: 1px solid rgba(255, 255, 255, .15);
    // uncomment for gap
    margin-top: 8em;
  }
}
@media (max-width: 1070px) {
  .footer-wrap {
    padding: 4em;
    padding-bottom: 2em;
    // gap: 4em;
  }
}

@media (max-width: 769px) {
  .footer-wrap {
    h1 {
      font-size: 3em !important;
    }
    .content-wrap {
      align-items: center;
      flex-direction: column-reverse;
      // gap: 3em;
      @include flex-gap(3em, 'column-reverse nowrap');
      .logo-contact {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        .logo {
          img {
            width: 10em;
          }
        }
      }
      .get-in-touch {
        h1 {
          margin-bottom: 2.5rem;
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .footer-wrap {
    padding-left: 2em;
    padding-right: 2em;
    .content-wrap {
      .logo-contact {
        flex-direction: column;
        align-items: center;
        .logo,
        .contact {
          width: 100%;
          max-width: none;
        }
      }
      .get-in-touch {
        width: 100%;
        .form {
          .input-line {
            &.one-button {
              > * {
                width: 100%;
              }
            }
            &.two-buttons {
              flex-wrap: wrap;
              > * {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>