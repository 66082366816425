<template lang="pug">
.notification-wrap
  .icon-wrap(
    @click.stop="showNotifications = !showNotifications"
  )
    img(
      :src="globalMixin_images.bell"
    )
    .notification-circle(
      v-if="isAnyNtfUnread"
    )
  transition(
    name="fade"
  )
    .notifications(
      v-if="showNotifications"
      @click.stop="showNotifications = true"
    )
      .notification(
        v-for="(notification, index) in lastThreeNotifications"
        :class="{ 'new' : !notification.ntf_seen }"
        @click="markAsRead(notification)"
      )
        .heading
          .name {{ notification.ntp_name }}
          .time {{ formatDate(notification.ntf_date) }}
        p.text(
          :class="{ 'has-border' : index !== notifications.length - 1 && notification.ntf_seen }"
          v-html="$t(`notifications.code${notification.ntp_id}`, { firstName: notification.usr_firstname })"
        )
      .see-all
        span(
          @click.stop="onNotificationsRedirect"
        ) 
          | {{ $t('notifications.seeAll') }}
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
export default {
  data () {
    return {
      showNotifications: false,
    };
  },
  computed: {
    ...mapState('notificationsStore', ['notifications']),
    lastThreeNotifications () {
      return this.notifications.slice(0, 3);
    },
    isAnyNtfUnread () {
      return this.notifications.some(ntf => !ntf.ntf_seen);
    },
  },
  // TODO add realtime new notification check
  // watch: {
  //   notifications: {
  //     deep: true,
  //     handler () {
        
  //     },
  //   },
  // },
  mounted () {
    document.addEventListener('click', () => this.showNotifications = false);
  },
  beforeUnmount () {
    document.removeEventListener('click', () => this.showNotifications = false);
  },
  methods: {
    ...mapActions('notificationsStore', ['getNotifications']),
    async markAsRead (ntf) {
      if (!ntf.ntf_seen) {
        const apiObj = {
          api: this.globalMixin_api.markNtfAsRead,
          params: {
            id: ntf.ntf_id,
          },
        };
        
        await this.globalMixin_apiCall(apiObj);
        await this.getNotifications();
      }
    },
    async onNotificationsRedirect () {
      this.notificationsRedirect();
      this.showNotifications = false;
    },
    notificationsRedirect () {
      if (this.userType === 1) this.$router.push({ name: 'UserNotifications' });
      if (this.userType === 2) this.$router.push({ name: 'AgentNotifications' });
    },
    formatDate (date) {
      return dayjs(dayjs(date).utc(true), 'YYYY-MM-DD HH:mm:ss').tz(dayjs.tz.guess()).format('MM/DD HH:mm');
      // return dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('MM/DD HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-wrap {
  position: relative;
  user-select: none;
  .icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: $notification-size;
    min-width: $notification-size;
    max-height: $notification-size;
    max-width: $notification-size;
    background-color: #FFF;
    border: 1px solid $box-border-color;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    .notification-circle {
      position: absolute;
      top: 12px;
      right: 15px;
    }
  }
  .notifications {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: calc(#{$notification-size} + 5px);
    right: 0;
    background: #FFFFFF;
    border: 1px solid #E9E9E9;
    border-radius: 10px;
    overflow: hidden;
    z-index: 3;
    .notification {
      display: flex;
      flex-direction: column;
      padding: 1em;
      width: 20em;
      &.new {
        cursor: pointer;
        background: #F0FAF3;
      }
      .heading {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .name {
          color: #434961;
          font-weight: 600;
        }
        .time {
          font-style: italic;
          color: #999BA3
        }
      }
      .text {
        color: #6C7490;
        &.has-border {
          margin: 0;
          padding: 1em 0 2em 0;
          border-bottom: 1px solid #EDEDED;
        }
      }
    }
    .see-all {
      display: flex;
      justify-content: center;
      padding: .8em;
      background: #FAFAFA;
      span {
        color: #3E94FF;
        font-weight: 600;
        font-style: italic;
        text-decoration: underline;
        font-size: .7em;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 280px){
  .notification-wrap{
    .notifications{
      width: 250px;
      .notification{
        width: 240px;
      }
    }
  }
}
</style>