<template lang="pug">
.app-summary-wrap
  .form(
    v-for="app in application"
    :key="app.ins_id"
  )
    h3 
      img(
        :src="globalMixin_images[`${getInsType(app)}InsuranceIcon`]"
        alt="insurance-icon.svg"
      )
      span(
        :class="getInsType(app)"
      ) 
        | {{ $t(`insurances.${getInsType(app)}`) }}
    .inputs
      component(
        disabled
        v-for="question in app.questions"
        :key="question.que_id"
        v-model="question.answer"
        :is="getQuestionType(question)"
        :label="question.que_content"
        :symbol="question.que_symbol"
        :position="question.que_symbol_pos"
        :placeholder="question.que_placeholder"
        :name="`input${question.que_id}`"
        :options="question.answers !== null? question.answers : undefined"
        :labelKey="'ans_content'"
        :valueKey="'ans_id'"
      )
</template>

<script>
export default {
  data () {},
  props: {
    application: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getQuestionType (question) {
      let inputComponent;
      switch (question.que_type) {
        case 0: inputComponent = 'BaseInput'; break;
        case 1: inputComponent = 'BaseSelect'; break;
        case 2: inputComponent = 'BaseRadioGroup'; break;
        case 3: inputComponent = 'BaseCheckboxGroup'; break;
        case 4: inputComponent = 'BaseDatePicker'; break;
        case 5: inputComponent = 'BaseInput'; break;
      }
      return inputComponent;
    },
    getInsType ({ ins_id }) {
      switch (ins_id) {
        case 1: return 'home';
        case 2: return 'life';
        case 3: return 'auto';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-summary-wrap {
  $summary-input-width: min(25em, 100%); 
  display: flex;
  flex-direction: column;
  // @include flex-gap(1em, 'column nowrap');
  background-color: #FFF;
  height: max-content;
  max-height: 83vh;  
  width: 400px;
  min-width: calc(#{$summary-input-width} * 2 + 6em + 20px);
  // max-width: 100%;
  padding: 0 2em 1.3em 2em;
  border-radius: 10px;
  border: 1px solid #F3F3F3;
  // margin-top: .05em;
  overflow: auto;
  position: sticky;
  top: 2em;
  .form {
    display: flex;
    flex-direction: column;
    // padding: 1.5em 2em;
    // border-bottom: 2px solid #DEDEE9;
    box-sizing: border-box;
    border-radius: 6px;
    h3 {
      display: flex;
      align-items: center;
      // gap: min(0.5em, 1vw);
      @include flex-gap(.5em, 'row nowrap');
      span {
        &.home {
          color: $button-color-aqua;
        }
        &.life {
          color: #654BE4;
        }
        &.auto {
          color: $main-blue;
        }
      }
    }
    .inputs {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      // gap: 1.5em;
      @include flex-gap(1.5em, 'row wrap');
      > * {
        width: min(25em, 100%);
      }
    }
  }
}

@media (max-width: 1300px) {
  .app-summary-wrap {
    width: 100%;
    min-width: auto;
  }
}
</style>