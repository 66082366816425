<template lang="pug">
modal(
  v-if="isOpen"
  @close-modal="isOpen = false"
)
  .confirm-wrap
    .content
      img(
        :src="globalMixin_images.exclamationRoundRed"
        alt="exclamationRoundRed"
      )
      p(
        v-html="confirmData.message"
      )
      .button-line
        base-button(
          :label="$t('buttons.confirm')"
          @click="onCallback"
        )
        base-button(
          noFillColor
          :label="$t('buttons.back')"
          @click="onCancel"
        )
</template>

<script>
import Modal from './Modal.vue';
export default {
  components: {
    Modal,
  },
  data () {
    return {
      // example of confirmData: { message: 'Are you sure you want to delete X?', callback: function, callbackParams: [param1, param2]}
      confirmData: null,
      resolvePromise: null,
      rejectPromise: null,
      isOpen: false,
    };
  },
  methods: {
    show (confirmData) {
      this.confirmData = confirmData;
      this.isOpen = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async onCallback () {
      if (this.confirmData.callbackParams) await this.confirmData.callback(...this.confirmData.callbackParams);
      else await this.confirmData.callback();
      this.resolvePromise(true);
      this.isOpen = false;
    },
    onCancel () {
      // this.resolvePromise(false);
      this.confirmData = null;
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.confirm-wrap {
  padding: 3vw 4vw;
  background: #FFF;
  border: 1px solid #E8E8E8;
  border-radius: 5px;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    @include flex-gap(3vw, 'column nowrap');
    color: #546882;
    .button-line {
      display: flex;
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
    }
  }
}
</style>