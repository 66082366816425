export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const kebabToCamelCase = string => {
  const stringParts = string.split('-');
  return stringParts.map(word => capitalize(word)).join('');
};

let uuid = 0;
export const uniqueID = () => {
  const getID = () => {
    return uuid++;
  };

  return {
    getID,
  };
};

export const BaseAutoImporter = app => {
  const requireComponent = require.context(
    '../components/base-components',
    false,
    /Base[A-Z]\w+\.(vue|js)$/
  );

  requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);
    const componentName = capitalize(
      kebabToCamelCase(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'))
    );
    
    app.component(componentName, componentConfig.default || componentConfig);
  });
};

// example of using in vue method option
// methods: {
//   onFunc: debounce(function () {
//     this.func();
//   }),
//   func () {
//     this.value = !this.value;
//   },
// }
export const debounce = (callback, time = 500) => {
  let interval, context, args;
  return function () {
    context = this;
    args = arguments;
    const callBackWithResetInterval = () => {
      callback.apply(context, args);
      interval = null;
    };
  
    if (interval) {
      clearInterval(interval);
      interval = setTimeout(callBackWithResetInterval, time);
      return;
    }
    interval = setTimeout(callBackWithResetInterval, time);
  };
};

export const rawNumToCurrencyUS = num => {
  const formattedNum = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'symbol'}).format(num);
  return formattedNum.slice(0, formattedNum.length-3);
};