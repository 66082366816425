<template>
  <div class="burger-container" :class="{ 'dark' : dark }" @click.stop="isOpen = !isOpen"><img :src="globalMixin_images.burger" alt="burger-menu" />
    <transition name="fade">
        <div class="menu-items" v-if="isOpen" @click.stop="isOpen = true">
            <slot></slot>
        </div>
    </transition>
  </div>  
</template>

<script>
export default {
  props: {
    dark: {
      type: Boolean,
    },
  },
  data () {
    return {
      isOpen: false,
    };
  },
  watch: {
    isOpen (val) {
      if(val) {
        document.body.style.position = "fixed";
      }
      else{
        document.body.style.position = "initial";
      }
    },
  },
  created () {
    document.addEventListener('click', () => this.isOpen = false);
  },
  beforeUnmount () {
    document.removeEventListener('click', () => this.isOpen = false);
    document.body.style.position = "initial";
  },
};
</script>

<style lang="scss" scoped>
.burger-container {
  // position: relative;
  // height: 40px;
  // width: 40px;
  &.dark {
    > img {
      filter: brightness(0.5);
    }
  }
  .menu-items {
    position: fixed;
    // height: 90vh;
    top: 68px;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
  }
}
</style>