<template lang="pug">
.agency-details-wrap(
  :class="{ 'top-offset' : isMobileMixin_isMobile }"
)
  confirm-box(
    ref="confirmBox"
  )
  profile-navigation(
    v-if="isMobileMixin_isMobile"
  )
  modal(
    v-if="showModal"
    background="#F7F8FBF0"
    @close-modal="showModal = false"
  )
    agent-agency-details-add-new-carrier(
      @add-carrier="onPostNewCarrier"
      @close-modal="showModal = false"  
    )
  modal(
    v-if="showModal2"
    @close-modal="closeModal2"
  )
    notice-box(
      :icon="globalMixin_images.exclamationRound"
      :message="$t('agentProfile.detailsWarn')"
      :buttonLabel="$t('buttons.ok')"
      :buttonCallback="closeModal2"
    )
  .header
    .search-wrap
      h1 {{ $t('agentProfile.profileDetails') }}
      //- input.search(
      //-   v-if="!isMobileMixin_isMobile"
      //-   :placeholder="$t('profile.search') + '...'"
      //- )
    .profile-notification-wrap
      notification-box
      profile-icon-box(
        v-if="!isMobileMixin_isMobile"
      )
  .carriers-wrap
    h3 {{ $t('agentProfile.yourCarriers') }}
    .carriers
      .carrier(
        v-for="carrier in form.carriers"
        :key="carrier.inc_id" 
      )  
        img(
          :src="globalMixin_images.agencyIllustration"
        )
        .del-btn(
          @click="onDelCarrier(carrier.inc_id)"
        )
          img(
            :src="globalMixin_images.cancelWhite"
          )
        span.name {{ carrier.inc_name }}
      .new-carrier(
        @click="onAddNewCarrier"
      )
        .icon-wrap
          img(
            :src="globalMixin_images.plusRound"
            alt="plus-round.svg"
          )
          span {{ $t('agentProfile.addNewCarrier') }}
  .forms
    .agency-info
      h3 {{ $t('agentProfile.agencyContactInfo') }}
      .inputs-wrap
        base-input(
          v-model.trim="form.usr_agencyname"
          :disabled="isLocked"
          :label="$t('form.agencyName')"
          :placeholder="$t('form.agencyName')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="form.usr_agencyaddress"
          :disabled="isLocked"
          :label="$t('form.address')"
          :placeholder="$t('form.address')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="form.usr_agencyemail"
          type="email"
          :disabled="isLocked"
          :label="$t('form.agencyEmail')"
          :placeholder="$t('form.agencyEmail')"
          :validatorTypes="['required', 'maxLength', 'regex']"
          :validatorData="{ regex: globalMixin_emailRegex }"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="form.usr_agencynumber"
          v-mask="globalMixin_phoneMask"
          :disabled="isLocked"
          :label="$t('form.agencyPhone')"
          :placeholder="$t('form.agencyPhone')"
          :isFormSubmitted="formMixin_checkErrors"
          autocomplete="off"
          :validatorTypes="['phoneNum', 'required', 'maxLength']"
          @has-error="formMixin_errorHandler"
        )
          //- :validatorData="{ regex: /^\\+\\d+$/ }"
    .rep-info
      h3 {{ $t('agentProfile.representativeInfo') }}
      .inputs-wrap
        base-input(
          v-model.trim="form.usr_firstname"
          :disabled="isLocked"
          :label="$t('form.agentName')"
          :placeholder="$t('form.agentName')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="form.usr_lastname"
          :disabled="isLocked"
          :label="$t('form.agentLastname')"
          :placeholder="$t('form.agentLastname')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="form.usr_password"
          type="password"
          :label="$t('form.newPassword')"
          :disabled="isLocked"
          :placeholder="$t('form.newPassword')"
          :validatorTypes="[]"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="form.usr_confirmNewPassword"
          type="password"
          :label="$t('form.confirmNewPassword')"
          :disabled="isLocked"
          :placeholder="$t('form.confirmNewPassword')"
          :isFormSubmitted="formMixin_checkErrors"
          :validatorTypes="['confirmPassword']"
          :validatorData="{ confirmPassword: form.usr_password }"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="form.usr_email"
          type="email"
          :disabled="isLocked"
          :label="$t('form.email')"
          :placeholder="$t('form.emailPlaceholder')"
          :validatorTypes="['required', 'maxLength', 'regex']"
          :validatorData="{ regex: globalMixin_emailRegex }"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="form.usr_phonenumber"
          v-mask="globalMixin_phoneMask"
          autocomplete="off"
          :disabled="isLocked"
          :label="$t('form.phone')"
          :placeholder="$t('form.phonePlaceholder')"
          :isFormSubmitted="formMixin_checkErrors"
          :validatorTypes="[ 'required', 'phoneNum', 'maxLength' ]"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="form.usr_insurancelicensenumber"
          :disabled="isLocked"
          :label="$t('form.insLicenceNum')"
          :placeholder="$t('form.insLicenceNum')"
          :validatorTypes="['number', 'required', 'maxLength']"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        .checkbox-wrap-agency(
          v-if="form.usr_emailnotifications !== undefined"
        )
          base-checkbox(
            v-model="form.usr_emailnotifications"
            :disabled="isLocked"
            :label="$t('form.receiveNotifications')"
            :isFormSubmitted="formMixin_checkErrors"
            @has-error="formMixin_errorHandler"
          )
        base-type-dropdown(
          v-if="allZipCodes.length > 0"
          :modelValue="form.zipcodes"
          :options="allZipCodes"
          multiple
          trackBy="zip_id"
          showBy="zip_code"
          :disabled="isLocked"
          :label="$t('form.prefZipCode')"
          :placeholder="$t('form.prefZipCode')"
          :isFormSubmitted="formMixin_checkErrors"
          @update:modelValue="setZipCodes"
          @has-error="formMixin_errorHandler"
        )
      .button-wrap
        base-button(
          v-if="isLocked"
          :label="$t('buttons.clickToEdit')"
          @click="unlock"
        )
        base-button(
          v-else
          :label="$t('buttons.updateChanges')"
          :disabled="loading"
          :loading="loading"
          @click="formMixin_onFormSubmit(submitForm)"
        )
    span(
      @click="onDeleteAccount"
      class="delete-acc"
    )
      | {{ $t('buttons.deleteAccount') }}
</template>

<script>
import ProfileNavigation from "@/components/profile/ProfileNavigation";
import Modal from "@/components/Modal.vue";
import AgentAgencyDetailsAddNewCarrier from "@/components/profile/agency-details/AgentAgencyDetailsAddNewCarrier.vue";
import NotificationBox from "@/components/profile/NotificationBox.vue";
import NoticeBox from '@/components/NoticeBox.vue';
import ProfileIconBox from "@/components/profile/ProfileIconBox.vue";
import formMixin from "@/js/formMixin.js";
import ConfirmBox from "@/components/ConfirmBox.vue";
import { mapActions } from 'vuex';

export default {
  mixins: [
    formMixin,
  ],
  components: {
    ProfileNavigation,
    Modal,
    AgentAgencyDetailsAddNewCarrier,
    NotificationBox,
    NoticeBox,
    ProfileIconBox,
    ConfirmBox,
  },
  data () {
    return {
      carriers: [],
      allZipCodes: [],
      form: {},
      lastZipCodesUsed: null,
      hasAllZipCodes: null,
      oldForm: {},
      showModal: false,
      showModal2: false,
      isLocked: true,
      selectedCarrier: null,
      selectedProfile: null,
      usr_password: '',
      usr_confirmNewPassword: '',
      loading: false,
    };
  },
  computed: {
    all_zipcode_obj () {
      return {
        zip_id: 0,
        zip_code: this.$t('agentProfile.all'),
      };
    },
  },
  watch: {
    form: function () {
      this.checkCarriers();
    },
  },
  mounted () {
    this.init();
  },
  methods: {
    ...mapActions(['setHasCarriers']),
    async init () {
      this.startLoading();
      await this.getAgentProfile();
      await this.getZipCodes();
      this.checkCarriers();
      this.stopLoading();
    },
    async getAgentProfile () {
      const apiObj = {
        api: this.globalMixin_api.getAgentProfile,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (error) {
        this.appendToast({ message: error.message, timeout: 5000 });
        return;
      }

      this.form = data.data;
      this.form.usr_password = '';
      this.form.usr_confirmNewPassword = '';
      this.oldForm = JSON.parse(JSON.stringify(this.form));
      this.hasAllZipCodes = !!this.form.usr_all_zipcodes;
      if (this.hasAllZipCodes) this.setZipCodes([this.all_zipcode_obj]);
    },
    async getZipCodes () {
      const apiObj = {
        api: this.globalMixin_api.getZipCodes,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.allZipCodes = data.data;
        this.allZipCodes.unshift(this.all_zipcode_obj);
      }
    },
    async submitForm () {
      this.loading = true;
      const changedFormFields = {};
      for (const key in this.form) {
        if (this.form[key] !== this.oldForm[key]) {
          changedFormFields[key] = this.form[key];
        }
      }

      delete changedFormFields.carriers;
      if (this.form.usr_all_zipcodes) delete changedFormFields.zipcodes;

      const apiObj = {
        api: this.globalMixin_api.putAgentProfile,
        params: changedFormFields,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.appendToast({ message: this.$t('profile.editSuccess'), type: "success" });
        this.getAgentProfile();
      }
      this.loading = false;
    },
    onAddNewCarrier () {
      this.addNewCarrier();
    },
    addNewCarrier () {
      this.showModal = true;
    },
    async onPostNewCarrier (selectedCarrier) {
      await this.postNewCarrier(selectedCarrier);
    },
    async postNewCarrier (selectedCarrier) {
      const apiObj = {
        api: this.globalMixin_api.postAgentCarriers,
        params: {
          carriers: [selectedCarrier],
        },
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);

      if (!error) {
        this.appendToast({ message: this.$t('agentProfile.carrierAdded'), type: "success" });
        this.getAgentProfile();
      } else {
        this.appendToast({ message: error });
      }
    },
    async onDelCarrier (selectedCarrier) {
      this.selectedCarrier = selectedCarrier;
      await this.$refs.confirmBox.show({
        message: this.$t("agentProfile.areYouSureDeleteCarrier"),
        callback: this.delCarrier,
      });
    },
    async delCarrier () {
      const apiObj = {
        api: this.globalMixin_api.delAgentCarrier,
        params: {
          carriers: this.selectedCarrier,
        },
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);

      if (!error) {
        this.appendToast({ message: this.$t('agentProfile.carrierDeleted'), type: "success" });
        this.getAgentProfile();
      } else {
        this.appendToast({ message: error });
      }
    },
    async deleteAccount () {
      const apiObj = {
        api: this.globalMixin_api.deleteAgentProfile,
      };
      const [data, error] = await this.globalMixin_apiCall(apiObj);
      this.$router.push({ name: "Landing" });
      this.globalMixin_onLogout();
    },
    async onDeleteAccount () {
      await this.$refs.confirmBox.show({
        message: this.$t('agentProfile.deleteAccount'),
        callback: this.deleteAccount,
      });
    },
    setZipCodes (newVal) {
      newVal = JSON.parse(JSON.stringify(newVal));
      const hasAllZipCodes = newVal.some(zipObj => zipObj.zip_id === 0);
      if (hasAllZipCodes) {
        this.lastZipCodesUsed = newVal.filter(zipObj => zipObj.zip_id !== 0);
        this.form.zipcodes = [this.all_zipcode_obj];
        this.hasAllZipCodes = true;
      } else if (!hasAllZipCodes && this.hasAllZipCodes) {
        this.form.zipcodes = this.lastZipCodesUsed;
        this.hasAllZipCodes = false;
      } else {
        this.form.zipcodes = newVal;
      }

      this.form.usr_all_zipcodes = this.form.zipcodes.some(zipObj => zipObj.zip_id === 0) ? 1 : 0;
    },
    checkCarriers () {
      if(this.form.carriers.length < 1) {
        this.showModal2 = true;
        this.setHasCarriers(false);
      } else {
        this.setHasCarriers(true);
      }
    },
    closeModal2 () {
      this.showModal2 = false;
    },
    unlock () {
      this.isLocked = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.agency-details-wrap {
  display: flex;
  flex-direction: column;
  // gap: 1em 0;
  @include flex-gap(3em, 'column nowrap');
  &.top-offset {
    padding-top: $nav-height;
  }
  h1 {
    margin: 0;
    font-size: 1.8em;
    white-space: nowrap;
  }
  h3 {
    margin: 0;
    margin-bottom: 1em;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .search-wrap {
      width: 60%;
      display: flex;
      align-items: center;
      // gap: 1em 7em;
      @include flex-gap(0em, 'row nowrap');
    }
    .profile-notification-wrap {
      display: flex;
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
    }
  }
  .carriers-wrap {
    display: flex;
    flex-direction: column;
    padding: max(1em, 1.5vw);
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
    // gap: 1em;
    @include flex-gap(1em, 'column nowrap');
    .carriers {
      display: flex;
      // gap: 1.5em;
      @include flex-gap(1.5em, 'row wrap');
      flex-wrap: wrap;
      justify-content: flex-start;
      .carrier {
        width: min(100%, 287px);
        display: flex;
        flex-direction: column;
        // gap: 1em;
        // @include flex-gap(1em, 'column nowrap');
        .del-btn {
          width: 17px;
          height: 17px;
          align-self: flex-end;
          position: absolute;
          margin: 5px;
          &:hover {
            cursor: pointer;
            > {
              img {
                display: initial;
              }
            }
          }
          img {
            height: 100%;
            display: none;
          }
        }
        img {
          height: 171px;
          border-radius: 5px;
          &:hover {
            cursor: pointer;
            + {
              .del-btn {
                > {
                  img {
                    display: initial;
                  }
                }
              }
            }
          }
        }
        .name {
          color: #5f6174;
          font-weight: 600;
          font-size: 0.9em;
          margin: 12px 0;
        }
      }
      .new-carrier {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2em;
        width: 287px;
        height: 171px;
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        cursor: pointer;
        .icon-wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          // gap: 1em;
          @include flex-gap(1em, 'column nowrap');
          img {
            width: max-content;
          }
          span {
            color: #5f6174;
            font-weight: 600;
            font-size: 0.9em;
          }
        }
      }
    }
  }
  .forms {
    display: flex;
    flex-wrap: wrap;
    // gap: 1em;
    // @include flex-gap(2em, 'row wrap');
    .agency-info,
    .rep-info {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      // gap: 1em;
      // @include flex-gap(1em, 'column wrap');
      padding: max(1em, 1.5vw);
      background: #fff;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      .inputs-wrap {
        display: flex;
        flex-wrap: wrap;
        // gap: 1.5em 1.5em;
        @include flex-gap(1.5em, 'row wrap');
        > * {
          width: 100%;
        }
        .checkbox-wrap-agency {
          display: flex;
          align-items: flex-start;
        }
      }
      .button-wrap {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        margin-top: 1em;
        button {
          height: max-content;
        }
      }
    }
    .agency-info {
      margin-right: auto;
    }
    .delete-acc {
      color: $main-blue;
      font-style: italic;
      cursor: pointer;
      margin-left: auto;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

@media (min-width: 769px) {
  .agency-details-wrap {
    .forms {
      h3 {
        width: max-content;
      }
      .agency-info {
        flex-basis: calc(35% - 0.5em);
        .inputs-wrap {
          > * {
            width: 100%;
          }
        }
      }
      .rep-info {
        flex-basis: calc(65% - 0.5em);
        .inputs-wrap {
          > * {
            width: calc(47% - 0.75vw);
          }
        }
        .checkbox-wrap-agency {
          > div {
            padding-top: 2.7em;
          }
        }
      }
    }
  }
}
@media (max-width: 896px){
  .carriers {
    justify-content: center!important;
  }
}

</style>
