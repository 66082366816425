<template lang="pug">
.questionnaire-wrap(
  :class="{active: isActive, overflow: isLocked && !showAnswers}"
  ref="questionnaireWrap"
  @mouseover="checkIsLocked"
  @mouseleave="checkFocused"
)
  transition(
    name="fade"
  )
    .locked-overlay(
      v-if="isLocked && !showAnswers && !noOverlay"
    )
      .wrap
        img(
          :src="globalMixin_images.lock"
          alt="lock.png"
        )
        .title
          img(
            :src="globalMixin_images[`${type}InsuranceIcon`]"
            alt="insurance-icon.svg"
          )
          span(
            :class="type"
          ) 
            | {{ $t(`insurances.${type}`) }}: 
            span.questionnaire {{ $t(`profile.questionnaire`) }}
        .info
          | {{ $t('profile.selectAnotherInsurance') }}
        base-button(
          :label="$t('buttons.reveal')"
          noFillColor
          @click="showAnswers = true"
        )
  .header
    .title
      img(
        :src="globalMixin_images[`${type}InsuranceIcon`]"
        alt="insurance-icon.svg"
      )
      span(
        :class="type"
      ) 
        | {{ $t(`insurances.${type}`) }}:
      span.sec-name {{ filteredSections[activeStepIndex].name }}
    .wizard-wrapper(
      :class="{ 'one-element' : filteredSections.length === 1 }"
    )
      wizard(
        :steps="filteredSections"
        horizontal
        small
        @set-active-step="handleStep"
      )
  .section-text
    | {{ filteredSections[activeStepIndex].text }}
  .content(
    ref="content"
  )
    transition(
      :name="currentTransition"
      mode="out-in"
      @beforeLeave="classBindEnter"
      @afterEnter="classBindLeave"
    )
      insurance-questionnaire-step(
        v-if="filteredSections.length > 0"
        :key="insRerenderKey"
        :disabled="isLocked"
        :questions="activeStep.questions"
        :checkErrors="checkErrors"
        @update:questions="updateQuestionsHandler"
        @has-error="errorHandler"
        @is-form-filled="setIsFormFilled"
      )
    .buttons-line
      base-button(
        v-if="!isLastStep"
        :label="$t('buttons.continue')"
        :disabled="!isFormFilled || isLocked"
        @click="nextStep"
      )
      base-button(
        v-show="isLastStep && hasNext"
        :label="$t('buttons.next')"
        :disabled="!isFormFilled || isLocked"
        @click="nextForm"
      )
      base-button(
        v-if="isLastStep && !hasNext"
        :disabled="!isFormFilled || isLocked"
        :label="$t('buttons.submitApplication')"
        @click="submitApplication"
      )
      base-button(
        v-if="activeStepIndex - 1 >= 0"
        :label="$t('buttons.previousStep')"
        :disabled="isLocked"
        transparent
        @click="previousStep"
      )
  transition(
    name="fade"
  )
    component(
      v-if="calcDisplayCondition"
      :is="getCalculationComponent"
      :totalAmount="totalCalcAmount"
      :otherData="otherData"
      :questions="coverageQuestions"
      :atRisk="atRisk"
      :umbrella="showUmbrella"
      :totalRequired="totalRequired"
      :isInLead="isLocked"
      @send-questions="handleAutoQuestions"
    )
</template>

<script>
import InsuranceQuestionnaireStep from './components/InsuranceQuestionnaireStep.vue';
import InsuranceCalculationAuto from './components/InsuranceCalculationAuto.vue';
import InsuranceCalculationLife from './components/InsuranceCalculationLife.vue';
import InsuranceCalculationHome from './components/InsuranceCalculationHome.vue';
export default {
  components: {
    InsuranceQuestionnaireStep,
    InsuranceCalculationAuto,
    InsuranceCalculationLife,
    InsuranceCalculationHome,
  },
  props: {
    insObj: {
      type: Object,
      required: true,
    },
    isLocked: {
      type: Boolean,
      required: true,
    },
    isEditing: {
      type: Boolean,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    hasNext: {
      type: Boolean,
    },
    noOverlay: {
      type: Boolean,
    },
  },
  emits: [
    'postAnswers',
    'submitApplication',
    'is-form-filled'
  ],
  data () {
    return {
      steps: [],
      hiddenQuestions: new Set(),
      hiddenAnswers: new Set(),
      superSetChangeToggler: 1,
      checkErrors: false,
      hasError: false,
      isFormFilled: false,
      isActive: false,
      showAnswers: !this.isLocked,
      
      //used to triger transition
      insRerenderKey: 0,
      currentTransition: 'slide-left',

      //semi hardcoded values
        totalCalcAmount: 0,
        //used for life insurance  
        otherData: [],
        //used for auto insurance
        coverageQuestions: [],
        autoCoverageQuestions: [],
        showUmbrella: false,
        atRisk: null,
        totalRequired: 0,
    };
  },
  computed: {
    activeStep () {
      return this.filteredSections.find(step => step.active);
    },
    activeStepNumber () {
      return this.filteredSections.findIndex(step => step.active) + 1;
    },
    activeStepIndex () {
      return this.activeStepNumber - 1;
    },
    activeStepFromStepsObj () {
      return this.steps.find(step => step.active);
    },
    isLastStep () {
      return this.activeStepIndex + 1 >= this.filteredSections.length;
    },
    filteredSections () {
      let data = [];
      for (let i = 0; i < this.steps.length; i++) {
        const section = this.steps[i];
        let temp_section = {
          ...section,
          questions: [],
        };
        for (let question of section.questions) {
          if (this.hiddenQuestions.has(Number(question.que_id))) {
            continue;
          }
          if (![1, 2, 3].includes(question.que_type)) {
            temp_section.questions.push(question);
            continue;
          }
          let temp_question = {
            ...question,
            answers: [],
          };
          for (let answer of question.oldAnswers) {
            if (!this.hiddenAnswers.has(Number(answer.ans_id))) {
              temp_question.answers.push(answer);
            }
          }

          if (question.que_type === 3 && question.que_placeholder !== null) {
            if (temp_question.answer.length === 0) temp_question.answer = ['none'];
            temp_question.answers.push({
              ans_id: 'none',
              ans_content: question.que_placeholder,
              hides_questions: null,
              hides_answers: null,
              shows_questions: null,
              shows_answers: null,
            });
          }

          temp_section.questions.push(temp_question);
        }
        data.push(temp_section);
        // if auto pop umbrella coverage
      }
      if (this.insObj.ins_id === 3 && !this.showUmbrella) data.pop();
      return this.superSetChangeToggler && data.filter(step => step.questions.length > 0);
    },
    calcQuestions () {
      const calcQueArr = [];
      if (this.filteredSections.length > 0) {
        for (let j = 0; j < this.filteredSections.length; j++) {
          for (let k = 0; k < this.filteredSections[j].questions.length; k++) {
            const que = this.filteredSections[j].questions[k];
            if (que.que_calc) calcQueArr.push(que);
          }
        }
      }
      return calcQueArr;
    },
    getCalculationComponent () {
      let comp;
      switch (this.insObj.ins_id) {
        case 1: comp = 'InsuranceCalculationHome'; break;
        case 2: comp = 'InsuranceCalculationLife'; break;
        case 3: comp = 'InsuranceCalculationAuto'; break;
      }
      return comp;
    },
    calcDisplayCondition () {
      let val;
      if (this.insObj.ins_id === 3) val = this.totalRequired >= 0 && this.coverageQuestions.length;
      else val = this.totalCalcAmount > 0 || this.totalCalcAmount.length > 0;
      return val;
    },
  },
  watch: {
    steps: {
      deep: true,
      async handler () {
        this.onUpdateAnswers();
      },
    },
  },
  created () {
    this.init();
  },
  methods: {
    async init () {
      this.createSteps();
      this.nice();
      this.setCompletedSteps();
      await this.postCalcQuestions();
      // this.bindButtonsToKeys();
    },
    createSteps () {
      const sections = JSON.parse(JSON.stringify(this.insObj.sections));

      for (let i = 0; i < sections.length; i++) {
        const questions = sections[i].questions;
        this.getHiddenFields(questions);
        
        //create step
        this.steps[i] = {
          id: i,
          name: sections[i].sec_name,
          text: sections[i].sec_text,
          completed: false,
          active: i === 0? true : false,
          questions: questions.map(queObj => {
            const newQueObj = {
              ...queObj,
              // oldAnswers fix the bug with dynamic answers count
              // Selecting an answer from (hidden) list, prevent the answer from reappearing again
              oldAnswers: queObj.answers,
            };
            if (queObj.answer) {
              if (queObj.answer.ans_id) {
                newQueObj.answer = queObj.answer.ans_id;
              } else {
                newQueObj.answer = queObj.answer.qan_content;
              }
            } else {
              // checkboxGroup requires [] for v-model to handle multiple values
              if (queObj.que_type == 3) newQueObj.answer = [];
              else if (queObj.que_prefill) {
                newQueObj.answer = queObj.que_prefill;
                // isPrefilled is used for step completion logic
                // if question answer is prefilled, step wont be considered as completed, even tho it has an answer
                newQueObj.isPrefilled = true;
              }
              else newQueObj.answer = '';
            }

            return newQueObj;
          }),
        };
      }
    },
    setCompletedSteps () {
      for (let i = 0; i < this.steps.length; i++) {
        // set if step is already completed
        // every function returns true if array is empty
        const dynamicQuestions = this.steps[i].questions.filter(queObj => !this.hiddenQuestions.has(queObj.que_id));
        const areAllQuestionsFilled = dynamicQuestions.every(question => {
          if (question.que_type === 3) return !!this.insObj.isCompletedInitially;
          if (question.isPrefilled) return false;
          return question.answer.length > 0;
        });
        this.steps[i].completed = dynamicQuestions.length > 0? areAllQuestionsFilled : false;
      }
    },
    getHiddenFields (questions) {
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        for (let j = 0; j < question.answers.length; j++) {
          const answer = question.answers[j];
          // need to check already answered questions to prevent all questions from hiding
          const answeredObj = question.answer;
          let answeredValue = null;
          if (answeredObj && answeredObj.ans_id) answeredValue = answeredObj.ans_id;
         
          if (answer.shows_questions !== null) {
            for (let que_id of answer.shows_questions) {
              if (Array.isArray(answeredValue)) {
                if (!answeredValue.includes(answer.ans_id))
                this.addToSet(que_id, this.hiddenQuestions);
              } else {
                if (answeredValue != answer.ans_id) 
                this.addToSet(que_id, this.hiddenQuestions);
              }
            }
          }
          if (answer.shows_answers !== null) {
            for (let ans_id of answer.shows_answers) {
              if (Array.isArray(answeredValue)) {
                if (!answeredValue.includes(answer.ans_id))
                this.addToSet(ans_id, this.hiddenAnswers);
              } else {
                if (answeredValue != answer.ans_id) 
                this.addToSet(ans_id, this.hiddenAnswers);
              }
            }
          }
          if (answer.hides_questions !== null) {
            for (let que_id of answer.hides_questions) {
              if (Array.isArray(answeredValue)) {
                if (answeredValue.includes(answer.ans_id))
                this.addToSet(que_id, this.hiddenQuestions);
              } else {
                if (answeredValue == answer.ans_id) 
                this.addToSet(que_id, this.hiddenQuestions);
              }
            }
          }
          if (answer.hides_answers !== null) {
            for (let ans_id of answer.hides_answers) {
              if (Array.isArray(answeredValue)) {
                if (answeredValue.includes(answer.ans_id))
                this.addToSet(ans_id, this.hiddenAnswers);
              } else {
                if (answeredValue == ans_id) 
                this.addToSet(ans_id, this.hiddenAnswers);
              }
            }
          }
        } 
      }
    },
    async previousStep () {
      // await this.initErrorCheck();

      // if (!this.hasError) {
      //   this.activeStepFromStepsObj.completed = true;
      // } else {
      //   this.activeStepFromStepsObj.completed = false;
      // }
      this.currentTransition = 'slide-right';
      this.changeStep(this.activeStepIndex - 1);
    },
    async nextStep () {
      await this.initErrorCheck();

      if (!this.hasError) {
        this.activeStepFromStepsObj.completed = true;
        this.currentTransition = 'slide-left';
        this.changeStep(this.activeStepIndex + 1);
      }
    },
    async handleStep (index) {
      // one of previous steps
      if (index < this.activeStepIndex) {
        // if (!this.hasError) {
        //   this.activeStepFromStepsObj.completed = true;
        // } else {
        //   this.activeStepFromStepsObj.completed = false;
        // }
        this.currentTransition = 'slide-right';
        this.changeStep(index);
      // one of next steps
      } else if (index > this.activeStepIndex) {
        //prevent going next step if theres an error on current one
        await this.initErrorCheck();
        if (!this.hasError) {
          this.currentTransition = 'slide-left';
          this.activeStepFromStepsObj.completed = true;
          if (this.filteredSections[index].completed) {
            this.changeStep(index);
          } else {
            const uncompletedStep = this.filteredSections.findIndex(stepObj => !stepObj.completed);
            this.changeStep(uncompletedStep);
          }
        }
      }
    },
    changeStep (index) {
      for (let i = 0; i < this.steps.length; i++) {
        this.steps[i].active = false;
      }

      const stepIdForActivation = this.filteredSections[index].id;
      const stepIndexFromId = this.steps.findIndex(step => step.id === stepIdForActivation);
      this.steps[stepIndexFromId].active = true;
      this.insRerenderKey++;
    },
    async onUpdateAnswers () {
      if (!this.hasError) {
        const params = {
          ins_id: this.insObj.ins_id,
          answers: [],
        };
        for (let i = 0; i < this.filteredSections.length; i++) {
          for (let j = 0; j < this.filteredSections[i].questions.length; j++) {
            const queObj = this.filteredSections[i].questions[j];
            params.answers.push({
              que_id: queObj.que_id,
              // if question is free input or date or number, fill ans_content property, otherwise fill ans_id
              // array which is checked is all the input types that are free text
              ans_id: [0, 4, 5, 6].includes(queObj.que_type)? null : queObj.answer,
              ans_content: ![0, 4, 5, 6].includes(queObj.que_type)? null : queObj.answer,
            });
          }
        }

        // handles special question for auto coverage
        if (this.autoCoverageQuestions.length > 0) params.answers.push(...this.autoCoverageQuestions);
        // default umbrela answer
        if (this.insObj.ins_id === 3 && !this.showUmbrella) params.answers.push({
          que_id: 150,
          ans_id: 199,
          ans_content: null,
        });

        this.$emit('postAnswers', params);
      }
    },
    updateQuestionsHandler (filteredQuestions) {
      // find answered questions from array
      let changedQuestionIds = [];
      for (let i = 0; i < filteredQuestions.length; i++) {
        const oldQuestion = this.filteredSections[this.activeStepIndex].questions.find(queObj => queObj.que_id === filteredQuestions[i].que_id);
        // if oldQuestion is a checkbox group, perform array comparison
        if (oldQuestion && Array.isArray(oldQuestion.answer)) {
          // first step - check length
          if (oldQuestion.answer.length !== filteredQuestions[i].answer.length) {
            changedQuestionIds.push(filteredQuestions[i].que_id);
          } else {
            // if its the same length - iterate over values
            for (let j = 0; j < oldQuestion.answer.length; j++) {
              if (oldQuestion.answer[j] !== filteredQuestions[i].answer[j]) {
                changedQuestionIds.push(filteredQuestions[i].que_id);
              }
            }
          }
        // if oldQuestion is an array or string
        } else {
          if (oldQuestion && oldQuestion.answer !== filteredQuestions[i].answer) {
            changedQuestionIds.push(filteredQuestions[i].que_id);
          }
        }
      }

      // sends questions that affect the calculations
      this.onPostCalcQuestions(changedQuestionIds);

      const stepIdForActivation = this.filteredSections[this.activeStepIndex].id;
      const stepIndexFromId = this.steps.findIndex(step => step.id === stepIdForActivation);

      
      // update step questions
      for (let i = 0; i < changedQuestionIds.length; i++) {
        const questionToReplaceIndex = this.steps[stepIndexFromId].questions.findIndex(queObj => queObj.que_id == changedQuestionIds[i]);
        const question = filteredQuestions.find(queObj => queObj.que_id === changedQuestionIds[i]);
        // clear isPreffiled prop to reset the logic for step completion
        delete question.isPrefilled;
        this.steps[stepIndexFromId].questions.splice(questionToReplaceIndex, 1, question);
      }
      this.nice();
    },
    nice () {
      this.hiddenQuestions.clear();
      this.hiddenAnswers.clear();

      const queList = [];
      for (let i = 0; i < this.steps.length; i++) {
        for (let j = 0; j < this.steps[i].questions.length; j++) {
          queList.push(this.steps[i].questions[j]);
        }
      }

      for (let section of this.steps) {
        for (let question of section.questions) {
          for (let answer of question.answers) {
            if (answer.shows_questions !== null) {
              for (let que_id of answer.shows_questions) {
                this.hiddenQuestions.add(Number(que_id));
              }
            }
            if (answer.shows_answers !== null) {
              for (let ans_id of answer.shows_answers) {
                this.hiddenAnswers.add(Number(ans_id));
              }
            }
          }
        }
      }
      for (let section of this.steps) {
        for (let question of section.questions) {
          const answeredObj = question.answer;
          let answeredValue = null;
          if (answeredObj && answeredObj) answeredValue = answeredObj;
          for (let answer of question.answers) {
            let isAnswer = false;
            if (Array.isArray(answeredValue)) {
              if (answeredValue.includes(answer.ans_id)) {
                isAnswer = true;
              }
            } else {
              if (answeredValue == answer.ans_id) {
                isAnswer = true;
              }
            }
            if (isAnswer) {
              if (answer.hides_questions !== null) {
                for (let que_id of answer.hides_questions) {
                  this.hiddenQuestions.add(Number(que_id));
                }
              }
              if (answer.hides_answers !== null) {
                for (let ans_id of answer.hides_answers) {
                  this.hiddenAnswers.add(Number(ans_id));
                }
              }
              if (answer.shows_questions !== null) {
                for (let que_id of answer.shows_questions) {
                  this.hiddenQuestions.delete(Number(que_id));
                }
              }
              if (answer.shows_answers !== null) {
                for (let ans_id of answer.shows_answers) {
                  this.hiddenAnswers.delete(Number(ans_id));
                }
              }
            }
          }
        }
      }
      this.clearAnswersFromHiddenQuestions();
      this.superSetChangeToggler++;
    },
    clearAnswersFromHiddenQuestions () {
      const hiddenQueList = [];
      for (let i = 0; i < this.steps.length; i++) {
        for (let j = 0; j < this.steps[i].questions.length; j++) {
          if (this.hiddenQuestions.has(this.steps[i].questions[j].que_id)) hiddenQueList.push(this.steps[i].questions[j]);
        }
      }

      let isAnswerDeleted = false;

      for (const queObj of hiddenQueList) {
        if (Number.isInteger(queObj.answer) || queObj.answer.length) {
          queObj.answer = queObj.que_type === 3? [] : '';
          isAnswerDeleted = true;
        }
      }

      if (isAnswerDeleted) this.nice();
    },
    onPostCalcQuestions (changedQuestionIds) {
      for (let i = 0; i < this.calcQuestions.length; i++) {
        if (changedQuestionIds.includes(this.calcQuestions[i].que_id)) {
          this.postCalcQuestions();
          return;
        }
      }
    },
    async postCalcQuestions () {
      const params = {
        id: this.insObj.ins_id,
        answers: [],
      };
      //question answer doesnt get updated so we await nextTick
      await this.$nextTick();

      for (let j = 0; j < this.calcQuestions.length; j++) {
        const queObj = this.calcQuestions[j];
        params.answers.push({
          que_id: queObj.que_id,
          // if question is free input or date or number, fill ans_content property, otherwise fill ans_id
          // array includes non free text inputs
          ans_id: [0, 4, 5, 6].includes(queObj.que_type)? null : queObj.answer,
          ans_content: ![0, 4, 5, 6].includes(queObj.que_type)? null : queObj.answer,
        });
      }
      
      const apiObj = {
        api: this.globalMixin_api.postCalcQuestions,
        params,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.totalCalcAmount = data.data;
        console.log(this.totalCalcAmount);
        this.showUmbrella = data.umbrella;
        this.atRisk = data.at_risk;
        this.totalRequired = data.total_required;
        if (data['other-data']) this.otherData = data['other-data'];
        if (data.questions) this.coverageQuestions = data.questions.map(que => {
          if (que.que_type === 3 && !que.answer) que.answer = [];
          return que;
        });
      }
    },
    async initErrorCheck () {
      this.checkErrors = true;
      await this.$nextTick();
      this.checkErrors = false;
    },
    addToSet (item, set) {
      this.superSetChangeToggler++;
      set.add(item);
    },
    removeFromSet (item, set) {
      this.superSetChangeToggler++;
      set.delete(item);
    },
    errorHandler (bool) {
      this.hasError = bool;
    },
    setIsFormFilled (bool) {
      this.isFormFilled = bool;
      const stepInfo = {
        id: this.insObj.ins_id,
        type: this.type,
        isFilled: this.isFormFilled,
      };
      if((this.isLastStep && bool) || !bool){
        this.$emit('is-form-filled', stepInfo);
      }
    },
    handleAutoQuestions (questions) {
      this.autoCoverageQuestions = questions;
      this.onUpdateAnswers();
    },
    nextForm () {
      this.$emit('scrollToNext');
    },
    submitApplication () {
      this.$emit('submitApplication');
    },
    checkFocused () {
      let inputs = this.$refs.questionnaireWrap.querySelectorAll(".base-input-el");
      for(let i = 0; i < inputs.length; i++){
        if(inputs[i] === document.activeElement) {
          inputs[i].blur();
        }
      }
      this.isActive = false;
    },
    checkIsLocked () {
      if(!this.isLocked){
        this.isActive = true;
      }
    },
    classBindEnter () {
      this.$refs.content.style.overflow = 'hidden';
    },
    classBindLeave () {
      this.$refs.content.style.overflow = 'visible';
    },
  },
};
</script>

<style lang="scss" scoped>
.questionnaire-wrap {
  display: flex;
  flex-direction: column;
  // gap: .5em;
  @include flex-gap(.5em, 'column nowrap');
  padding: 1em 2vw;
  background: #FFF;
  border: 2px solid #D7DEE8;
  border-radius: 10px;
  max-width: 3840px;
  transition: all .2s ease-out;
  position: relative;
  &.overflow {
    overflow: hidden;
  }
  .locked-overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
    border-radius: 10px;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      // gap: 1em;
      @include flex-gap(1em, 'column nowrap');
      max-width: 26em;
      max-height: 100%;
      padding: 0 1em;
      img {
        max-width: 12em;
      }
      .title {
        display: flex;
        align-items: center;
        // gap: min(0.5em, 1vw);
        @include flex-gap(.5em, 'row nowrap');
        span {
          font-weight: 600;
          font-size: 1.1em;
          display: flex;
          align-items: center;
          &.home {
            color: $button-color-aqua;
          }
          &.life {
            color: #654BE4;
          }
          &.auto {
            color: $main-blue;
          }
          span.questionnaire {
            color: #313450;
            margin-left: .5em;
          }
        }
      }
      .info {
        font-size: .9em;
        color: #888FA6;
        text-align: center;
        line-height: 1.8em;
      }
      // button {
      //   position: absolute;
      //   top: 1em;
      //   right: 1em;
      // }
    }
  }
  .header {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // gap: 1em 2em;
    @include flex-gap(2em, 'row wrap');
    font-weight: 600;
    .title {
      display: flex;
      align-items: center;
      // gap: min(0.5em, 1vw);
      @include flex-gap(.5em, 'row nowrap');
      margin-left: .7em;
      span {
        &.home {
          color: $button-color-aqua;
        }
        &.life {
          color: #654BE4;
        }
        &.auto {
          color: $main-blue;
        }
      }
      .sec-name {
        font-size: 1.25em;
        color: #434961;
      }
    }
    .wizard-wrapper {
      display: flex;
      justify-content: flex-end;
      min-width: 20em;
      &.one-element {
        min-width: auto;
      }
    }
  }
  .section-text {
    font-weight: 600;
    color: #434961;
    margin: .5em 0;
  }
  .content {
    display: flex;
    flex-direction: column;
    // gap: 1em;
    // @include flex-gap(1em, 'column nowrap');
    overflow: visible;
    .buttons-line {
      display: flex;
      margin: .5em 0;
      flex-direction: row-reverse;
      // gap: 2em;
      @include flex-gap(1em, 'row-reverse nowrap');
    }
  }
}
.active{
  // transition: all .2s ease-out;
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.06);
  // transform: scale(1.02);
  border-color: $main-blue;
}

@media (min-width: 769px) {
  .questionnaire-wrap {
    .header { 
      justify-content: space-between;
    }
  }
}

@media (max-width: 500px) {
  #auto {
    .wizard-wrap {
      overflow-x: auto;
    }
  }
}

@media screen and (max-width: 280px){
  .questionnaire-wrap{
    .header{
      .wizard-wrapper{
        width: 200px;
        min-width: 150px;
      }
    }
  }
}
</style>