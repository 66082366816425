<template lang="pug">
.dashboard-wrap(
  :class="{ 'top-offset' : isMobileMixin_isMobile }"
  @scroll.prevent
)
  confirm-box(
    ref="confirmBox"
  )
  modal(
    v-if="showModal"
    @close-modal="deletedAccConfirm"
  )
    notice-box(
      :icon="globalMixin_images.checkBlue"
      :message="$t('profile.accDeleted')"
      :buttonLabel="$t('buttons.ok')"
      :buttonCallback="deletedAccConfirm"
    )
  profile-navigation(
    v-if="isMobileMixin_isMobile"
  )
  .header
    .search-wrap
      h1 {{ $t('profile.title') }}
    .profile-notification-wrap
      notification-box
      profile-icon-box(
        v-if="!isMobileMixin_isMobile"
      )
    //- profile-burger(
    //-   v-if="isMobileMixin_isMobile"
    //- )
  .main
    .info
      .form(
        v-show="user && !isChangingPassword"
      )
        base-input(
          v-model.trim="user.usr_firstname"
          :disabled="locked"
          :label="$t('form.firstname')"
          :placeholder="$t('form.firstnamePlaceholder')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="user.usr_lastname"
          :disabled="locked"
          :label="$t('form.lastname')"
          :placeholder="$t('form.lastnamePlaceholder')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="user.usr_email"
          :disabled="locked"
          :label="$t('form.email')"
          :placeholder="$t('form.emailPlaceholder')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="user.usr_phonenumber"
          v-mask="globalMixin_phoneMask"
          autocomplete="off"
          :disabled="locked"
          :label="$t('form.phone')"
          :placeholder="$t('form.phonePlaceholder')"
          :validatorTypes="[ 'phoneNum', 'required', 'maxLength' ]"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-date-picker(
          v-if="partnerInputsBool"
          v-model.trim="user.usr_partnerdateofbirth"
          :disabled="locked"
          :label="$t('form.partnerDateOfBirth')"
          :validatorTypes="['required', 'maxLength', 'regex']"
          :validatorData="{ regex: globalMixin_dateRegex }"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-if="partnerInputsBool"
          v-model.trim="user.usr_partnerfirstname"
          :disabled="locked"
          :label="$t('form.partnerFirstname')"
          :placeholder="$t('form.partnerFirstname')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-if="partnerInputsBool"
          v-model.trim="user.usr_partnerlastname"
          :disabled="locked"
          :label="$t('form.partnerLastname')"
          :placeholder="$t('form.partnerLastname')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-type-dropdown(
          v-if="allZipCodes.length > 0 && user.zipcodes"
          v-model="user.zipcodes"
          :disabled="locked"
          :label="$t('form.zipCode')"
          :placeholder="$t('form.zipCode')"
          :options="allZipCodes"
          trackBy="zip_id"
          showBy="zip_code"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-radio-group(
          v-model="user.usr_maritalstatus"
          name="radioMartial"
          :disabled="locked"
          :label="$t('form.maritalStatus')"
          :placeholder="$t('form.maritalStatus')"
          :options="[{ id: 1, label: 'Married' }, { id: 2, label: 'Domestic Partner' }, { id: 0, label: 'Single' }]"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-date-picker(
          v-model.trim="user.usr_dateofbirth"
          :disabled="locked"
          :label="$t('form.dateOfBirth')"
          :placeholder="$t('form.dateOfBirth')"
          :validatorTypes="['required', 'maxLength', 'regex']"
          :validatorData="{ regex: globalMixin_dateRegex }"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-checkbox(
          v-model="user.usr_emailnotifications"
          :disabled="locked"
          :label="'Receive e-mail notifications'"
        )
        base-radio-group(
          v-model="user.usr_rents"
          :options="[{ value: 1, label: $t('form.yes') }, { value: 0, label: $t('form.no') }]"
          valueKey="value"
          name="rents-group-user-dashboard"
          :disabled="locked"
          :label="$t('form.doYouRent')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="user.usr_address1"
          :disabled="locked"
          :label="$t('form.address1')"
          :placeholder="$t('form.address1')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="user.usr_address2"
          :disabled="locked"
          :label="$t('form.address2')"
          :placeholder="$t('form.address2')"
          :validatorTypes="[]"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="user.usr_city"
          :disabled="locked"
          :label="$t('form.city')"
          :placeholder="$t('form.city')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model.trim="user.usr_state"
          :disabled="locked"
          :label="$t('form.state')"
          :placeholder="$t('form.state')"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-select(
          name="gender"
          v-model="user.usr_gender"
          :disabled="locked"
          :label="$t('form.gender')"
          :placeholder="$t('form.gender')"
          :options="[{ label: $t('registration.male'), id: 0 }, { label: $t('registration.female'), id: 1 }, { label: $t('registration.other'), id: 2 }]"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        span(
          @click="onDeleteAccount"
          class = "delete-acc"
        )
          | {{ $t('buttons.deleteAccount') }}

      .form(
        v-show="user && isChangingPassword"
      )
        base-input(
          v-model="user.usr_oldPassword"
          type="password"
          :disabled="locked"
          :label="$t('form.oldPassword')"
          :placeholder="$t('form.oldPassword')"
          :validatorTypes="passwordValidators"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model="user.usr_password"
          type="password"
          :disabled="locked"
          :label="$t('form.newPassword')"
          :placeholder="$t('form.newPassword')"
          :validatorTypes="passwordValidators"
          :isFormSubmitted="formMixin_checkErrors"
          @has-error="formMixin_errorHandler"
        )
        base-input(
          v-model="user.usr_confirmNewPassword"
          type="password"
          :disabled="locked"
          :label="$t('form.confirmNewPassword')"
          :placeholder="$t('form.confirmNewPassword')"
          :isFormSubmitted="formMixin_checkErrors"
          :validatorTypes="confirmPasswordValidators"
          :validatorData="{ confirmPassword: user.usr_password }"
          @has-error="formMixin_errorHandler"
        )
      .button-line
        //- :disabled="formMixin_hasError"
        base-button(
          :label="isChangingPassword ? $t('buttons.backToDetails') : $t('buttons.changePassword')"
          style="marginRight: 1em;"
          noFillColor
          @click="onChangePassword"
        )
        base-button(
          v-if="locked"
          :label="$t('buttons.clickToEdit')"
          :disabled="!locked"
          @click="unlock"
        )
        base-button(
          v-else
          :label="$t('buttons.updateChanges')"
          :disabled="!isDataChanged"
          noFillColor
          @click="onSaveChanges"
        )
    .stats
      .graph
        img(
          :src="globalMixin_images.userDashboardIllustation"
          alt="ilustration"
        )
      .status-pending
        .status
          .header
            h3 {{ $t('profile.applicationStatus') }}
            span.edit(
              @click="$router.push({ name: 'UserApplication' })"
            )
              | {{ $t('profile.editApplication') }}
          button.light-blue
             span(v-if="usr_accepted_quote") {{ $t('buttons.acceptedQuote') }}
             span(v-else) {{ $t('buttons.pendingQuote') }}
          .paragraph
            h4 {{ pengingInsText }}
            //- p.transparent Lorem ipsum dolor sit amet, consectetur adipiscing elit  morelon oncus orci vulputate.
        .pending(
          v-if="!usr_accepted_quote"
        )
          .header
            p(@click="$router.push({ name: 'UserQuotes' })") {{ $t('profile.pendingQuotes') }}
            img(
              :src="globalMixin_images.shareArrow"
            )
          .quotes
            .number {{ quote_count }}
            span {{ $t('profile.numberOfQuotes') }}
        .pending(
          v-else
          @click="$router.push({ name: 'UserQuotes' })"
          style="cursor: pointer;"
        )
          .header(
            :style="safariFix"
          )
            p {{ $t('profile.quoteAccepted') }}
            img(
              :src="globalMixin_images.shareArrow"
            )
          .quotes-chosen {{ $t('profile.clickChosen') }}
</template>

<script>
import ProfileNavigation from '@/components/profile/ProfileNavigation';
import NotificationBox from "@/components/profile/NotificationBox.vue";
import ProfileIconBox from "@/components/profile/ProfileIconBox.vue";
import formMixin from "@/js/formMixin";
import ConfirmBox from "@/components/ConfirmBox.vue";
import Modal from '@/components/Modal.vue';
import NoticeBox from '@/components/NoticeBox.vue';
import dayjs from 'dayjs';

export default {
  components: {
    ProfileNavigation,
    NotificationBox,
    ProfileIconBox,
    ConfirmBox,
    Modal,
    NoticeBox,
  },
  mixins: [
    formMixin,
  ],
  data () {
    return {
      user: {
        usr_email: "",
        usr_firstname: "",
        usr_lastname: "",
        usr_dateofbirth: "",
        usr_address1: "",
        usr_city: "",
        usr_state: "",
        usr_address2: "",
        usr_rents: 0,
        usr_gender: "0",
        usr_phonenumber: "",
        usr_emailnotifications: true,
        usr_maritalstatus: "0",
        usr_partnerfirstname: null,
        usr_partnerlastname: null,
        usr_partnerdateofbirth: null,
        usr_oldPassword: "",
        usr_password: "",
        usr_confirmNewPassword: "",
        zipcodes: [],
      },
      oldUser: null,
      isChangingPassword: false,
      allZipCodes: [],
      pendingInsArr: [],
      pengingInsText: "",
      quote_count: 0,
      locked: true,
      usr_accepted_quote: false,
      showModal: false,
      safariFix: {
        marginBottom: '3.5em',
      },
    };
  },
  computed: {
    isDataChanged () {
      for (const key in this.user) {
        // special object check
        if (key === 'zipcodes') {
          if (this.user[key].zip_id != this.oldUser[key].zip_id) return true;
          continue;
        }
        // regular primitive check
        if (this.user[key] != this.oldUser[key]) return true;
      }
      return false;
    },
    partnerInputsBool () {
      return parseInt(this.user.usr_maritalstatus) !== 0;
    },
    passwordValidators () {
      if (this.user.usr_password.length > 0 || this.user.usr_oldPassword.length > 0 || this.user.usr_confirmNewPassword.length > 0) return ['required'];
      else return [];
    },
    confirmPasswordValidators () {
      if (this.user.usr_password.length > 0 || this.user.usr_oldPassword.length > 0 || this.user.usr_confirmNewPassword.length > 0) return ['confirmPassword', 'required'];
      else return [];
    },
  },
  watch: {
    user: {
      deep: true,
      handler () {
        if (this.user.usr_password.length === 0 && this.user.usr_oldPassword.length === 0 && this.user.usr_confirmNewPassword.length === 0) {
          this.formMixin_clearErrors();
        }
      },
    },
  },
  created () {
    this.oldUser = this.user;
  },
  mounted () {
    this.init();
  },
  methods: {
    async init () {
      this.startLoading();
      await this.getZipCodes();
      await this.getUserInfo();
      this.getPendingIns();
      this.stopLoading();
    },
    async getUserInfo () {
      const apiObj = {
        api: this.globalMixin_api.getUserProfile,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.user = data.data;
        this.pendingInsArr = data.types;
        // TODO check why BaseRadioGroup doesnt work with boolean values for user.usr_rents
        this.user.usr_rents = this.user.usr_rents? 1 : 0;
        // there will be only one zipCode for user
        this.user.zipcodes = this.user.zipcodes[0];
        this.user.usr_dateofbirth = dayjs(this.user.usr_dateofbirth, 'YYYY-MM-DD').format('MM/DD/YYYY');
        if (this.user.usr_partnerdateofbirth) this.user.usr_partnerdateofbirth = dayjs(this.user.usr_partnerdateofbirth, 'YYYY-MM-DD').format('MM/DD/YYYY');
        this.addPasswordFields();
        this.oldUser = JSON.parse(JSON.stringify(data.data));
        this.quote_count = data.quote_count;
        this.usr_accepted_quote = data.quote_confirmed;
      }
    },
    async getZipCodes () {
      const apiObj = {
        api: this.globalMixin_api.getZipCodes,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.allZipCodes = data.data;
      }
    },
    getPendingIns () {
      if (this.pendingInsArr.length >= 1) {
        for (let i = 0; i < this.pendingInsArr.length; i++) {
          this.pengingInsText += this.pendingInsArr[i].ins_short;
          if(i < this.pendingInsArr.length -1){
            this.pengingInsText += ' & ';
          }        
        }
        this.pengingInsText += ' Insurance';
      }
    },
    addPasswordFields () {
      this.user.usr_oldPassword = '';
      this.user.usr_password = '';
      this.user.usr_confirmNewPassword = '';
    },
    onChangePassword () {
      this.isChangingPassword = !this.isChangingPassword;
      this.unlock();
    },
    onSaveChanges () {
      if (this.user.usr_password.length > 0 || this.user.usr_oldPassword.length > 0 || this.user.usr_confirmNewPassword.length > 0) {
        if (this.user.usr_oldPassword.length === 0) this.isChangingPassword = true;
        if (this.user.usr_password.length === 0) this.isChangingPassword = true;
        if (this.user.usr_confirmNewPassword.length === 0) this.isChangingPassword = true;
        if (this.user.usr_password !== this.user.usr_confirmNewPassword) this.isChangingPassword = true;
      }
      this.formMixin_onFormSubmit(this.saveChanges);
    },
    async saveChanges () {
      let params = {};
      //get only data that has been changed
      for (const key in this.user) {
        if (this.user[key] != this.oldUser[key]) {
          params[key] = this.user[key];
        }
      }
      // convert user.usr_rents to boolean
      if (params.usr_rents !== undefined) params.usr_rents = !!params.usr_rents;
      if (params.usr_address2 === "") params.usr_address2 = "None";
      params.usr_dateofbirth = dayjs(params.usr_dateofbirth, 'MM/DD/YYYY').format('YYYY-MM-DD');
      if (params.usr_partnerdateofbirth) params.usr_partnerdateofbirth = dayjs(params.usr_partnerdateofbirth, 'MM/DD/YYYY').format('YYYY-MM-DD');

      const apiObj = {
        api: this.globalMixin_api.patchUserProfile,
        params,
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.appendToast({ message: this.$t('profile.editSuccess'), type: 'success' });
        this.getUserInfo();
        this.locked = true;
      }
    },
    async changePassword () {
      
    },
    async deleteAccount () {
      const apiObj = {
        api: this.globalMixin_api.deleteUserProfile,
      };
      const [data, error] = await this.globalMixin_apiCall(apiObj);
      this.showModal = true;
    },
    async onDeleteAccount () {
      await this.$refs.confirmBox.show({
        message: this.$t('profile.deleteAccount'),
        callback: this.deleteAccount,
      });
    },
    deletedAccConfirm () {
      this.$router.push({ name: "Landing" });
      this.globalMixin_onLogout();
    },
    unlock () {
      this.locked = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-wrap {
  h1 {
    margin: 0;
    font-size: 1.8em;
    white-space: nowrap;
  }
  display: flex;
  flex-direction: column;
  // gap: 1em 0;
  &.top-offset {
    padding-top: $nav-height;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    // gap: 3vw;
    // @include flex-gap(3vw, 'row nowrap');
    .search-wrap {
      display: flex;
      // gap: 1em;
      // @include flex-gap(1em, 'row nowrap');
      align-items: center;
      flex-grow: 1;
    }
    .profile-notification-wrap {
      display: flex;
      // gap: 1em;
      @include flex-gap(1em, 'row nowrap');
    }
  }
  .main {
    display: flex;
    flex-wrap: wrap;
    // gap: 1em;
    @include flex-gap(1em, 'row wrap');
    overflow: hidden;
    .info {
      width: 100%;
      display: flex;
      flex-direction: column;
      // gap: 5em 0;
      background-color: #FFF;
      padding: clamp(1em, 3vw, 2em);
      border-radius: 5px;
      max-height: 80vh;
      .form {
        overflow: auto;
        display: flex;
        flex-direction: column;
        @include flex-gap(.5em, 'column nowrap');
        padding-bottom: 2px;
        padding-right: 5px;
        min-height: 22em;
        .base-input,
        .base-type-dropdown,
        .base-radio-group,
        .base-checkbox,
        .base-select,
        .calendar-wrap {
          margin-bottom: 1.5em;
        }
        .delete-acc {
          color: $main-blue;
          font-style: italic;
          cursor: pointer;
          margin-bottom: 5px;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .button-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2em;
        margin-bottom: 1em;
        // gap: 0 1em;
        > button {
          width: 12em;
        }
      }
    }
    .stats {
      display: flex;
      flex-direction: column;
      // gap: 1em;
      // @include flex-gap(1em, 'column nowrap');
      $block-padding: clamp(.8em, 3vw, 1.2em);
      width: min(100%, 2550px);
      h3, h4, p {
        margin: 0;
      }
      .graph {
        text-align: center;
        padding: 2vw 3vw;
        border-radius: 5px;
        background: #FFF;
        margin-bottom: 1em;
        // height: 100%;
        flex-grow: 1;
      }
      .status-pending {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        .status {
          display: flex;
          flex-direction: column;
          // gap: 2em 0;
          // @include flex-gap(2em, 'column nowrap');
          background-color: #FFF;
          padding: $block-padding;
          border-radius: 5px;
          flex-grow: 1;
          .header {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            flex-wrap: wrap;
            margin-bottom: 1.5em;
            .edit {
              color: #448CFD;
              font-weight: 600;
              cursor: pointer;
            }
          }
          .light-blue {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2em 0;
            background-color: rgba(86, 119, 249, 0.2);
            color: #5775F8;
            border-radius: 10px;
            outline: none;
            border: none;
            font-weight: 600;
            padding: 1em 1.5em;
            width: max-content;
          }
          .paragraph {
            display: flex;
            flex-direction: column;
            // gap: .5em 0;
            // @include flex-gap(0.5em, 'column nowrap');
            margin-bottom: 1.5em;
            .transparent {
              opacity: .5;
              font-size: .95em;
            }
          }
        }
        .pending {
          display: flex;
          flex-direction: column;
          background: linear-gradient(146.33deg, #3E94FF -3.38%, #7D48EF 108.41%);
          border-radius: 5px;
          color: #FFF;
          padding: $block-padding;
          width: 100%;//MOBILE
          // gap: 5.5em 0;
          .header {
            display: flex;
            margin-bottom: 6.5em; // it already had 1em margin
            // gap: 0 4.5em;
            font-weight: 600;
            img {
              max-width: 17px;
              margin-left: 4.5em;
            }
            p {
              font-size: 1.1em;
              cursor: pointer;
            }
          }
          .quotes {
            .number {
              font-family: 'Open Sans';
              font-weight: 600;
              font-size: 3.5em;
            }
            span {
              font-size: .85em
            }
          }
          .quotes-chosen{
            font-family: inherit;
            font-weight: 600;
            text-align: center;
            font-size: 1.6em;
          }
        }
      }
    }
  }
}

@media (min-width: 769px) {
  .dashboard-wrap {
    .main {
      flex-wrap: nowrap;
      .info {
        width: max-content;
      }
      .stats {
        .status-pending {
          flex-wrap: initial;
          .pending {
            width: 20em;
          }
        }
      }
    }
  }
}

@media (max-width: 1150px) {
  .dashboard-wrap {
    .main {
      .stats {
        .status-pending {
          flex-wrap: wrap;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .dashboard-wrap {
    .main {
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 280px){
  .dashboard-wrap{
    .header{
      .search-wrap{
        h1{
          max-width: 70%;
          white-space: normal;
        }
      }
    }
  }
}

/* Safari 11+ */
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .dashboard-wrap {
    .main {
      .info {
        padding: 3vw;
      }
      .stats {
        .status-pending {
          .status {
            padding: 3vw;
          }
          .pending {
            padding: 3vw;
          }
        }
      }
    }
  }
}}
</style>