export default {
  "errors": {
    "insurance-company-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance company not found"])},
    "already-submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application already submitted"])},
    "duplicate-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A user with that email address already exists."])},
    "doesnt-exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User with that email doesn't exist"])},
    "invalid-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid token"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwords must match"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number must be 10 characters long"])}
  },
  "insurances": {
    "auto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Insurance"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Insurance"])},
    "life": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Life Insurance"])}
  },
  "landing": {
    "betterWay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the <span style='color: #2FE2D8; font-size: 1em; '>Coverage & Quotes</span> you need"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fairwey’s Coverage Recommendation Engine allows you to find the right coverage at the best rate. No spam calls. Just the advice & coverage you need at the best available rates."])},
    "optionsAgent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click Start Quoting and proceed with the registration process. Once registered, receive new leads and start quoting."])},
    "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works"])},
    "howItWorksWorkForYouParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’ll gather quotes from over 30 insurance companies, based on your individual coverage needs. You’ll then review your customized quotes in your account."])},
    "coverageNeeds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determine Your Coverage Needs"])},
    "weGetToWorkForYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Get to Work for You"])},
    "chooseQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a Quote & Connect"])},
    "reviewCustomQuotesParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After reviewing your quotes, simply choose a policy. We’ll then connect you with an agent to finalize your policy coverage. Don’t worry – we’ll never share your contact info until you decide. We don’t like spam calls and we know you don’t either."])},
    "coverageAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’re unique. And so are your coverage needs. Our Coverage Recommendation Engine helps you determine the right amount of coverage for your specific needs."])},
    "makeMoneyParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’re paid a flat fee from the agent you choose. <span style='font-weight: 600'>We’re not incentivized</span style='font-weight: 600'> by any agent or carrier. This way you have the confidence that <span style='color: aqua'>you’re truly getting the coverage you need</span> at the best rate available."])},
    "makeMoneyHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do we make money?"])},
    "howDifferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How is Fairwey different?"])},
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
    "obnoxiousPhoneCalls": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No obnoxious phone calls or emails"])},
    "obnoxiousParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We don't like annoying sales calls any more than you do. With Fairwey, we only share your contact info with the agent you choose, protecting you from being bombarded by phone calls & emails."])},
    "bestOfBothWorlds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best of both worlds"])},
    "bestOfBothWorldsParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We work with both captive and independent agents. This gives you access to the broadest selection of insurance carriers."])},
    "weAreFocusedOnYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right coverage, right price"])},
    "weAreFocusedOnYouParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our goal is simply to get you the coverage you need at the best rate available. As former insurance agents, we understand that the right coverage is just as important as the right rate."])},
    "selectInsuranceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What can we help with?"])},
    "reviewQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review your customized quotes online and choose an agent here."])},
    "youSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You selected"])},
    "selectInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select insurance type"])},
    "agentRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance Agent Registration"])},
    "weFindClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We Find Clients"])},
    "weFindClientsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We find potential clients, help them determine their precise coverage needs, and get that information to your team. Our coverage assessment tools educate your future clients about their needs, saving your team valuable time."])},
    "uploadYourQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Your Quote"])},
    "uploadYourQuoteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your team puts together a quote based on the client’s coverage needs and enters that info into your Fairwey profile."])},
    "onlyPayIfYouWin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only Pay if You Win"])},
    "onlyPayIfYouWinText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We never charge you unless the client chooses your quote. When they choose your quote, we connect you and the consumer so you can bind their policies. Say goodbye to expensive, time-consuming prospecting calls and start increasing your ROI."])},
    "clickVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click this button to complete your registration."])},
    "successfullyVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have succesfully verified your account!"])},
    "successfullyReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have succesfully reset your password!"])}
  },
  "registration": {
    "finishRegistration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Registration"])},
    "dontWorry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't worry"])},
    "infoNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your contact information will not be shared until you have selected a winning quote from an agent."])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Male"])},
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "yourName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
    "yourLastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your last name"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Terms of Use*"])},
    "termsOfUseSpecial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept Terms of Use <a href='/terms/agent.pdf' target='_blank'>here</a>"])},
    "youMustAcceptTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must accept terms of use."])},
    "verifyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify your email."])},
    "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to home"])},
    "emailConfirmMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank You for signing up with Fairwey! Please check your e-mail to validate your account."])},
    "emailDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This info is not visible to the agents until you have confirmed their quote."])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* view the terms"])},
    "yourEmailOrPasswordInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email or password is invalid."])},
    "validEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a valid email address."])},
    "resetPasswordSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check your email and reset your password."])}
  },
  "form": {
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "usernamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your username goes here"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "firstnamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your first name goes here"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "lastnamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your last name goes here"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email address"])},
    "emailPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email goes here"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old password"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "enterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter new password"])},
    "confirmNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone number"])},
    "phonePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone number goes here"])},
    "maritalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marital status"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "address1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 1"])},
    "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address 2"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zip code"])},
    "partnerFirstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your partner first name"])},
    "partnerLastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your partner last name"])},
    "partnerDateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your partner date of birth"])},
    "dateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "agentName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent name"])},
    "agentLastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent last name"])},
    "agencyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency name"])},
    "insLicenceNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance licence number"])},
    "prefZipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferred zip code(s)"])},
    "creditCardInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit card/payment information"])},
    "searchThePage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search the page"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "doYouRent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you rent?"])},
    "agencyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency phone number"])},
    "agencyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency email address"])},
    "receiveNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive e-mail notifications"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])},
    "forgotPasswordInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the email address associated with the account"])},
    "saveCardMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to save your card for future payments?"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])}
  },
  "buttons": {
    "nextStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next Step"])},
    "previousStep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous Step"])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "updateChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Changes"])},
    "pendingQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending Quote"])},
    "acceptedQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepted Quote"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "backToQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to quotes"])},
    "viewSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Summary"])},
    "quote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote"])},
    "getQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get my quotes"])},
    "getStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])},
    "userLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "goHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go Home"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "finishQuoting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish Quoting"])},
    "showClientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View client details"])},
    "goToAgencyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Agency Details"])},
    "agentLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agent Log in"])},
    "sendQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send a quote"])},
    "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "startQuoting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Quoting"])},
    "deleteApplicaton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete application"])},
    "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Account"])},
    "editQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit quote"])},
    "submitPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Payment"])},
    "clickToEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to Edit"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed"])},
    "submitApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit Application"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "markAllAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark all as read"])},
    "deleteAllNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete all notifications"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ok"])},
    "reveal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reveal section"])},
    "backToDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Details"])},
    "chooseQot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose this Quote"])},
    "openSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Application Summary"])},
    "closeSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Application Summary"])}
  },
  "autoInsurance": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Insurance Application"])}
  },
  "sidebarMenus": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
    "myQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Quotes"])},
    "agencyDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency Details"])},
    "leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])}
  },
  "profile": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile information"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "applicationStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application Status"])},
    "editApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Application"])},
    "pendingQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending quotes"])},
    "numberOfQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of quotes"])},
    "addNewType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new type"])},
    "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
    "questionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questionnaire"])},
    "myQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Quotes"])},
    "quoteAccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chosen Quote"])},
    "sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])},
    "breakdownDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakdown Details"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MONTHLY"])},
    "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNUAL"])},
    "quoteSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote Summary"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])},
    "applicationSaveProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You saved your progress"])},
    "applicationSubmitSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application successfully submitted, the agents will be coming back to you shortly."])},
    "applicationNotAllFilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not all application fields are filled"])},
    "applicationDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application deleted"])},
    "noApplicationSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select an insurance type to proceed with completing the <span style='color: #3E94FF;'>application.</span> Note that more insurance types means better chances to get better bundle prices.</p>"])},
    "deleteAppMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you wish to delete your application? All data related to this application will be lost."])},
    "editSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile updated!"])},
    "noQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No <span style='color: #3E94FF;'>quotes</span> yet. The agents are working on providing you with the best quotes for your insurance application."])},
    "homeCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Home's Estimated Replacement Cost"])},
    "homeCoverageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your home insurance quotes will be based on this level of coverage:"])},
    "homeCalcDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an estimation of the cost to rebuild your home. The actual coverage needed may vary."])},
    "lifeInsBreakdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Life Insurance Breakdown"])},
    "totalLifeNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Life Insurance Needed"])},
    "calculatedPolicyAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculated Policy Amount"])},
    "totalBodilyCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommended Bodily Injury Coverage"])},
    "umbrellaText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Looks like your coverage need is greater than $500k. You’ll want to consider a liability umbrella quote to protect your assets and income. Can we get an umbrella quote for you as well?"])},
    "recommendedCoverage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Would you like to go with the suggested BI coverage of <strong>", _interpolate(_named("coverage")), "</strong> to assure that your assets and income are properly protected? (Don’t worry, it’s one of the least expensive parts of your insurance)"])},
    "greatNews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great news! Looks like you’re in good shape with your current Bodily Injury coverage."])},
    "quoteRemoved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote removed by agent!"])},
    "quoteApologize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We apologize for the inconvenience."])},
    "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you wish to delete your account? This action will remove all of your account data, including your application. If you wish to access the system again, you will need to register again."])},
    "submittedApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congrats! We are working on getting your quotes. No worries - you will not be getting tons of phone calls from sales people. Once agents have completed their quotes, this information will show in your My Quotes page. From there you can compare your options and select the quote that suits you best."])},
    "autoCoverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liability Coverage Needs"])},
    "autoTotalAssets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total assets protection needed"])},
    "autoTotalRisk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total assets currently at risk"])},
    "insuranceLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application submitted. We're working on gathering your quotes!"])},
    "selectAnotherInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once submitted, the application can no longer be edited. You can add another insurance type of your choice into the bundle and get better prices!"])},
    "accDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have successfully deleted your account!"])},
    "clickChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have selected a quote, click here to view it"])},
    "deleteAllNotificationsConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete all your notifications?"])},
    "deleteNotificationConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this notification?"])}
  },
  "agentProfile": {
    "profileDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Details"])},
    "yourCarriers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your carriers"])},
    "addNewCarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new carrier"])},
    "carrierAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrier added."])},
    "carrierDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrier deleted."])},
    "agencyContactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agency contact information"])},
    "representativeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Representative information"])},
    "leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads"])},
    "noLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No <span style='color: #3E94FF;'>leads</span> yet. We are working on providing you with applications for quoting!"])},
    "noLeadsInCat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No <span style='color: #3E94FF;'>leads</span> in this category yet."])},
    "insuranceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insurance type"])},
    "filterLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter leads"])},
    "submitQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit a quote"])},
    "bundlePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundle price"])},
    "bundle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bundle"])},
    "quoteLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quote lead"])},
    "quoteSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for submitting your quote!"])},
    "selectInsuranceCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Insurance Company"])},
    "selectNewCarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select new carrier"])},
    "breakdownPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breakdown Price"])},
    "areYouSureDeleteCarrier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note that this action will remove all your quotes related to this carrier. Are you sure you want do delete this carrier?"])},
    "deleteQuoteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove this quote?"])},
    "backToLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to Leads"])},
    "pendingPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending payment"])},
    "paymentAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount: "])},
    "paymentNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you are given access to the client's contact details, please complete the payment process."])},
    "paymentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your payment. Please proceed to view your client's contact details."])},
    "saveCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to save this card for future purchases?"])},
    "paymentAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment accounts"])},
    "proceedToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proceed to Checkout"])},
    "payWithSavedCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with existing card"])},
    "useDifferentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use a different payment method"])},
    "pleaseChooseCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a card."])},
    "payWithSelectedCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with selected card"])},
    "sureToProceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you wish to proceed? Please be informed that by continuing with the process, you are authorizing being charged a fee by Fairwey."])},
    "deleteAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you wish to delete your account? This action will remove all of your account data, including your leads and quotes. If you wish to access the system again, you will need to register again."])},
    "allCarriersQuoted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotes for all of your carriers have already been covered by other agents. Unless another agent revokes their quote, you cannot submit a quote for this lead."])},
    "clientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client information"])},
    "detailsWarn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed with filling out your profile details. Then, you can start quoting!"])},
    "leadClientHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Information"])},
    "leadSpouseHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner Information"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])}
  },
  "notifications": {
    "code1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hi ", _interpolate(_named("firstName")), ", Great news!  We have a new quote for you to review. Check it out <a href='/profile/my-quotes' style='color: #3E94FF;'>here</a>"])},
    "code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Great news! We have a new prospect for you to quote. Remember, time is of the essence! Once you have a prepared quote, submit it to your prospect <a href='/agent/profile/leads/all' style='color: #3E94FF;'>here</a> for them to review."])},
    "code3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The prospect has requested a change to your quote. Please review changes and update your quote <a href='/agent/profile/leads/all' style='color: #3E94FF;'>here</a>"])},
    "code4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woohoo! - Your quote was selected. Now let’s get you connected. Click <a href='/agent/profile/leads/all' style='color: #3E94FF;'>here</a> to get this relationship off and running!"])},
    "seeAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all notifications"])},
    "noNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No <span style='color: #3E94FF;'>notifications</span> to show."])}
  },
  "footer": {
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright 2022 Fairwey LLC. All Rights Reserved"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "getInTouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch"])},
    "getInTouchThanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for getting in touch. We will get back to you as soon as possible."])},
    "legalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal Details"])},
    "getInTouchText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have questions or a comment? We’d love to hear from you!"])},
    "getQuotes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Real Quotes from Real Agents"])}
  },
  "noticeBox": {
    "insufficientInfoFilled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill out the remaining fields in your profile page in order to see your leads."])}
  }
}