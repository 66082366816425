import i18n from '../i18n';
import images from '@/js/images';

export default {
  user: [
    { icon: images.dashboard, label: i18n.global.t('sidebarMenus.dashboard'), name: 'UserDashboard' },
    { icon: images.bullet, label: i18n.global.t('sidebarMenus.application'), name: 'UserApplication' },
    { icon: images.bullet, label:  i18n.global.t('sidebarMenus.myQuotes'), name: 'UserQuotes' },
    { icon: images.dashboard, label:  i18n.global.t('sidebarMenus.notifications'), name: 'UserNotifications' },
  ],
  agent: [
    { icon: images.dashboard, label:  i18n.global.t('sidebarMenus.agencyDetails'), name: 'AgentAgencyDetails' },
    { icon: images.bullet, label:  i18n.global.t('sidebarMenus.leads'), name: 'AgentLeadsWrapper' },
    { icon: images.dashboard, label:  i18n.global.t('sidebarMenus.notifications'), name: 'AgentNotifications' },
  ],
};