<template lang="pug">
label.badge(
  :class="bindClass"
) 
  .status(
    :for="'badge' + type"
  )
    input(
      v-bind="$attrs"
      type="checkbox"
      :id="'badge' + type"
      :checked="checked"
      @change="handleChange"
    )
    img(
      v-if="checked"
      :src="globalMixin_images.checkWhite"
      alt="check-white.svg"
    )
    //- img(
    //-   v-else
    //-   :src="globalMixin_images.plus"
    //-   alt="plus.svg"
    //- )
  .insurance-name
    span(v-if="type") {{ $t(`insurances.${ type }`) }}
    span(v-else) {{ $t(`profile.addNewType`) }}
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: [
    'update-checked',
  ],
  computed: {
    bindClass () {
      if (this.checked && !this.disabled) {
        return this.type;
      }
      return false;
    },
  },
  methods: {
    handleChange (e) {
      if(!this.disabled){
        this.$emit('update-checked', e.target.checked);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  display: flex;
  width: max-content;
  height: max-content;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  // border: 1px solid gray;
  .status, .insurance-name {
    transition: all .2s ease;
    background:#DBE9FC26;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .status {
    width: 2.5em;
    position: relative;
    background: #c1cad6;
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
  .insurance-name {
    background: #D7DEE8;
    padding: .5em 1em;
    color: #888FA6;
    span {
      margin-top: 4px;
      font-family: 'Spartan';
      font-weight: 600;
      font-size: .75em;
    }
  }
  &.home {
    border: none;
    > * {
      transition: all .2s ease;
    }
    .status {
      background: #2FE2D8;
    }
    .insurance-name {
      background: #2FE2D840;
      color: #4068DA;;
    }
  }
  &.life {
    border: none;
    > * {
      transition: all .2s ease;
    }
    .status {
      background: #654BE4;
    }
    .insurance-name {
      background: #654BE440;
      color: #654BE4;
    }
  }
  &.auto {
    border: none;
    > * {
      transition: all .2s ease;
    }
    .status {
      background: $main-blue;
    }
    .insurance-name {
      background: $main-light-blue;
      color: $main-blue;
    }
  }
}
</style>