<template lang="pug">
.step-wrap
  h2.title
    img.icon(
      :src="globalMixin_images.userAvatar"
      alt="user-avatar.svg"
    )
    span {{ title }}
  .form
    //- transition-group(
    //-   name="fade"
    //- )
    base-radio-group(
      v-model="localQuestions.maritalStatus.value"
      :key="randomKey"
      :label="localQuestions.maritalStatus.label"
      :options="localQuestions.maritalStatus.options"
      name="martialStatus"
      maxWidth
      :validatorTypes="['required']"
      :isFormSubmitted="checkErrors"
      @has-error="errorHandler"
    )
    .left(
      :class="{ 'single' : localQuestions.maritalStatus.value == 0 }"
    )
      base-select(
        name="gender"
        :key="randomKey"
        v-model="localQuestions.gender"
        :label="'Your gender'"
        :placeholder="'Select gender'"
        :options="[{ label: $t('registration.male'), id: 0 }, { label: $t('registration.female'), id: 1 }, { label: $t('registration.other'), id: 2 }]"
        :isFormSubmitted="checkErrors"
        @has-error="errorHandler"
      )
      base-input(
        v-model.trim="localQuestions.phoneNumber"
        v-mask="globalMixin_phoneMask"
        :key="randomKey"
        :label="'Your phone number'"
        :placeholder="'Your phone number'"
        :details="$t('registration.emailDetails')"
        :validatorTypes="[ 'phoneNum', 'required', 'maxLength' ]"
        autocomplete="off"
        :isFormSubmitted="checkErrors"
        @has-error="errorHandler"
      )
      base-input(
        v-model.trim="localQuestions.email"
        :key="randomKey"
        type="email"
        :label="'Your email'"
        :placeholder="'Your email'"
        :details="$t('registration.emailDetails')"
        :validatorTypes="['required', 'maxLength', 'regex']"
        :validatorData="{ regex: globalMixin_emailRegex }"
        :isFormSubmitted="checkErrors"
        @has-error="errorHandler"
      )

    .right(
      v-if="localQuestions.maritalStatus.value != 0"
    )
      base-input(
        :key="randomKey"
        v-model.trim="localQuestions.partnerFirstName"
        :label="`Your partner's first name`"
        :placeholder="'Your partner first name'"
        :isFormSubmitted="checkErrors"
        @has-error="errorHandler"
      )
      base-input(
        :key="randomKey"
        v-model.trim="localQuestions.partnerLastName"
        :label="`Your partner's last name`"
        :placeholder="'Your partner last name'"
        :isFormSubmitted="checkErrors"
        @has-error="errorHandler"
      )
      base-date-picker(
        :key="randomKey"
        v-model.trim="localQuestions.partnerDateOfBirth"
        :label="`Your partner's date of birth`"
        :validatorTypes="['required', 'maxLength', 'regex']"
        :validatorData="{ regex: globalMixin_dateRegex }"
        :isFormSubmitted="checkErrors"
        @has-error="errorHandler"
      )
</template>

<script>
import stepMixin from '@/js/stepMixin';
export default {
  mixins: [stepMixin],
  methods: {
    randomKey () {
      // TODO forgive me for this
      return Math.random();
    },
  },
};
</script>

<style scoped lang="scss">
.step-wrap {
  h2.title {
    display: flex;
    // gap: 0 10px;
     @include flex-gap(10px, 'row nowrap');
    margin: 0;
    margin-bottom: max(2em, 1.8vw);
    span {
      font-size: 1.4em;
    }
  }
  .form {
    display: flex;
    flex-wrap: wrap;
    // gap: 1.7em 4em;
    @include flex-gap(1.7em, 'row wrap');
    .left, .right {
      width: calc(50% - 2em);
      display: flex;
      flex-direction: column;
      gap: 1.7em 4em;
      &.single {
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        > * {
          width: calc(50% - 2em);
        }
      }
    }
  }
}

@media (max-width: 1367px) {
  .step-wrap {
    .form {
      > * {
        width: 100% !important;
      }
      .left {
        &.single {
          flex-wrap: nowrap;
          flex-direction: column;
          > * {
            width: $input-width;
          }
        }
      }
    }
  }
}

@media (max-width: 689px) {
  .step-wrap {
    .form {
      > * {
        width: calc(100% - 2em) !important;
      }
    }
  }
}
</style>