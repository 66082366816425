<template lang="pug">
.different-wrap
  h1 {{ $t('landing.howDifferent') }}
  .cards-wrap
    .card
      .header
        .icon-wrap
          img(
            :src="globalMixin_images.phoneIcon"
            alt="lightbulb.svg"
          )
        h3 {{ $t('landing.obnoxiousPhoneCalls') }}
      p {{ $t('landing.obnoxiousParagraph') }}
    .card
      .header
        .icon-wrap
          img(
            :src="globalMixin_images.lightbulbWhite"
            alt="lightbulb.svg"
          )
        h3 {{ $t('landing.bestOfBothWorlds') }}
      p {{ $t('landing.bestOfBothWorldsParagraph') }}
    .card
      .header
        .icon-wrap
          img(
            :src="globalMixin_images.userIcon"
            alt="lightbulb.svg"
          )
        h3 {{ $t('landing.weAreFocusedOnYou') }}
      p {{ $t('landing.weAreFocusedOnYouParagraph') }}
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
* {
  color: #172856;
  font-family: 'Poppins', sans-serif; //Open Sans
}
.different-wrap {
  display: flex;
  flex-direction: column;
  // gap: 4em;
  @include flex-gap(6em, 'column nowrap');
  width: 100%;
  h1 {
    margin: 0;
    margin-top: .7em;
    font-size: 3.8em;
    text-align: center;
    z-index: 1;
  }
  h3 {
    margin: 0;
    font-size: 2em;
  }
  .cards-wrap {
    display: flex;
    // gap: 10px;
    @include flex-gap(10px, 'row nowrap');
    .card {
      width: 33.33%;
      //1.3em is read-more offset
      min-height: calc(411px);
      height: max-content;
      padding: 3em 2em 4em 2em;
      display: flex;
      flex-direction: column;
      position: relative;
      // gap: 3em;
      // @include flex-gap(5em, 'column nowrap');
      transition: all .2s ease-in;
      &:hover {
        transition: all .2s ease-out;
        box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.06);
        transform: scale(1.05);
      }
      .header {
        display: flex;
        align-items: center;
        height: 100px;
        margin-bottom: 3em;
        // gap: 1em;
        @include flex-gap(1em, 'row nowrap');
        .icon-wrap {
          position: relative;
          $size: 4.5em;
          min-width: $size;
          min-height: $size;
          max-width: $size;
          max-height: $size;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $main-blue;
          border-radius: 50%;
          border: 9px solid $main-light-blue;
        }
      }
      p {
        margin: 0;
        line-height: 2em;
        color: #57595A;
        font-size: 1.2em;
        font-family: 'Poppins', sans-serif; //Open Sans
      }
    }
  }
}

@media (max-width: 1062px) {
  .different-wrap {
    .cards-wrap {
      flex-direction: column;
      .card {
        padding: 1em;
        padding-bottom: 2em;
        // gap: 1em;
        min-height: auto;
        width: 100%;
        p {
          line-height: 1.5em;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .different-wrap {
    h1 {
      font-size: 3em;
    }
  }
}
</style>