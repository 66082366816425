<template lang="pug">
.nav-wrap(
  :class="{ 'is-mobile': isMobileMixin_isMobile }"
)
  router-link(
    v-if="isMobileMixin_isMobile"
    :to="{ name: 'Landing' }"
  )
    img(
      :src="globalMixin_images.backArrowLeft"
      alt="back-arrow-left.svg"
    )
  img.logo(
    :src="globalMixin_images.logo"
    alt="back-arrow-left.svg"
  )
  //- should add something to burger
  burger(
    v-if="isMobileMixin_isMobile"
  )
</template>

<script>
export default {
  emits: [
    'goBack'
  ],
};
</script>

<style lang="scss" scoped>
.nav-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: $nav-height;
  background-image: linear-gradient(to right, #3E94FF, #886CF5);
  padding: 1em;
  .logo {
    max-height: 80%;
  }
  &.is-mobile {
    height: $nav-height;
    justify-content: space-between;;
  }
}
</style>