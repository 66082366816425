<template lang="pug">
button.base-button(
  :class="{ 'no-fill-color' : noFillColor, 'transparent' : transparent, 'disabled' : disabled, 'aqua' : aqua }"
  :disabled="disabled"
)
  slot
  .left-icon(
    v-if="leftIcon && !loading"
  )
    img(
      :class="{ 'white' : iconWhite }"
      :src="leftIcon"
      :alt="leftIcon"
    )
  .label(
    v-if="label && !loading"
  )
    | {{ label }}
  base-loader(
    v-if="loading"
    :size="15"
  )
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    leftIcon: {
      type: String,
    },
    bindEnter: {
      type: Boolean,
    },
    noFillColor: {
      type: Boolean,
    },
    iconWhite: {
      type: Boolean,
    },
    transparent: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    aqua: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  mounted () {
    if (this.bindEnter) {
      document.addEventListener('keydown', this.bindEnterCallback);
    }
  },
  beforeUnmount () {
    if (this.bindEnter) {
      document.removeEventListener('keydown', this.bindEnterCallback);
    }
  },
  methods: {
    bindEnterCallback (evt) {
      if (evt.code === 'Enter') {
        if (!this.disabled) {
          this.$attrs.onClick();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.base-button {
  font-family: 'Inter';
  display: flex;
  align-items: center;
  justify-content: center;
  // gap: 0 .8em;
  @include flex-gap(.8em, 'row nowrap');
  padding: .8em 1.2em;
  color: white;
  background-color: $main-blue;
  border-radius: 6px;
  border: 2px solid $main-blue;
  cursor: pointer;
  font-weight: 600;
  width: max-content;
  .left-icon,
  .label {
    font-size: .9em;
    display: flex;
    align-items: center;
  }
  .left-icon {
    img.white {
      filter: brightness(5);
    }
  }
  &.aqua {
    background-color: $button-color-aqua;
    border-color: $button-color-aqua;
    color: #172856;
  }
  &.no-fill-color {
    border: 2px solid $main-blue;
    color: $main-blue;
    background-color: transparent;
  }
  &.transparent {
    border: 2px solid transparent;
    background-color: transparent;
    color: #838798;
  }
  &.disabled {
    border: 2px solid $button-background-disabled;
    background-color: $button-background-disabled;
    color: $button-text-disabled;
    cursor: default;
  }
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
  .base-button {
    .left-icon,
    .label {
      margin-bottom: 1px;
    }
  }
}}
</style>