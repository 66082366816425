<template lang="pug">
.register-wrap
  //- modal(
  //-   v-if="user"
  //-   background="#F7F8FBF0"
  //-   @close-modal="$router.push({ name: 'AgentLeadsWrapper' });"
  //- )
  //-   agent-user-info-modal(
  //-     :data="user"
  //-     @close-modal="$router.push({ name: 'AgentLeadsWrapper' });"
  //-   )
  modal(
    v-if="user"
    background="#F7F8FBF0"
    @close-modal="$router.push({ name: 'AgentLeadsWrapper' });"
  )
    notice-box(
      :message="$t('agentProfile.paymentSuccess')"
      :buttonLabel="$t('buttons.ok')"
      :buttonCallback="() => $router.push({ name: 'AgentLeadsWrapper' })"
    )
  register-navigation
  .content
    .link-wrap
      router-link(
        :to="{ name: 'AgentLeadsWrapper' }"
      )
        img(
          :src="globalMixin_images.backArrowGray"
          alt="back-arrow-gray.svg"
        )
        | {{ $t('agentProfile.backToLeads') }}
    .form-wrap
      .form
        .header
          h1 {{ $t('agentProfile.pendingPayment') }}
          p {{ $t('agentProfile.paymentAmount') + price }}
        transition(
          name="fade"
          mode="out-in"
        )
          .fields(
            v-if="!payWithSavedCard"
          )
            #card-element
            //- #card-errors(role="alert")
            p.saved-card(
              v-if="paymentMethods.length"
              @click="payWithSavedCard = true"
            ) 
              | {{ $t('agentProfile.payWithSavedCard') }}
            base-checkbox(
              v-if="!savedCreditCard"
              v-model="isSavingCard"
              :label="$t('agentProfile.saveCard')"
            )
            .boxes
              base-checkbox(
                v-model="terms"
                :label="$t('registration.termsOfUseSpecial')"
              )
              span.error-msg(
                v-if="showTermsError"
              ) {{ $t('registration.youMustAcceptTerms') }}
            .button-line
              base-button#submit(
                :label="$t('buttons.submitPayment')"
                :disabled="loading"
                :loading="loading"
                @click="onSubmitPayment"
              )
          payment-choose-card(
            v-else
            :cards="paymentMethods"
            :loading="loading"
            @select-card="savedCreditCard = $event"
            @new-card="handleNewCard"
            @make-payment="submitPayment"
            @close-modal="payWithSavedCard = false"
          )
        .message
          p {{ $t('agentProfile.paymentNotice') }}
          .icon
            img(
              :src="globalMixin_images.exclamationRound"
              alt="exclamation-round.svg"
            )
      .illustration
        img(
          :src="globalMixin_images.paymentIllustration"
          alt="paymentIllustration"
        )
  register-footer
</template>

<script>
import Modal from '@/components/Modal.vue';
import AgentUserInfoModal from "@/components/profile/leads/AgentUserInfoModal.vue";
import RegisterNavigation from '@/components/register-page/RegisterNavigation';
import RegisterFooter from '@/components/register-page/RegisterFooter';
import RegisterApplication from '@/components/register-page/application/RegisterApplication';
import PaymentChooseCard from '@/components/profile/payment/PaymentChooseCard';
import NoticeBox from '@/components/NoticeBox';

const stripeConstructor = window.Stripe;

export default {
  components: {
    Modal,
    AgentUserInfoModal,
    RegisterNavigation,
    RegisterFooter,
    RegisterApplication,
    PaymentChooseCard,
    NoticeBox,
  },
  data () {
    return {
      stripe: null,
      creditCard: null,
      savedCreditCard: null,
      paymentIntent: null,
      paymentMethods: [],
      payWithSavedCard: false,
      user: null,
      terms: false,
      loading: false,
      isSavingCard: false,
      showTermsError: false,
      price: "",
    };
  },
  watch: {
    payWithSavedCard: {
      async handler (isTrue) {
        // this is done because #card-element isnt loaded before transition completes
        // fade transition lasts for 200ms, 250ms is set to ensure that the element is loaded
        if (!isTrue) {
          setTimeout(() => {
            this.createStripeElements();
          }, 250);
        }
      },
    },
  },
  created () {
    this.init();
  },
  methods: {
    async init () {
      await this.initStripe();
      this.createStripeElements();
      await this.showClientInfo();
    },
    async initStripe () {
      const apiObj = {
        api: this.globalMixin_api.getStripeKey,
      };
      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.stripe = stripeConstructor(data.key);
      }
    },
    createStripeElements () {
      const elements = this.stripe.elements();
      const style = {
        base: {
          color: "#32325d",
        },
      };

      const card = elements.create("card", { style: style });
      card.mount("#card-element");
      this.creditCard = card;
    },
    async getPaymentIntent () {
      this.startLoading();
      const apiObj = {
        api: this.globalMixin_api.getPaymentIntent,
        params: {
          id: this.$route.params.leadId,
        },
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.paymentIntent = data.payment_intent;
        this.paymentMethods = data.payment_methods || [];
        this.price = data.price;
      } else {
        this.paymentMethods = error.response.data.payment_methods || [];
        this.price = error.response.data.price;
      }

      this.payWithSavedCard = this.paymentMethods.length > 0;
      this.stopLoading();
    },
    async showClientInfo () {
      this.startLoading();
      const apiObj = {
        api: this.globalMixin_api.showClientInfo,
        params: {
          id: this.$route.params.leadId,
        },
      };

      const [data, error] = await this.globalMixin_apiCall(apiObj);
      if (!error) {
        this.paymentIntent = data.payment_intent;
        this.paymentMethods = data.payment_methods || [];
        this.user = data.user_data;
      } else {
        if (!error.response.data.payment_intent) {
          this.getPaymentIntent();
          return;
        }
        else this.paymentIntent = error.response.data.payment_intent;
        this.paymentMethods = error.response.data.payment_methods || [];
        this.price = error.response.data.price;
      }

      this.payWithSavedCard = this.paymentMethods.length > 0;
      this.stopLoading();
    },
    onSubmitPayment () {
      if (this.terms) this.submitPayment();
      else this.showTermsError = true;
    },
    submitPayment (paymentMethod) {
      this.loading = true;
      const newMethod = {
        card: this.creditCard,
        // billing_details: {
        //   name: 'John Doe',
        // },
      };
      const method = paymentMethod? paymentMethod.id : newMethod;

      const methodObj = {
        payment_method: method,
      };

      if (this.isSavingCard) methodObj.setup_future_usage = 'on_session';

      this.stripe.confirmCardPayment(this.paymentIntent, methodObj).then(result => {
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          this.appendToast({ message: result.error.message });
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            // this.appendToast({ message: this.$t('agentProfile.paymentSuccess'), type: 'success' });
            this.showClientInfo();
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
          }
        }
      }).finally(
        () => {
          this.loading = false;
        }
      );
    },
    handleNewCard () {
      this.savedCreditCard = null;
      this.payWithSavedCard = false;
    },
  },
};
</script>

<style scoped lang="scss">
.register-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - #{$nav-height});
    padding: 1vw 7vw 2vw 7vw;
    .link-wrap {
      width: min(100%, 80em);
      margin: 0 auto;
      a {
        width: max-content;
        display: flex;
        // gap: 1em;
        color: #84899B;
        text-decoration: none;
        font-weight: 600;
        margin-bottom: 4em;
        img {
          margin-right: 1em;
        }
      }
    }
    .form-wrap {
      margin: 0 auto;
      width: min(100%, 60em);
      display: flex;
      flex-direction: column-reverse;
      // flex-wrap: wrap;
      // gap: 1em;
      background: #FFF;
      padding: 1.5em;
      border: 1px solid #DADADA;
      border-left: 6px solid #3E94FF;
      .form {
        display: flex;
        flex-direction: column;
        // gap: 3em;
        @include flex-gap(3em, 'column nowrap');
        // width: 50%;
        .header {
          display: flex;
          flex-direction: column;
          // gap: 2em;
          p {
            margin-top: 2em;
            font-size: 1.5em;
            color: $main-blue;
          }
        }
        .fields {
          display: flex;
          flex-direction: column;
          // gap: 2em;
          flex-grow: 1;
          #card-element {
            margin-bottom: 2em;
          }
          .base-checkbox {
            margin: 1em 0 0 0;
          }
          .boxes {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            margin-bottom: 1.5em;
            .error-msg{
              position: relative;
              top: 0;
              margin: 0;
              gap: 0;
            }
            .terms-text {
              color: $main-blue;
            }
          }
        }
        p {
          color: #313450;
          font-weight: 600;
        }
        .saved-card {
          margin: 0;
          margin-top: auto;
          color: $main-blue;
          cursor: pointer;
          text-decoration: underline;
          font-weight: 400;
          width: max-content;
        }
        h1 {
          font-size: 2.5em;
          margin: 0;
        }
        .message {
          margin-top: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          // gap: 2em;
          padding: 1em;
          background: #EAEAF8;
          border-left: 5px solid #4092FE;
          p {
            font-size: .8em;
            color: #172856;
            margin: 0;
            font-weight: 600;
          }
          .icon {
            min-width: 35px;
            min-height: 35px;
            margin-left: 2em;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }

      }
      .illustration {
        margin-right: 1em;
        img {
          width: max-content;
        }
      }
    }
    .button-line {
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }
  }
}

@media (min-width: 769px) {
  .register-wrap {
    .content {
      .form-wrap {
        margin: 0 auto;
        width: min(100%, 80em);
        display: flex;
        flex-direction: row;
        // flex-wrap: wrap;
        // gap: 1em;
        // @include flex-gap(1em, 'row nowrap');
        background: #FFFFFF;
        padding: 1.5em;
        border: 1px solid #DADADA;
        border-left: 6px solid #3E94FF;
        .form {
          display: flex;
          flex-direction: column;
          // gap: 3em;
          @include flex-gap(3em, 'column nowrap');
          width: 40%;
        }
        .illustration {
          img {
            width: max-content;
          }
        }
      }
      .button-line {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>