<template>
  <div class="pagination">

    <div class="buttons">
      <div class="button prev" @click="emitPageNumberChange(1)">
        <img :src="globalMixin_images.selectArrow" alt="arrow">
        <img :src="globalMixin_images.selectArrow" alt="arrow">
      </div>
      <div class="button prev" @click="handlePreviousPageNumber">
        <img :src="globalMixin_images.selectArrow" alt="arrow">
      </div>
      <div 
        v-for="page in pageArray"
        :key="page"
        class="page"
        :class="{ 'selected' : page === pageNumber }"
        @click="emitPageNumberChange(page)"
      >
        <BaseLoader v-if="loading && page === pageNumber" :size="15" color="#FFF" />
        <template v-else>{{ page }}</template>
      </div>
      <div class="button next" @click="handleNextPageNumber">
        <img :src="globalMixin_images.selectArrow" alt="arrow">
      </div>
      <div class="button next" @click="emitPageNumberChange(pageCount)">
        <img :src="globalMixin_images.selectArrow" alt="arrow">
        <img :src="globalMixin_images.selectArrow" alt="arrow">
      </div>
    </div>

    <!-- <div class="go-to"><input type="number" placeholder="Go to page..." v-model="goToPage"></div> -->
  </div>
</template>

<script>
export default {
  props: {
    pageCount: Number,
    pageNumber: Number,
    loading: Boolean,
  },
  data () {
    return {
      goToPage: '',
    };
  },
  computed: {
    pageArray () {
      let array = [];
      for (let i = 1; i <= this.pageCount; i++) {
        if(array.length === 5) {
          break;
        }

        if (this.pageNumber < 3) {
          array.push(i);

        }else if (this.pageNumber > this.pageCount - 2) {
          if (i >= this.pageCount - 4) {
            array.push(i);
          }

        }else {
          if (i >= this.pageNumber-2) {
            array.push(i);
          }
        }
      }

      return array;
    },
  },
  methods: {
    handlePreviousPageNumber () {
      if (this.pageNumber !== 1) this.emitPageNumberChange(this.pageNumber - 1);
    },
    handleNextPageNumber () {
      if (this.pageNumber !== this.pageCount) this.emitPageNumberChange(this.pageNumber + 1);
    },
    emitPageNumberChange (number) {
      if (this.pageNumber !== number) this.$emit('page-change', number);
    },
  },
  watch: {
    goToPage: {
      handler (newVal) {
        if (this.searchTimeout) {
          clearInterval(this.searchTimeout);
          this.searchTimeout = null;
        }

        newVal = parseInt(newVal);

        if (Number.isNaN(newVal)) {
          return;
        }

        if (newVal > this.pageCount) {
          this.goToPage = this.pageCount;
        } else if (newVal < 1) {
          this.goToPage = 1;
        }

        this.searchTimeout = setTimeout(() => {
          this.emitPageNumberChange(parseInt(this.goToPage));
          this.searchTimeout = null;
        }, 500);
      },
    },
    pageCount: {
      handler () {
        this.goToPage = '';
      },
    },
    pageNumber: {
      handler () {
        this.goToPage = '';
      },
    },
  },
};
</script>

<style scoped lang="scss">
  * {
    user-select: none;
    box-sizing: border-box;
    outline: none;
  }
  .pagination {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    background-color: #F9F9F9;
    width: 100%;
    padding: 1%;
  }
  .buttons {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: flex-end;
    align-items: center;

  }
  .go-to {
    display: flex;
    justify-content: center;
    width: 30%;
  }
  .page, .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }
  .page {
    width: 7%;
    margin: 0 1%;
    color: #434961;
    border-radius: 6px;
    padding: .2em;
  }
  .selected {
    background-color: #7D48EF;
    font-weight: 600;
    // font-size: 1.1em;
    color: white;
    transition: all .3s ease;
    border-radius: 6px;
  }
  .button {
    font-size: 0.8em;
    width: 15%;
    img {
      max-width: 12px;
      max-height: 12px;
    }
    &.prev {
      img {
        transform: rotate(90deg);
      }
    }
    &.next {
      img {
        transform: rotate(270deg);
      }
    }
  }

  @media (max-width: 950px) {
    .buttons {
      width: 70%;
    }
  }

  @media (max-width: 700px) {
    .pagination {
      flex-direction: column;
    }
    .buttons {
      width: 100%;
      justify-content: center;
    }
    .go-to {
      justify-content: center;
      width: 100%;
      margin-bottom: 2%;
    }
    .go-to input {
      width: 50%;
    }
  }
</style>